import $i18n from 'panda-i18n';
import React, { useEffect, useRef, useState } from 'react';
import { events } from '@/components/cn-utils';
import { CnBadge } from '@/components/cn-badge';
import { CnBox } from '@/components/cn-box';
import { CnLoading } from '@/components/cn-loading';
import { CnBalloon } from '../cn-balloon';
import { CnIcon } from '../cn-icon';
import { JobContainer } from './components/job-container';
import { cssPrefix, HIDE_EXPORT_LOADING, HIDE_IMPORT_LOADING, HIDE_PANEL, MARK_READ, MARK_UNREAD, RUNNING_TIP_KEY, SHOW_EXPORT_LOADING, SHOW_IMPORT_LOADING, SHOW_PANEL, SHOW_RUNNING_TIP, } from './constant';
import './index.scss';
import classNames from 'classnames';
export var CnAsyncJobs = function (props) {
    var importProps = props.importProps, exportProps = props.exportProps, className = props.className, style = props.style;
    var ref = useRef();
    var _a = useState(false), unread = _a[0], setUnread = _a[1];
    var _b = useState(false), visible = _b[0], setVisible = _b[1];
    var _c = useState(false), runningTip = _c[0], setRunningTip = _c[1];
    var _d = useState(false), importLoading = _d[0], setImportLoading = _d[1];
    var _e = useState(false), exportLoading = _e[0], setExportLoading = _e[1];
    var importPropsRef = useRef(importProps);
    useEffect(function () {
        importPropsRef.current = importProps;
    }, [importProps]);
    var exportPropsRef = useRef(exportProps);
    useEffect(function () {
        exportPropsRef.current = exportProps;
    }, [exportProps]);
    useEffect(function () {
        var cb1 = function () { return setImportLoading(true); };
        events.on(SHOW_IMPORT_LOADING, cb1);
        var cb2 = function () { return setImportLoading(false); };
        events.on(HIDE_IMPORT_LOADING, cb2);
        var cb3 = function () { return setExportLoading(true); };
        events.on(SHOW_EXPORT_LOADING, cb3);
        var cb4 = function () { return setExportLoading(false); };
        events.on(HIDE_EXPORT_LOADING, cb4);
        var cb5 = function () { return setUnread(true); };
        events.on(MARK_UNREAD, cb5);
        var cb6 = function () { return setUnread(false); };
        events.on(MARK_READ, cb6);
        var cb7 = function () {
            if (window.localStorage.getItem(RUNNING_TIP_KEY))
                return;
            window.localStorage.setItem(RUNNING_TIP_KEY, '1');
            setRunningTip(true);
        };
        events.on(SHOW_RUNNING_TIP, cb7);
        var cb8 = function (options) {
            var _a;
            setVisible(true);
            (_a = ref.current) === null || _a === void 0 ? void 0 : _a.setTab((options === null || options === void 0 ? void 0 : options.type) === 'import' ? 'import' : 'export');
        };
        var cb9 = function () { return setVisible(false); };
        events.on(SHOW_PANEL, cb8);
        events.on(HIDE_PANEL, cb9);
        return function () {
            events.off(SHOW_IMPORT_LOADING, cb1);
            events.off(HIDE_IMPORT_LOADING, cb2);
            events.off(SHOW_EXPORT_LOADING, cb3);
            events.off(HIDE_EXPORT_LOADING, cb4);
            events.off(MARK_UNREAD, cb5);
            events.off(MARK_READ, cb6);
            events.off(SHOW_RUNNING_TIP, cb7);
            events.off(SHOW_PANEL, cb8);
            events.off(HIDE_PANEL, cb9);
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, className, "".concat(cssPrefix, "trigger")), style: style, onClick: function () { return setVisible(true); } },
            React.createElement(CnBadge, { dot: unread },
                React.createElement(CnBalloon, { visible: runningTip, closable: false, trigger: React.createElement(CnLoading, { visible: importLoading || exportLoading, size: "medium", className: "".concat(cssPrefix, "trigger-loading") },
                        React.createElement(CnIcon, { type: "task-r-color", size: "xl" })) },
                    React.createElement(CnBox, { direction: "row", spacing: 9, align: "center" },
                        React.createElement("span", null, $i18n.get({
                            id: 'TheImportAndExportTaskIs_391802359',
                            dm: '导入导出任务后台运行中',
                            ns: 'CnAsyncJobsPro',
                        })),
                        React.createElement("span", { className: "".concat(cssPrefix, "running-tip-know"), onClick: function () { return setRunningTip(false); } }, $i18n.get({
                            id: 'IKnow',
                            dm: '我知道了',
                            ns: 'CnAsyncJobsPro',
                        })))))),
        React.createElement(JobContainer, { ref: ref, visible: visible, setVisible: setVisible, importProps: importPropsRef.current, exportProps: exportPropsRef.current })));
};
