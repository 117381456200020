import $i18n from "../../../locales/i18n";
import { imageFileTypeList } from './file-icon-url';
import { getBasename } from '../../../utils';
/**
 * 内置文件校验
 */
export var fileValidator = function (file) {
    if (!file) {
        var errorMsg = $i18n.get({
            id: 'InvalidUpload',
            dm: '无效上传',
        });
        throw new Error(errorMsg);
    }
    // 校验文件名长度
    if (file.name.length > 190) {
        var errorMsg = $i18n.get({
            id: 'FileNameIsUpTo_1175914279',
            dm: '文件名最长190个字符',
        });
        throw new Error(errorMsg);
    }
};
/**
 * 获取文件名
 * 1. 优先获取文件名
 * 2. 如果文件名不存在，通过链接拿到文件名
 * 3. 如果链接也拿不到，使用key
 */
export var getFileName = function (file) {
    try {
        var fileName = file.name;
        if (!fileName && file.url) {
            fileName = getBasename(file.url);
        }
        if (!fileName)
            fileName = file.key;
        return String(fileName); // 兼容异常的数据格式
    }
    catch (_a) { }
    return '';
};
/** 计算文件大小 */
export var calcFileSize = function (size) {
    var fileSize = parseFloat(size);
    // fileSize为浮点数 用 < 0.000001 替代 === 0
    if (isNaN(fileSize) || fileSize < 0.0000001) {
        return '0';
    }
    var SIZE_SUFFIX = ['B', 'K', 'M', 'G', 'T', 'P'];
    var suffixIndex = 0;
    // 在Mac上实验发现 取1024造成显示的大小和实际大小不一致
    // 因为单位制不同 见 https://superuser.com/questions/938234/size-of-files-in-windows-os-its-kb-or-kb
    var BIT_NUMBER_SYSTEM = 1024;
    while (fileSize >= BIT_NUMBER_SYSTEM && suffixIndex < SIZE_SUFFIX.length) {
        suffixIndex++;
        fileSize /= BIT_NUMBER_SYSTEM;
    }
    var suffix = SIZE_SUFFIX[suffixIndex];
    fileSize = Number(fileSize.toFixed(2));
    return "".concat(fileSize).concat(suffix);
};
/** 获取文件格式 */
export var getFileType = function (file) {
    var _a;
    var fileName = getFileName(file);
    return (_a = fileName === null || fileName === void 0 ? void 0 : fileName.split('.').pop()) === null || _a === void 0 ? void 0 : _a.toLowerCase();
};
/** 是否可以使用图片预览组件 */
export var canUseImageViewer = function (file) {
    var fileType = getFileType(file);
    return fileType && file.url && imageFileTypeList.includes(fileType);
};
