import { __assign, __awaiter, __generator, __rest } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnButton } from '@/components/cn-button';
import { formilyReact } from '@/form/formily';
export var Reset = function (props) {
    var forceClear = props.forceClear, validate = props.validate, onClick = props.onClick, onReset = props.onReset, onResetValidateFailed = props.onResetValidateFailed, onResetValidateSuccess = props.onResetValidateSuccess, restProps = __rest(props, ["forceClear", "validate", "onClick", "onReset", "onResetValidateFailed", "onResetValidateSuccess"]);
    var form = formilyReact.useParentForm();
    return (React.createElement(CnButton, __assign({}, restProps, { onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
            var err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if ((onClick === null || onClick === void 0 ? void 0 : onClick(e)) === false)
                            return [2 /*return*/];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, form.reset('*', { forceClear: forceClear, validate: validate })];
                    case 2:
                        _a.sent();
                        onResetValidateSuccess === null || onResetValidateSuccess === void 0 ? void 0 : onResetValidateSuccess();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        onResetValidateFailed === null || onResetValidateFailed === void 0 ? void 0 : onResetValidateFailed(err_1);
                        return [3 /*break*/, 4];
                    case 4:
                        onReset === null || onReset === void 0 ? void 0 : onReset();
                        return [2 /*return*/];
                }
            });
        }); } }), restProps.children ||
        $i18n.get({ id: 'Reset', dm: '重置', ns: 'CnForm' })));
};
