import { __assign, __rest } from "tslib";
import React from 'react';
import { formilyCore, formilyReact } from '@/form/formily';
import { useFormLayout } from '@/form/cn-form-layout';
import { Form } from '@fusion/lib';
var UiFormItem = Form.Item;
var useFormItemLayout = function (props) {
    var _a, _b, _c, _d, _e, _f, _g;
    var layout = useFormLayout();
    return __assign(__assign({}, props), { colon: (_a = props.colon) !== null && _a !== void 0 ? _a : layout.colon, labelAlign: (_b = props.labelAlign) !== null && _b !== void 0 ? _b : layout.labelAlign, labelWidth: (_c = props.labelWidth) !== null && _c !== void 0 ? _c : layout.labelWidth, labelCol: ((_d = props.labelCol) !== null && _d !== void 0 ? _d : layout.labelCol), wrapperCol: ((_e = props.wrapperCol) !== null && _e !== void 0 ? _e : layout.wrapperCol), size: ((_f = props.size) !== null && _f !== void 0 ? _f : layout.size), asterisk: props.asterisk, labelTextAlign: (_g = props.labelTextAlign) !== null && _g !== void 0 ? _g : layout.labelTextAlign });
};
export var BaseItem = function (props) {
    var children = props.children, others = __rest(props, ["children"]);
    var formLayout = useFormItemLayout(others);
    function getChildren(child) {
        if (props === null || props === void 0 ? void 0 : props.fullWidth) {
            // fullWidth 撑开
            return React.cloneElement(child, {
                style: {
                    width: '100%',
                },
            });
        }
        return children;
    }
    return (React.createElement(UiFormItem, __assign({}, formLayout), getChildren(children)));
};
// 适配
export var FormItem = formilyReact.connect(BaseItem, formilyReact.mapProps(function (props, field) {
    if (formilyCore.isVoidField(field)) {
        return {
            label: field.title || props.label,
            asterisk: props.asterisk,
            extra: props.extra || field.description,
        };
    }
    if (!field)
        return props;
    var takeFeedbackStatus = function () {
        if (field.validating)
            return 'pending';
        return field.decoratorProps.feedbackStatus || field.validateStatus;
    };
    var takeMessage = function () {
        var split = function (messages) {
            return messages.reduce(function (buf, text, index) {
                if (!text)
                    return buf;
                return index < messages.length - 1
                    ? buf.concat([text, ', '])
                    : buf.concat([text]);
            }, []);
        };
        if (field.validating)
            return;
        if (field.selfErrors.length) {
            if (props.requiredMessage) {
                return [props.requiredMessage];
            }
            else {
                return split(field.selfErrors);
            }
        }
        if (field.selfWarnings.length)
            return split(field.selfWarnings);
        if (field.selfSuccesses.length)
            return split(field.selfSuccesses);
    };
    var takeAsterisk = function () {
        if (field.required && field.pattern !== 'readPretty') {
            return true;
        }
        if ('asterisk' in props) {
            return props.asterisk;
        }
        return false;
    };
    return {
        label: props.label || field.title,
        validateState: takeFeedbackStatus(),
        help: takeMessage(),
        asterisk: takeAsterisk(),
        extra: props.extra || field.description,
    };
}));
FormItem.BaseItem = BaseItem;
export default FormItem;
