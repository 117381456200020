import $i18n from 'panda-i18n';
import locale from '@/locales';
import { registerCommonValidateRules } from '@cainiaofe/cn-ui-common';
import './validator';
registerCommonValidateRules();
// 国际化初始化，必须首位执行
$i18n.init({
    locale: locale,
    componentName: 'CnForm',
});
export { mixinCnFormArrayBase, CnFormArrayBase } from './cn-form-array-base';
export * from './cn-anchor-form';
export * from './cn-array-table';
export * from './cn-form';
export * from './cn-form-field';
export * from './cn-form-layout';
export { createCnFormStepInstance } from './cn-form-step';
export * from './cn-form-tab';
export * from './cn-quick-dialog-form';
// 导出注册到表单里的组件
export * from './component-map';
// 导出 formily 能力，更多细节进入文件内查看
export * from './formily';
export * from './schema-field';
