import { __assign, __rest } from "tslib";
import './cn-complex-radio.scss';
import * as React from 'react';
import { Radio as NextRadio } from './radio';
import $i18n, { withI18n } from 'panda-i18n';
import { CnReadOnly } from '@/components/cn-read-only';
import locale from '@/locales';
var renderPreview = function (value, readOnlyProps) {
    var showValue = value;
    if (typeof value === 'boolean') {
        showValue = value
            ? $i18n.get({ id: 'Yes', dm: '是', ns: 'CnRadio' })
            : $i18n.get({ id: '31255237809541120.CNTM', dm: '否', ns: 'CnRadio' });
    }
    return React.createElement(CnReadOnly, __assign({ value: showValue, type: "enum" }, readOnlyProps));
};
export var CnComplexRadio = withI18n(React.forwardRef(function (props, ref) {
    var readOnly = props.readOnly, readOnlyProps = props.readOnlyProps, otherProps = __rest(props, ["readOnly", "readOnlyProps"]);
    return (React.createElement(NextRadio, __assign({ "data-name": "CnComplexRadio", ref: ref, isPreview: readOnly, renderPreview: function (values) { return renderPreview(values, readOnlyProps); } }, otherProps)));
}), {
    locale: locale,
    componentName: 'CnRadio',
});
CnComplexRadio.displayName = 'CnComplexRadio';
CnComplexRadio.defaultProps = {
    readOnlyProps: {
        type: 'enum',
    },
};
