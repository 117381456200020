import './index.scss';
import * as React from 'react';
import classNames from 'classnames';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import { CnTextEllipsis } from '../cn-ellipsis';
export var CnStatusTag = function (props) {
    var _a;
    var type = props.type, status = props.status, size = props.size;
    var children = props.children || props.text;
    if (!children)
        return null;
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-status-tag', (_a = {},
            _a["cn-ui-status-tag-type-".concat(type)] = type,
            _a["cn-ui-status-tag-size-".concat(size)] = size,
            _a["cn-ui-status-tag-status-".concat(status)] = status,
            _a)) },
        React.createElement(CnTextEllipsis, { line: 1 }, children)));
};
CnStatusTag.displayName = 'CnStatusTag';
CnStatusTag.defaultProps = {
    type: 'normal',
    status: 'info',
};
