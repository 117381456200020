'use strict';
import { __assign, __extends } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import { CnButton } from '@/components/cn-button';
import { CnIcon } from '@/components/cn-icon';
import { Overlay, Balloon } from '@fusion/lib';
import { addEventListener, getClientSize, errorImage } from '../utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
var initialPosition = {
    x: 0,
    y: 0,
};
// 放缩比例区间[0.1, 4]
var minScaleRate = 0.2;
var maxScaleRate = 4;
var Preview = /** @class */ (function (_super) {
    __extends(Preview, _super);
    function Preview(props) {
        var _this = _super.call(this, props) || this;
        _this.addEventListener = function () {
            _this.onMouseUpListener = addEventListener(window, 'mouseup', _this.onMouseUp, false);
            _this.onMouseMoveListener = addEventListener(window, 'mousemove', _this.onMouseMove, false);
            _this.onKeyDownListener =
                _this.props.showSwitch &&
                    addEventListener(window, 'keydown', _this.onKeyDown, false);
            try {
                // Resolve if in iframe lost event
                /* istanbul ignore next */
                if (window.top !== window.self) {
                    _this.onTopMouseUpListener = addEventListener(window.top, 'mouseup', _this.onMouseUp, false);
                    _this.onTopMouseMoveListener = addEventListener(window.top, 'mousemove', _this.onMouseMove, false);
                }
            }
            catch (error) {
                /* istanbul ignore next */
                console.warn(false, "[ImagePlayer] ".concat(error));
            }
        };
        _this.removeEventListener = function () {
            var _a, _b, _c, _d;
            (_a = _this.onMouseUpListener) === null || _a === void 0 ? void 0 : _a.remove();
            (_b = _this.onMouseMoveListener) === null || _b === void 0 ? void 0 : _b.remove();
            _this.props.showSwitch && ((_d = (_c = _this.onKeyDownListener) === null || _c === void 0 ? void 0 : _c.remove) === null || _d === void 0 ? void 0 : _d.call(_c));
            /* istanbul ignore next */
            if (_this.onTopMouseUpListener)
                _this.onTopMouseUpListener.remove();
            /* istanbul ignore next */
            if (_this.onTopMouseMoveListener)
                _this.onTopMouseMoveListener.remove();
        };
        _this.onClose = function (e) {
            e === null || e === void 0 ? void 0 : e.stopPropagation();
            _this.setState({
                chooseUrl: null,
                visible: false,
                position: initialPosition,
                scale: 1,
                rotate: 0,
            }, function () {
                if (typeof _this.props.onClose === 'function') {
                    _this.props.onClose();
                }
            });
        };
        _this.onKeyDown = function (e) {
            switch (e.key) {
                case 'ArrowRight': {
                    _this.onSwitch('next', e);
                    return;
                }
                case 'ArrowLeft': {
                    _this.onSwitch('prev', e);
                }
                default:
            }
        };
        _this.onZoomIn = function () {
            _this.setState(function (prevState) { return (__assign(__assign({}, prevState), { scale: prevState.scale < maxScaleRate
                    ? prevState.scale + 0.1
                    : prevState.scale, position: initialPosition })); });
        };
        _this.onZoomOut = function () {
            _this.setState(function (prevState) { return (__assign(__assign({}, prevState), { scale: prevState.scale >= minScaleRate
                    ? prevState.scale - 0.1
                    : prevState.scale, position: initialPosition })); });
        };
        _this.onRotateRight = function () {
            _this.setState(function (prevState) { return (__assign(__assign({}, prevState), { rotate: prevState.rotate + 90 })); });
        };
        _this.onRotateLeft = function () {
            _this.setState(function (prevState) { return (__assign(__assign({}, prevState), { rotate: prevState.rotate - 90 })); });
        };
        _this.onMouseUp = function () {
            var _a = _this.state, visible = _a.visible, isMoving = _a.isMoving, scale = _a.scale, rotate = _a.rotate;
            var isRotate = rotate % 180 !== 0;
            if (visible && isMoving) {
                var widthRaw = _this.imgRef.offsetWidth * scale;
                var heightRaw = _this.imgRef.offsetHeight * scale;
                var _b = isRotate
                    ? [heightRaw, widthRaw]
                    : [widthRaw, heightRaw], width = _b[0], height = _b[1];
                // eslint-disable-next-line @typescript-eslint/no-shadow
                var _c = _this.imgRef.getBoundingClientRect(), left = _c.left, top = _c.top;
                _this.setState({ isMoving: false });
                var _d = getClientSize(), clientWidth = _d.width, clientHeight = _d.height;
                // 水平方向全部拖出边界，则回到屏幕中心
                var overflowHoz = left < -width
                    ? -width - left
                    : left > clientWidth
                        ? left - document.documentElement.clientWidth
                        : 0;
                // 垂直方向全部拖出边界，则回到屏幕中心
                var overflowVer = top < -height
                    ? -height - top
                    : top > clientHeight
                        ? top - document.documentElement.clientHeight
                        : 0;
                if (overflowHoz || overflowVer) {
                    _this.setState({ position: initialPosition });
                }
            }
        };
        _this.onMouseDown = function (event) {
            var position = _this.state.position;
            // Only allow main button
            if (event.button !== 0)
                return;
            event.preventDefault();
            // Without this mask close will abnormal
            event.stopPropagation();
            _this.originPositionRef.deltaX = event.pageX - position.x;
            _this.originPositionRef.deltaY = event.pageY - position.y;
            _this.originPositionRef.originX = position.x;
            _this.originPositionRef.originY = position.y;
            _this.setState({ isMoving: true });
        };
        _this.onMouseMove = function (event) {
            var _a = _this.state, visible = _a.visible, isMoving = _a.isMoving;
            if (visible && isMoving) {
                _this.setState({
                    position: {
                        x: event.pageX - _this.originPositionRef.deltaX,
                        y: event.pageY - _this.originPositionRef.deltaY,
                    },
                });
            }
        };
        _this.onError = function (e) {
            var _a = _this.props, onError = _a.onError, errorUrl = _a.errorUrl;
            e.target.src = errorUrl;
            onError && onError(e);
        };
        _this.onSwitch = function (key, e) {
            var onSwitch = _this.props.onSwitch;
            var chooseUrl = _this.state.chooseUrl;
            e.stopPropagation();
            _this.setState({
                position: initialPosition,
                scale: 1,
                rotate: 0,
            });
            onSwitch && onSwitch(key, chooseUrl);
        };
        _this.download = function () {
            var chooseUrl = _this.state.chooseUrl;
            var x = new XMLHttpRequest();
            x.open('GET', chooseUrl, true);
            x.responseType = 'blob';
            x.onloadend = function () {
                var url = window.URL.createObjectURL(x.response);
                var a = document.createElement('a');
                a.href = url;
                a.download = '';
                a.click();
            };
            x.send();
        };
        _this.renderButton = function (_a, key) {
            var icon = _a.icon, onClick = _a.onClick, _b = _a.tooltip, tooltip = _b === void 0 ? '' : _b, _c = _a.className, className = _c === void 0 ? '' : _c;
            if (tooltip) {
                return (React.createElement(Balloon.Tooltip, { key: key, align: "b", trigger: React.createElement(CnButton, { className: classNames('cn-ui-image-viewer-image-operate-btn', className), size: "medium", onClick: onClick },
                        React.createElement(CnIcon, { type: icon, size: "large" })) }, tooltip));
            }
            return (React.createElement(CnButton, { key: key, className: classNames('cn-ui-image-viewer-image-operate-btn', className), size: "medium", onClick: onClick },
                React.createElement(CnIcon, { type: icon, size: "large" })));
        };
        _this.renderOperations = function (optConfig) { return (React.createElement("div", { id: "operation-bar", className: 'cn-ui-image-viewer-image-player-operation-bar', onClick: function (e) { return e.stopPropagation(); } }, optConfig.map(function (item, index) { return _this.renderButton(item, index); }))); };
        _this.state = {
            chooseUrl: props.chooseUrl || '',
            visible: props.visible || false,
            rotate: 0,
            isMoving: false,
            scale: 1,
            position: initialPosition,
        };
        _this.originPositionRef = {
            originX: 0,
            originY: 0,
            deltaX: 0,
            deltaY: 0,
        };
        _this.imgRef = React.createRef();
        return _this;
    }
    Preview.prototype.componentDidMount = function () {
        this.addEventListener();
    };
    Preview.prototype.componentWillUnmount = function () {
        this.removeEventListener();
    };
    Preview.prototype.componentWillReceiveProps = function (nextProps, prevState) {
        this.setState({
            chooseUrl: nextProps.chooseUrl || prevState.chooseUrl,
            visible: nextProps.visible || prevState.visible,
            shouldExpandWidth: nextProps.shouldExpandWidth || prevState.shouldExpandWidth,
        });
    };
    Preview.prototype.render = function () {
        var _this = this;
        var _a = this.props, shouldPreviewExpand = _a.shouldPreviewExpand, shouldExpandWidth = _a.shouldExpandWidth, wrapperClassName = _a.wrapperClassName, showSwitch = _a.showSwitch, hasDownload = _a.hasDownload;
        var _b = this.state, chooseUrl = _b.chooseUrl, scale = _b.scale, position = _b.position, rotate = _b.rotate, visible = _b.visible;
        var optConfig = [
            {
                icon: 'add',
                onClick: this.onZoomIn,
                tooltip: $i18n.get({
                    id: 'Amplification',
                    dm: '放大',
                    ns: 'CnImageViewer',
                }),
            },
            {
                icon: 'minus',
                onClick: this.onZoomOut,
                tooltip: $i18n.get({
                    id: 'Narrowing',
                    dm: '缩小',
                    ns: 'CnImageViewer',
                }),
            },
            {
                icon: 'counterclockwise',
                onClick: this.onRotateLeft,
                tooltip: $i18n.get({
                    id: 'LeftRotation',
                    dm: '左侧旋转',
                    ns: 'CnImageViewer',
                }),
            },
            {
                icon: 'refresh',
                onClick: this.onRotateRight,
                tooltip: $i18n.get({
                    id: 'RightRotation',
                    dm: '右侧旋转',
                    ns: 'CnImageViewer',
                }),
            },
        ];
        if (hasDownload) {
            optConfig.push({
                icon: 'download',
                onClick: this.download,
                tooltip: $i18n.get({ id: 'Download', dm: '下载', ns: 'CnImageViewer' }),
            });
        }
        var closeBtn = {
            icon: 'close',
            onClick: this.onClose,
            className: 'cn-ui-image-viewer-close-preview-btn',
            tooltip: $i18n.get({ id: 'Close', dm: '关闭', ns: 'CnImageViewer' }),
        };
        var leftBtn = {
            icon: 'arrow-left',
            onClick: function (e) { return _this.onSwitch('prev', e); },
            className: 'cn-ui-image-viewer-arrow-left',
            tooltip: $i18n.get({ id: 'LastOne', dm: '上一个', ns: 'CnImageViewer' }),
        };
        var rightBtn = {
            icon: 'arrow-right',
            onClick: function (e) { return _this.onSwitch('next', e); },
            className: 'cn-ui-image-viewer-arrow-right',
            tooltip: $i18n.get({ id: 'Next', dm: '下一个', ns: 'CnImageViewer' }),
        };
        var previewStyle = shouldPreviewExpand
            ? shouldExpandWidth
                ? { width: '100%' }
                : { height: '100%' }
            : {};
        return (React.createElement(Overlay, { wrapperClassName: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-image-viewer-image-overlay', wrapperClassName), visible: visible, align: "cc cc", hasMask: true, disableScroll: true, shouldUpdatePosition: true, onRequestClose: function (type) {
                // 绕过overlay bug，当鼠标按下拖动其上元素并移入开发者工具栏范围，再抬起时会触发docClick自动弹层关闭
                if (type === 'docClick')
                    return;
                _this.onClose();
            } },
            React.createElement("div", { className: 'cn-ui-image-viewer-image-player-wrapper-overlay-fix', style: { width: '100%', height: '100%' }, onClick: this.onClose },
                this.renderOperations(optConfig),
                this.renderButton(closeBtn),
                React.createElement("img", { className: 'cn-ui-image-viewer-image-player', ref: function (ref) { return (_this.imgRef = ref); }, src: chooseUrl, alt: "", style: __assign({ transform: "\n                    scale3d(".concat(scale, ", ").concat(scale, ", 1)\n                    translate3d(").concat(position.x, "px, ").concat(position.y, "px, 0)\n                    rotate(").concat(rotate, "deg)") }, previewStyle), onMouseDown: this.onMouseDown, onClick: function (e) { return e.stopPropagation(); }, onError: this.onError }),
                showSwitch && (React.createElement(React.Fragment, null,
                    this.renderButton(leftBtn),
                    this.renderButton(rightBtn))))));
    };
    Preview.propTypes = {
        /**
         * 弹层wrapper样式名
         */
        wrapperClassName: PropTypes.string,
        /**
         * 是否打开预览
         */
        visible: PropTypes.bool,
        /**
         * 图片url或url数组
         */
        chooseUrl: PropTypes.string,
        /**
         * 点击大图预览时是否拉伸长边适配屏幕
         */
        shouldPreviewExpand: PropTypes.bool,
        /**
         * 拉伸哪个边适配屏幕
         */
        shouldExpandWidth: PropTypes.bool,
        /**
         * 加载出错的占位图
         */
        errorUrl: PropTypes.string,
        /**
         * 加载出错后回调
         * @params {Event} e
         * @returns {void}
         */
        onError: PropTypes.func,
        /**
         * 关闭预览弹层后回调
         */
        onClose: PropTypes.func,
        showSwitch: PropTypes.bool,
        onSwitch: PropTypes.func,
        hasDownload: PropTypes.bool,
    };
    Preview.defaultProps = {
        wrapperClassName: '',
        visible: false,
        chooseUrl: '',
        shouldPreviewExpand: false,
        shouldExpandWidth: false,
        showSwitch: false,
        errorUrl: errorImage,
        onSwitch: function () { },
        onError: function () { },
    };
    return Preview;
}(React.Component));
export default Preview;
