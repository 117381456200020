import { __assign, __rest } from "tslib";
import * as React from 'react';
import { CnFormItem } from '@/form/cn-form-item';
import { formilyReact } from '@/form/formily';
import { mapStatus } from '@/form/__builtins__';
export var CnFormField = function (props) {
    var children = props.children, decoratorProps = props.decoratorProps, restProps = __rest(props, ["children", "decoratorProps"]);
    if (children) {
        var ChildCom = function (childProps) {
            var childrenProps = (childProps === null || childProps === void 0 ? void 0 : childProps.props) || children.props || {};
            var field = formilyReact.useField();
            var state = mapStatus(childrenProps, field).state;
            return React.cloneElement(children, __assign(__assign({}, childProps), { field: field, state: state }));
        };
        return (React.createElement(formilyReact.Field, __assign({ decorator: [CnFormItem, decoratorProps] }, restProps, { component: [formilyReact.observer(ChildCom)] })));
    }
    return (React.createElement(formilyReact.Field, __assign({ decorator: [CnFormItem, decoratorProps] }, restProps)));
};
