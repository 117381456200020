import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import { formilyCore } from '@/form/formily';
import { isEmpty, find, isNil } from 'lodash';
import { getRequest, request } from 'cn-request';
var FORMILY_HOOKS = [
    {
        label: $i18n.get({ id: 'FieldHook', dm: '字段钩子', ns: 'CnDynamicForm' }),
        children: [
            {
                label: $i18n.get({
                    id: 'FieldInitialization',
                    dm: '字段初始化',
                    ns: 'CnDynamicForm',
                }),
                value: 'onFieldReact',
            },
            {
                label: $i18n.get({
                    id: 'FieldValueChange',
                    dm: '字段值变化',
                    ns: 'CnDynamicForm',
                }),
                value: 'onFieldValueChange',
            },
            {
                label: $i18n.get({
                    id: 'WhenTheInputBoxIsOutOfFocus',
                    dm: '输入框失焦时',
                    ns: 'CnDynamicForm',
                }),
                value: 'onFieldChange_active_false',
            },
        ],
    },
    {
        label: $i18n.get({ id: 'FormHook', dm: '表单钩子', ns: 'CnDynamicForm' }),
        isFormHooks: true,
        children: [
            {
                label: $i18n.get({
                    id: 'FormInitialization',
                    dm: '表单初始化',
                    ns: 'CnDynamicForm',
                }),
                value: 'onFormInit',
            },
            {
                label: $i18n.get({
                    id: 'FormValueChange',
                    dm: '表单值变化',
                    ns: 'CnDynamicForm',
                }),
                value: 'onFormValuesChange',
            },
            {
                label: $i18n.get({
                    id: 'FormFieldInput',
                    dm: '表单字段输入',
                    ns: 'CnDynamicForm',
                }),
                value: 'onFormInputChange',
            },
        ],
    },
];
var CONDITION_OPTIONS = [
    {
        label: $i18n.get({ id: 'ValueIsEqual', dm: '值等于', ns: 'CnDynamicForm' }),
        value: '$self.value==${conditionValue}',
    },
    {
        label: $i18n.get({
            id: 'ValueNotEqual',
            dm: '值不等于',
            ns: 'CnDynamicForm',
        }),
        value: '$self.value!=${conditionValue}',
    },
    {
        label: $i18n.get({ id: 'Contains', dm: '包含', ns: 'CnDynamicForm' }),
        value: '$self.value.includes(${conditionValue})',
    },
    {
        label: $i18n.get({
            id: 'DoesNotContain',
            dm: '不包含',
            ns: 'CnDynamicForm',
        }),
        value: '!$self.value.includes(${conditionValue})',
    },
    {
        label: $i18n.get({ id: 'Empty', dm: '为空', ns: 'CnDynamicForm' }),
        value: '!$self.value',
        hiddenValue: true,
    },
    {
        label: $i18n.get({
            id: '31255239852167168.CNTM',
            dm: '不为空',
            ns: 'CnDynamicForm',
        }),
        value: '!!$self.value',
        hiddenValue: true,
    },
];
export var isFormHooks = function (value) {
    var _a;
    return !isNil(find((_a = find(FORMILY_HOOKS, 'isFormHooks')) === null || _a === void 0 ? void 0 : _a.children, ['value', value]));
};
export var isNoValueCondition = function (value) {
    var _a, _b;
    return (_b = (_a = find(CONDITION_OPTIONS, ['value', value])) === null || _a === void 0 ? void 0 : _a.hiddenValue) !== null && _b !== void 0 ? _b : false;
};
var packRequest = function (options) {
    if (!getRequest()) {
        console.error('组件使用默认实例中...请先初始化项目cn-request,切勿发布到生产环境');
    }
    return request(options).then(function (result) {
        if ('request' in result && 'config' in result) {
            return result.data;
        }
        return result;
    });
};
var generateParams = function (array, values) {
    return Array.from(array).reduce(function (acc, _a) {
        var _b;
        var key = _a.key, value = _a.value;
        return Object.assign(acc, (_b = {}, _b[key] = value === '$self.value' ? values : value, _b));
    }, {});
};
export var handleRequestService = function (requestConfig) {
    var _a = requestConfig || {}, url = _a.url, service = _a.service, paramsProps = _a.params, dataProps = _a.data, _b = _a.searchFormat, searchFormat = _b === void 0 ? function (data) { return data; } : _b, _c = _a.method, method = _c === void 0 ? 'GET' : _c;
    if (service) {
        return service;
    }
    if (url) {
        return function (searchConfig) {
            var formValues = (searchConfig || {}).formValues;
            var params = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'GET'
                ? searchFormat(__assign(__assign({}, formValues), paramsProps))
                : paramsProps;
            var data = (method === null || method === void 0 ? void 0 : method.toLocaleUpperCase()) === 'POST'
                ? searchFormat(__assign(__assign({}, formValues), dataProps))
                : dataProps;
            return packRequest(__assign(__assign({}, requestConfig), { params: params, data: data, url: url }));
        };
    }
    return function () { return Promise.resolve([]); };
};
var getRealCondition = function (condition, conditionValue) {
    return "".concat(condition.replace('${conditionValue}', "'".concat(conditionValue, "'")));
};
export var generateEffects = function (reactionsList) {
    reactionsList.forEach(function (watchItem) {
        var watchCode = watchItem.watchCode, reactions = watchItem.reactions;
        reactions.forEach(function (reaction) {
            var _a, _b, _c;
            var effects = reaction.effects, fulfill = reaction.fulfill;
            var resourcePool = fulfill.resourcePool;
            var rpField = resourcePool.field, rpState = resourcePool.state, rpCondition = resourcePool.condition, rpConditionValue = resourcePool.conditionValue, _d = resourcePool.data, rpData = _d === void 0 ? {} : _d;
            var fromDataSource = rpData.fromDataSource, syncData = rpData.syncData, asyncData = rpData.asyncData;
            var requestConfig = {
                url: asyncData === null || asyncData === void 0 ? void 0 : asyncData.url,
                method: asyncData === null || asyncData === void 0 ? void 0 : asyncData.method,
                formatResult: (asyncData === null || asyncData === void 0 ? void 0 : asyncData.formatResult)
                    ? new Function("return ".concat(asyncData.formatResult))()
                    : function (res) { return res; },
            };
            var fieldEffectsHandle = function (field, form) {
                var _a, _b, _c;
                // 监听onFieldChange时，会存在field.value为undefined的情况；需要做容错处理
                field.value = field.value ? field.value : '';
                // new Function("$self", "$form", `${fulfill?.run}`)(field, form)
                var conditionResult = new Function('$self', "return ".concat(getRealCondition(rpCondition, rpConditionValue)))(field);
                if ((asyncData === null || asyncData === void 0 ? void 0 : asyncData.method.toLocaleUpperCase()) === 'GET') {
                    requestConfig.params = generateParams((_a = asyncData.params) === null || _a === void 0 ? void 0 : _a.array, field.value);
                }
                if ((asyncData === null || asyncData === void 0 ? void 0 : asyncData.method.toLocaleUpperCase()) === 'POST') {
                    requestConfig.data = generateParams((_b = asyncData.params) === null || _b === void 0 ? void 0 : _b.array, field.value);
                }
                if (rpState === 'value') {
                    if (conditionResult) {
                        var requestService = handleRequestService(requestConfig);
                        requestService().then(function (requestRes) {
                            var _a;
                            var currentOption = fromDataSource
                                ? (_a = find(field.getState().dataSource, ['value', field.value])) !== null && _a !== void 0 ? _a : {}
                                : {};
                            var realResponseData = requestConfig.formatResult(requestRes);
                            var finalData = Object.assign(currentOption, new Function("return ".concat(syncData))(), realResponseData);
                            rpField.forEach(function (f) {
                                form.setFieldState(f, { value: finalData === null || finalData === void 0 ? void 0 : finalData[f] });
                            });
                        });
                    }
                    else {
                        rpField.forEach(function (f) {
                            form.setFieldState(f, { value: '' });
                        });
                    }
                }
                else if (rpState === 'dataSource') {
                    if (conditionResult) {
                        var currentOption = fromDataSource
                            ? (_c = find(field.getState().dataSource, ['value', field.value])) !== null && _c !== void 0 ? _c : {}
                            : {};
                        var realSyncData_1 = new Function("return ".concat(syncData))();
                        var finalData_1 = Object.assign(currentOption);
                        var requestService = handleRequestService(requestConfig);
                        requestService().then(function (requestRes) {
                            rpField.forEach(function (f) {
                                form.setFieldState(f, { value: '' });
                                form.setFieldState(f, function (state) {
                                    var _a, _b;
                                    if (Array.isArray(realSyncData_1)) {
                                        Object.assign(finalData_1, (_a = {}, _a[f] = realSyncData_1, _a));
                                    }
                                    var realResponseData = requestConfig.formatResult(requestRes);
                                    if (Array.isArray(realResponseData)) {
                                        Object.assign(finalData_1, (_b = {}, _b[f] = realResponseData, _b));
                                    }
                                    state.dataSource = finalData_1 === null || finalData_1 === void 0 ? void 0 : finalData_1[f];
                                });
                            });
                        });
                    }
                    else {
                        rpField.forEach(function (f) {
                            form.setFieldState(f, function (state) {
                                state.dataSource = [];
                            });
                        });
                    }
                }
                else {
                    rpField.forEach(function (f) {
                        form.setFieldState(f, function (state) {
                            state[rpState] = conditionResult;
                        });
                    });
                }
            };
            var formEffectsHandle = function (form) {
                var _a, _b;
                if ((asyncData === null || asyncData === void 0 ? void 0 : asyncData.method.toLocaleUpperCase()) === 'GET') {
                    requestConfig.params = generateParams((_a = asyncData.params) === null || _a === void 0 ? void 0 : _a.array);
                }
                if ((asyncData === null || asyncData === void 0 ? void 0 : asyncData.method.toLocaleUpperCase()) === 'POST') {
                    requestConfig.data = generateParams((_b = asyncData.params) === null || _b === void 0 ? void 0 : _b.array);
                }
                if (rpState === 'value') {
                    var requestService = handleRequestService(requestConfig);
                    requestService().then(function (requestRes) {
                        var realResponseData = requestConfig.formatResult(requestRes);
                        var finalData = Object.assign({}, new Function("return ".concat(syncData))(), realResponseData);
                        rpField.forEach(function (f) {
                            form.setFieldState(f, function (state) {
                                state.value = finalData === null || finalData === void 0 ? void 0 : finalData[f];
                            });
                        });
                    });
                }
                else if (rpState === 'dataSource') {
                    var realSyncData_2 = new Function("return ".concat(syncData))();
                    var finalData_2 = {};
                    var requestService = handleRequestService(requestConfig);
                    requestService().then(function (requestRes) {
                        rpField.forEach(function (f) {
                            // 此处需要使用*(${f})写法，否则当触发CnDynamicForm中clearFormGraph、deleteValuesIn方法时，dataSource会被清空。
                            form.setFieldState("*(".concat(f, ")"), function (state) {
                                var _a, _b;
                                if (Array.isArray(realSyncData_2)) {
                                    Object.assign(finalData_2, (_a = {}, _a[f] = realSyncData_2, _a));
                                }
                                var realResponseData = requestConfig.formatResult(requestRes);
                                if (Array.isArray(realResponseData)) {
                                    Object.assign(finalData_2, (_b = {}, _b[f] = realResponseData, _b));
                                }
                                state.dataSource = finalData_2 === null || finalData_2 === void 0 ? void 0 : finalData_2[f];
                            });
                        });
                    });
                }
                else {
                    rpField.forEach(function (f) {
                        form.setFieldState(f, function (state) {
                            state[rpState] = true;
                        });
                    });
                }
            };
            // 判断当存在watchCode并且存在fulfill.run时，才执行
            if (!isEmpty(resourcePool)) {
                if (isFormHooks(effects[0])) {
                    (_a = formilyCore[effects[0]]) === null || _a === void 0 ? void 0 : _a.call(formilyCore, formEffectsHandle);
                }
                else if (effects[0].includes('_')) {
                    var _e = effects[0].split('_'), effectsHook = _e[0], effectsWatch_1 = _e[1], watchBool = _e[2];
                    var execuBool_1 = new Function("return ".concat(watchBool))();
                    (_b = formilyCore[effectsHook]) === null || _b === void 0 ? void 0 : _b.call(formilyCore, watchCode, [effectsWatch_1], function (field, form) {
                        if (field.getState()[effectsWatch_1] === execuBool_1) {
                            fieldEffectsHandle(field, form);
                        }
                    });
                }
                else {
                    (_c = formilyCore[effects[0]]) === null || _c === void 0 ? void 0 : _c.call(formilyCore, watchCode, fieldEffectsHandle);
                }
            }
        });
    });
};
