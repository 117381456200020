import { useArray, useIndex, useRecord } from '../hooks';
import { ArrayBaseIndex } from '../view/array-base-index';
import { Addition } from '../view/addition';
import { Copy } from '../view/copy';
import { MoveDown } from '../view/move-down';
import { MoveUp } from '../view/move-up';
import { Remove } from '../view/remove';
import { SortHandle } from '../view/sort-handle';
import { CnFormArrayBaseItem } from '../view/array-base-item';
export function mixinCnFormArrayBase(_TargetComponent) {
    var TargetComponent = _TargetComponent;
    TargetComponent.Addition = Addition;
    TargetComponent.Index = ArrayBaseIndex;
    TargetComponent.Item = CnFormArrayBaseItem;
    TargetComponent.Copy = Copy;
    TargetComponent.MoveDown = MoveDown;
    TargetComponent.MoveUp = MoveUp;
    TargetComponent.Remove = Remove;
    TargetComponent.SortHandle = SortHandle;
    TargetComponent.useArray = useArray;
    TargetComponent.useIndex = useIndex;
    TargetComponent.useRecord = useRecord;
    return TargetComponent;
}
