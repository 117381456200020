import { __assign } from "tslib";
import './split-button-group.scss';
import * as React from 'react';
import classNames from 'classnames';
import { Button as NextButton } from '@fusion/lib';
import { HocBaseComponents, useCreatePluginInstance, } from '@/components/cn-utils';
var CnSplitButtonGroupView = React.forwardRef(function (props, ref) {
    var className = props.className;
    return (React.createElement(NextButton.Group, __assign({ ref: ref, className: classNames('cn-ui-button-group', className) }, props)));
});
export var CnSplitButtonGroup = React.forwardRef(function (props, ref) {
    var _a;
    var pluginIns = useCreatePluginInstance('CnSplitButtonGroup', props === null || props === void 0 ? void 0 : props.usePlugin);
    var plugins = (_a = pluginIns === null || pluginIns === void 0 ? void 0 : pluginIns.getPlugin) === null || _a === void 0 ? void 0 : _a.call(pluginIns);
    if (plugins.length === 0) {
        return React.createElement(CnSplitButtonGroupView, __assign({}, props, { ref: ref }));
    }
    return HocBaseComponents(CnSplitButtonGroupView, { props: props, plugins: plugins, ref: ref });
});
