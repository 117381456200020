import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { request } from 'cn-request';
import { HABIT_STORAGE_KEY } from './constant/key';
/**
 * 组件习惯存储
 */
var ComponentHabitStorage = /** @class */ (function () {
    /**
     * 构造函数
     * @param options 组件习惯选项
     */
    function ComponentHabitStorage(options) {
        /**
         * 当 this.options.storeType = 'memory' 时，用于将数据存在变量中
         */
        this.memoryData = {};
        // 组件实例标识符
        var instanceIdentifier = 'normal';
        if (typeof options.instanceIdentifier === 'string') {
            instanceIdentifier = options.instanceIdentifier;
        }
        if (typeof options.instanceIdentifier === 'function') {
            instanceIdentifier = options.instanceIdentifier();
        }
        this.options = __assign(__assign({}, options), { instanceIdentifier: instanceIdentifier, storeType: options.storeType || 'localStorage', habitMaxNum: options.habitMaxNum || 10 });
    }
    /**
     * 获取组件的所有习惯数据
     * @param componentName 组件名称
     * @param data 组件的所有习惯数据
     */
    ComponentHabitStorage.prototype.setComponentHabitData = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var habitData, newHabitData;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.getAllHabitData()];
                    case 1:
                        habitData = _c.sent();
                        newHabitData = __assign(__assign({}, habitData), (_a = {}, _a[this.options.componentName] = __assign(__assign({}, habitData[this.options.componentName]), (_b = {}, _b[this.options.instanceIdentifier] = data, _b)), _a));
                        if (this.options.storeType === 'memory') {
                            this.memoryData = newHabitData;
                            return [2 /*return*/];
                        }
                        localStorage.setItem(HABIT_STORAGE_KEY, JSON.stringify(newHabitData));
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * 获取 CNUI 所有组件且所有组件实例的习惯数据
     * @returns CNUI 所有组件且所有组件实例的习惯数据
     */
    ComponentHabitStorage.prototype.getAllHabitData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var saveDataStr;
            return __generator(this, function (_a) {
                if (this.options.storeType === 'memory') {
                    return [2 /*return*/, this.memoryData];
                }
                saveDataStr = localStorage.getItem(HABIT_STORAGE_KEY) || '';
                if (saveDataStr) {
                    try {
                        return [2 /*return*/, JSON.parse(saveDataStr)];
                    }
                    catch (err) {
                        console.error(err);
                    }
                }
                return [2 /*return*/, {}];
            });
        });
    };
    /**
     * 获取组件的所有习惯数据,包含所有的组件实例
     * @returns 组件的所有习惯数据,包含所有的组件实例
     */
    ComponentHabitStorage.prototype.getComponentAllHabitData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var habitData;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getAllHabitData()];
                    case 1:
                        habitData = _a.sent();
                        return [2 /*return*/, habitData[this.options.componentName] || {}];
                }
            });
        });
    };
    /**
     * 新增用户习惯
     * @param item 用户习惯选项数据
     */
    ComponentHabitStorage.prototype.addHabit = function (item) {
        return __awaiter(this, void 0, void 0, function () {
            var newItem, habitList, newHabitList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        newItem = __assign(__assign({}, item), { createdAt: Date.now() });
                        return [4 /*yield*/, this.getHabitList()];
                    case 1:
                        habitList = _a.sent();
                        newHabitList = __spreadArray([
                            newItem
                        ], habitList.filter(function (_a) {
                            var key = _a.key;
                            return key !== item.key;
                        }), true);
                        if (newHabitList.length > this.options.habitMaxNum) {
                            newHabitList = newHabitList.slice(0, this.options.habitMaxNum);
                        }
                        if (!(this.options.storeType === 'requestService')) return [3 /*break*/, 3];
                        return [4 /*yield*/, request({
                                method: 'POST',
                                url: 'https://pre-cnui.cainiao-inc.com/api/cnui/lab/updateConfig',
                                data: {
                                    key: "".concat(this.options.componentName, "__").concat(this.options.instanceIdentifier),
                                    content: JSON.stringify(newHabitList),
                                },
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                    case 3:
                        this.setComponentHabitData(newHabitList);
                        return [2 /*return*/];
                }
            });
        });
    };
    /**
     * 获取用户已保存的习惯列表
     * @param componentHabitData 组件的习惯数据
     * @returns 用户已保存的习惯列表
     */
    ComponentHabitStorage.prototype.getHabitList = function (componentHabitData) {
        return __awaiter(this, void 0, void 0, function () {
            var instanceIdentifier, response, result, habitData, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        instanceIdentifier = this.options.instanceIdentifier;
                        if (!(this.options.storeType === 'requestService')) return [3 /*break*/, 2];
                        return [4 /*yield*/, request({
                                method: 'GET',
                                url: 'https://pre-cnui.cainiao-inc.com/api/cnui/lab/getConfig',
                                params: {
                                    key: "".concat(this.options.componentName, "__").concat(this.options.instanceIdentifier),
                                },
                            })];
                    case 1:
                        response = _a.sent();
                        if (response.status === 200) {
                            result = response.data;
                            if (result.success) {
                                if (!result.data)
                                    return [2 /*return*/, []];
                                return [2 /*return*/, JSON.parse(result.data.content)];
                            }
                        }
                        return [2 /*return*/, []];
                    case 2:
                        if (componentHabitData)
                            return [2 /*return*/, componentHabitData[instanceIdentifier] || []];
                        _a.label = 3;
                    case 3:
                        _a.trys.push([3, 5, , 6]);
                        return [4 /*yield*/, this.getComponentAllHabitData()];
                    case 4:
                        habitData = _a.sent();
                        return [2 /*return*/, habitData[instanceIdentifier] || []];
                    case 5:
                        err_1 = _a.sent();
                        console.error(err_1);
                        return [2 /*return*/, []];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    return ComponentHabitStorage;
}());
export { ComponentHabitStorage };
