import $i18n from 'panda-i18n';
import './index.scss';
import React from 'react';
import { CnMenu, CnMenuItem } from '@/components/cn-menu';
import { CnIcon } from '@/components/cn-icon';
import { setGlobalSize, useSizeChange, withNativeProps, } from '@cainiaofe/cn-ui-common';
import { CnDropdown } from '@/components/cn-dropdown';
export var CnCompactSelect = function (props) {
    var _a = props.triggerType, triggerType = _a === void 0 ? 'click' : _a, onChange = props.onChange;
    var size = useSizeChange({ size: props.value }).size;
    var usedLangList = [
        {
            value: 'small',
            label: $i18n.get({ id: 'Compact', dm: '紧凑', ns: 'CnCompactSelect' }),
        },
        {
            value: 'medium',
            label: $i18n.get({ id: 'Loose', dm: '宽松', ns: 'CnCompactSelect' }),
        },
    ];
    return (React.createElement(CnDropdown, { trigger: withNativeProps(props, React.createElement("span", { className: "cn-compact-select", "data-testid": "CnCompactSelect", "aria-haspopup": "true" }, size === 'small' ? (React.createElement(CnIcon, { type: "icon-task-list" })) : (React.createElement(CnIcon, { type: "icon-density-compact" })))), triggerType: triggerType },
        React.createElement(CnMenu, { className: "cn-compact-select-menu" }, usedLangList.map(function (langObj) { return (React.createElement(CnMenuItem, { onClick: function () {
                setGlobalSize(langObj.value);
                onChange === null || onChange === void 0 ? void 0 : onChange(langObj.value);
            }, key: langObj.value }, langObj.label)); }))));
};
