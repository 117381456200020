import { formilyReactive } from '@/form/formily';
export var slot = {
    widgetSlot: formilyReactive.observable.shallow({}),
    searchSlot: formilyReactive.observable.shallow({}),
};
export var registerWidgetSlot = function (name, comp) {
    slot.widgetSlot[name] = comp;
};
export var registerSearchSlot = function (name, comp) {
    slot.searchSlot[name] = comp;
};
