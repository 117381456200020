import { __assign, __rest } from "tslib";
import './view/layout.scss';
import React, { createContext, useContext } from 'react';
import { useFormLabelAlign } from '@cainiaofe/cn-ui-common';
import { useResponsiveFormLayout } from './useResponsiveFormLayout';
import classNames from 'classnames';
import { IFormLayoutProps } from './types/form-layout';
import { IFormLayoutContext } from './types/form-layout-context';
import { useFormLabelCol } from './hooks/use-form-label-col';
export { IFormLayoutContext, IFormLayoutProps };
export var FormLayoutDeepContext = createContext(null);
export var FormLayoutShallowContext = createContext(null);
export var useFormDeepLayout = function () { return useContext(FormLayoutDeepContext); };
export var useFormShallowLayout = function () { return useContext(FormLayoutShallowContext); };
export var useFormLayout = function () {
    return __assign(__assign({}, useFormDeepLayout()), useFormShallowLayout());
};
export var CnFormLayout = function (_a) {
    var _b;
    var shallow = _a.shallow, children = _a.children, prefix = _a.prefix, className = _a.className, style = _a.style, labelAlignProps = _a.labelAlign, labelColProps = _a.labelCol, filterDisplay = _a.filterDisplay, otherProps = __rest(_a, ["shallow", "children", "prefix", "className", "style", "labelAlign", "labelCol", "filterDisplay"]);
    var labelCol = useFormLabelCol({
        labelAlign: labelAlignProps,
        labelCol: labelColProps,
    });
    var labelAlign = useFormLabelAlign(labelAlignProps);
    var _c = useResponsiveFormLayout(__assign(__assign({}, otherProps), { labelAlign: labelAlign, labelCol: labelCol })), ref = _c.ref, props = _c.props;
    var deepLayout = useFormDeepLayout();
    var newDeepLayout = __assign({}, deepLayout);
    if (!shallow) {
        Object.assign(newDeepLayout, props);
    }
    else {
        if (props.size) {
            newDeepLayout.size = props.size;
        }
        if (props.colon) {
            newDeepLayout.colon = props.colon;
        }
    }
    if (filterDisplay === 'flex') {
        Object.assign(newDeepLayout, { filterDisplay: filterDisplay, removeEmptyLabel: true });
    }
    return (React.createElement("div", { "data-name": "CnFormLayout", ref: ref, style: style, className: classNames(CN_UI_HASH_CLASS_NAME, className, 'cn-ui-layout', 'cn-ui-form-layout', (_b = {
                'cn-ui-form-layout--single-center': otherProps.layoutMode === 'single-col',
                'cn-ui-form-layout--single-left': otherProps.layoutMode === 'single-left'
            },
            _b["cn-ui-form-".concat(props.layout)] = props.layout,
            _b["cn-ui-form-".concat(props.size)] = props.size,
            _b)) },
        React.createElement(FormLayoutDeepContext.Provider, { value: newDeepLayout },
            React.createElement(FormLayoutShallowContext.Provider, { value: shallow ? props : undefined }, children))));
};
CnFormLayout.defaultProps = {
    shallow: true,
};
CnFormLayout.useFormDeepLayout = useFormDeepLayout;
CnFormLayout.useFormShallowLayout = useFormShallowLayout;
CnFormLayout.useFormLayout = useFormLayout;
