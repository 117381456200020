import { __assign, __rest } from "tslib";
import React, { useEffect } from 'react';
import { useSizeChange } from '@cainiaofe/cn-ui-common';
import { HocBaseComponents, useCreatePluginInstance, } from '@/components/cn-utils';
import { CnPageView } from './page-view';
export function CnPage(props) {
    var _a;
    var sizeProps = props.size, restProps = __rest(props, ["size"]);
    var size = useSizeChange({ size: sizeProps }).size;
    var pluginIns = useCreatePluginInstance('CnPage', props === null || props === void 0 ? void 0 : props.usePlugin);
    var plugins = (_a = pluginIns === null || pluginIns === void 0 ? void 0 : pluginIns.getPlugin) === null || _a === void 0 ? void 0 : _a.call(pluginIns);
    useEffect(function () {
        document.body.classList.remove('cn-small');
        document.body.classList.remove('cn-medium');
        document.body.classList.remove('cn-large');
        if (['small', 'medium', 'large'].includes(size)) {
            document.body.classList.add("cn-".concat(size));
        }
    }, [size]);
    if ((plugins === null || plugins === void 0 ? void 0 : plugins.length) === 0) {
        return React.createElement(CnPageView, __assign({}, restProps));
    }
    return HocBaseComponents(CnPageView, {
        props: props,
        plugins: plugins,
    });
}
CnPage.defaultProps = {
    embeddable: false,
    noPadding: false,
    noCard: false,
    contentFill: true,
    viewMode: 'normal',
};
CnPage.displayName = 'CnPage';
