import { formilyReact } from '@/form/formily';
import { CnTimePicker2 as UiCnTimePicker2, CnRangeTimePicker2 as UiCnRangeTimePicker2, CnTimePickerPro as UiCnTimePickerPro, CnRangeTimePickerPro as UiCnRangeTimePickerPro, } from '@/components/cn-time-picker-pro';
import { mapStatus } from '@/form/__builtins__';
/**
 * @deprecated 请使用 CnTimePickerPro
 */
export var CnTimePicker2 = formilyReact.connect(UiCnTimePicker2, formilyReact.mapProps(mapStatus));
/**
 * @deprecated 请使用 CnRangeTimePickerPro
 */
export var CnRangeTimePicker2 = formilyReact.connect(UiCnRangeTimePicker2, formilyReact.mapProps(mapStatus));
export var CnTimePickerPro = formilyReact.connect(UiCnTimePickerPro, formilyReact.mapProps(mapStatus));
export var CnRangeTimePickerPro = formilyReact.connect(UiCnRangeTimePickerPro, formilyReact.mapProps(mapStatus));
