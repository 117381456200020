import { isMiniApp, isWeChatMiniProgram, isByteDanceMicroApp, isWeb, } from '@uni/env';
import isNil from 'lodash/isNil';
/**
 * 格式化version
 * @param v
 * @returns {string}
 */
function formatVersion(v) {
    return String(v).replace(/_/g, '.');
}
/**
 * 根据ua获取操作系统名称和版本
 * @param ua
 * @returns {{name: string, version: string}}
 */
function getOSInfo(ua) {
    var OSList = {
        ios: /(like Mac OS X)/i,
        android: /Android/i,
        wp: /Windows Phone/i,
        bb: /(BlackBerry)|(RIM)/i,
        win: /(Windows 95)|(Win95)|(Windows_95)|(Windows ME)|(Windows 98)|(Win98)|(Windows NT 5.0)|(Windows 2000)|(Windows NT)|(WinNT)|(Windows XP)/i,
        linux: /(Linux)|(X11)/i,
        macos: /(Mac_PowerPC)|(Macintosh)/i,
        robot: /(nuhk)|(Googlebot)|(Yammybot)|(Openbot)|(Slurp)|(MSNBot)|(ia_archiver)/i,
    };
    var os = {
        name: 'unknown',
        version: '0.0.0',
    };
    if (/Windows\sPhone\s(?:OS\s)?([\d.]+)/.test(ua)) {
        os.version = RegExp.$1;
    }
    else if (/Safari/.test(ua) && /Android[\s/]([\d.]+)/.test(ua)) {
        os.version = RegExp.$1;
    }
    else if (/(iPhone|iPad|iPod)/.test(ua) &&
        /OS ([\d_.]+) like Mac OS X/.test(ua)) {
        os.version = RegExp.$1;
    }
    os.version = formatVersion(os.version);
    for (var _osName in OSList) {
        var Reg = OSList[_osName];
        if (Reg.test(ua)) {
            os.name = _osName;
            break;
        }
    }
    return os;
}
// 判断是否为严格小程序模式 （非小程序内嵌 webview）
export var isStrictMiniApp = isMiniApp && !isWeb;
export var isStrictWechatMiniProgram = isWeChatMiniProgram && !isWeb;
export var isStrictByteDanceMicroApp = isByteDanceMicroApp && !isWeb;
// 判断所有小程序平台（当前仅：阿里系、微信、字节）
export var isMiniappPlatform = !isWeb && (isMiniApp || isWeChatMiniProgram || isByteDanceMicroApp);
var systemInfo;
if (isStrictMiniApp) {
    systemInfo = my.getSystemInfoSync();
}
else if (isStrictWechatMiniProgram) {
    systemInfo = wx.getSystemInfoSync();
}
else if (isStrictByteDanceMicroApp) {
    systemInfo = tt.getSystemInfoSync();
}
var name = 'unknown';
var version = '0.0.0';
if (isStrictWechatMiniProgram) {
    var system = systemInfo.system;
    name = system.split(' ')[0].toLowerCase();
    version = system.split(' ')[1];
}
else if (isStrictMiniApp) {
    var system = systemInfo.system, platform = systemInfo.platform;
    name = platform.toLowerCase();
    version = system;
}
else if (isStrictByteDanceMicroApp) {
    var system = systemInfo.system, platform = systemInfo.platform;
    name = platform.toLowerCase();
    version = system;
}
else {
    var UA = isNil(navigator) ? '' : navigator.userAgent;
    // web环境，通过ua计算
    var info = getOSInfo(UA);
    name = info.name;
    version = info.version;
}
export { systemInfo };
export var screenWidth = systemInfo && !isNil(systemInfo.screenWidth)
    ? systemInfo.screenWidth
    : window.screen.width;
export var screenHeight = systemInfo && !isNil(systemInfo.screenHeight)
    ? systemInfo.screenHeight
    : window.screen.height;
export var windowWidth = systemInfo && !isNil(systemInfo.windowWidth)
    ? systemInfo.windowWidth
    : document.documentElement.clientWidth;
export var windowHeight = systemInfo && !isNil(systemInfo.windowHeight)
    ? systemInfo.windowHeight
    : document.documentElement.clientHeight;
export var dpr = systemInfo && (systemInfo.devicePixelRatio || systemInfo.pixelRatio)
    ? systemInfo.devicePixelRatio || systemInfo.pixelRatio
    : window.devicePixelRatio;
export var osName = name;
export var osVersion = version;
export var isIOS = name === 'ios';
export var isAndroid = name === 'android';
export var screenRate = screenWidth / 750;
export var screenPixelRatio = isMiniappPlatform ? 1 : windowWidth / 750;
/**
 * 判断是否是生产环境
 * @type {Boolean}
 */
export var isProduction = function () {
    var PRODUCTION_ENV = 'production';
    var result = false;
    try {
        if (process.env.NODE_ENV === PRODUCTION_ENV) {
            result = true;
        }
    }
    catch (err) {
        //
    }
    return result;
};
