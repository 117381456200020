import { isAdditionComponent } from './is-addition-component';
var isRemoveComponent = function (schema) {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'Remove')) > -1;
};
var isCopyComponent = function (schema) {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'Copy')) > -1;
};
var isMoveUpComponent = function (schema) {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'MoveUp')) > -1;
};
var isMoveDownComponent = function (schema) {
    var _a, _b;
    return ((_b = (_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf) === null || _b === void 0 ? void 0 : _b.call(_a, 'MoveDown')) > -1;
};
/**
 * 是否是可编辑卡片的的操作组件
 * 如添加卡片、删除卡片、复制卡片、移动卡片
 */
export var isOperationComponent = function (schema) {
    return (isAdditionComponent(schema) ||
        isRemoveComponent(schema) ||
        isCopyComponent(schema) ||
        isMoveDownComponent(schema) ||
        isMoveUpComponent(schema));
};
