import $i18n from 'panda-i18n';
import React from 'react';
import { events } from '@/components/cn-utils';
import { CnBox } from '@/components/cn-box';
import { cssPrefix, SHOW_PANEL } from '../../constant';
import './index.scss';
import classNames from 'classnames';
export default function JobMessage(props) {
    var type = props.type, data = props.data;
    var jobName = data.jobName, status = data.status;
    var onClick = function () { return events.emit(SHOW_PANEL); };
    var view = (React.createElement("span", { className: classNames(CN_UI_HASH_CLASS_NAME, "".concat(cssPrefix, "message")), onClick: onClick }, $i18n.get({ id: 'ClickToView', dm: '点击查看', ns: 'CnAsyncJobsPro' })));
    switch (status) {
        case 'FAILED':
            if (type === 'export') {
                return (React.createElement(CnBox, { direction: "row", spacing: 4 },
                    React.createElement("span", null, $i18n.get({
                        id: 'ExportFailedjobName',
                        dm: '导出失败：{jobName}',
                        ns: 'CnAsyncJobsPro',
                    }, { jobName: jobName })),
                    view));
            }
            return (React.createElement(CnBox, { direction: "row", spacing: 4 },
                React.createElement("span", null, $i18n.get({
                    id: 'ImportFailedjobName',
                    dm: '导入失败：{jobName}',
                    ns: 'CnAsyncJobsPro',
                }, { jobName: jobName })),
                view));
        case 'SOME':
            if (type === 'export') {
                return (React.createElement(CnBox, { direction: "row", spacing: 4 },
                    React.createElement("span", null, $i18n.get({
                        id: 'TheExportPartIsCompletejobName',
                        dm: '导出部分完成：{jobName}',
                        ns: 'CnAsyncJobsPro',
                    }, { jobName: jobName })),
                    view));
            }
            return (React.createElement(CnBox, { direction: "row", spacing: 4 },
                React.createElement("span", null, $i18n.get({
                    id: 'TheImportPartIsCompletejobName',
                    dm: '导入部分完成：{jobName}',
                    ns: 'CnAsyncJobsPro',
                }, { jobName: jobName })),
                view));
        case 'SUCCEEDED':
            if (type === 'export') {
                return (React.createElement(CnBox, { direction: "row", spacing: 4 },
                    React.createElement("span", null, $i18n.get({
                        id: 'ExportCompletejobName',
                        dm: '导出完成：{jobName}',
                        ns: 'CnAsyncJobsPro',
                    }, { jobName: jobName })),
                    view));
            }
            return (React.createElement(CnBox, { direction: "row", spacing: 4 },
                React.createElement("span", null, $i18n.get({
                    id: 'ImportCompletejobName',
                    dm: '导入完成：{jobName}',
                    ns: 'CnAsyncJobsPro',
                }, { jobName: jobName }))));
        default:
            return null;
    }
}
