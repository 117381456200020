import { __assign, __extends, __rest } from "tslib";
import { withI18n } from 'panda-i18n';
import * as React from 'react';
import classNames from 'classnames';
import BigNumber from 'bignumber.js';
import NextNumberPicker from './number-picker';
import locale from '@/locales';
var CnNumberPickerOrigin = /** @class */ (function (_super) {
    __extends(CnNumberPickerOrigin, _super);
    function CnNumberPickerOrigin(props) {
        var _this = _super.call(this, props) || this;
        _this.saveNumberPickerRef = function (ref) {
            _this.numberPickerRef = ref;
        };
        _this.onChange = function (newValue, e) {
            var onChange = _this.props.onChange;
            if (!('value' in _this.props)) {
                _this.setState({
                    value: newValue,
                });
            }
            onChange && onChange(newValue, e);
        };
        var value;
        if ('value' in props) {
            value = props.value;
        }
        else {
            value = props.defaultValue;
        }
        _this.state = {
            value: value === undefined || value === null ? undefined : value,
        };
        return _this;
    }
    CnNumberPickerOrigin.getDerivedStateFromProps = function (nextProps, prevState) {
        if ('value' in nextProps && nextProps.value !== prevState.value) {
            var value = nextProps.value;
            return {
                value: value === undefined || value === null ? '' : value,
            };
        }
        return null;
    };
    CnNumberPickerOrigin.prototype.focus = function () {
        this.numberPickerRef.focus();
    };
    CnNumberPickerOrigin.prototype.render = function () {
        var _a = this.props, className = _a.className, readOnly = _a.readOnly, otherProps = __rest(_a, ["className", "readOnly"]);
        var classes = classNames(CN_UI_HASH_CLASS_NAME, {
            'cn-ui-number-picker': true,
            'cn-ui-number-picker-readonly': !!readOnly,
        }, className);
        if (readOnly) {
            var bigNumberValue = new BigNumber(this.state.value);
            var displayValue = bigNumberValue.toString();
            if (!bigNumberValue.isNaN()) {
                if (otherProps.format) {
                    displayValue = otherProps.format(displayValue);
                }
            }
            else {
                displayValue = '- -';
            }
            return (React.createElement("div", { className: classes },
                otherProps.innerBefore,
                otherProps.addonTextBefore,
                displayValue,
                otherProps.addonTextAfter,
                otherProps.innerAfter));
        }
        return (React.createElement(NextNumberPicker, __assign({ "data-name": "CnNumberPicker", ref: this.saveNumberPickerRef }, otherProps, { prefix: "cn-next-", className: classes, value: this.state.value, onChange: this.onChange })));
    };
    CnNumberPickerOrigin.displayName = 'CnNumberPicker';
    CnNumberPickerOrigin.defaultProps = {
        type: 'normal',
        maxLength: 17,
        precision: 0,
    };
    return CnNumberPickerOrigin;
}(React.Component));
export var CnNumberPicker = withI18n(CnNumberPickerOrigin, {
    componentName: 'CnNumberPicker',
    locale: locale,
    forwardRef: true,
});
