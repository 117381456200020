import { formilyReact } from '@/form/formily';
import { CnCheckbox as UiCnCheckbox, CnCheckboxGroup as UiCnCheckboxGroup, } from '@/components/cn-checkbox';
import { mapStatus } from '@/form/__builtins__';
export var CnCheckbox = formilyReact.connect(UiCnCheckbox, formilyReact.mapProps({
    value: 'checked',
    onInput: 'onChange',
}));
export var CnCheckboxGroup = formilyReact.connect(UiCnCheckboxGroup, formilyReact.mapProps(mapStatus, {
    dataSource: true,
}));
