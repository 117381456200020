import { __assign } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { SortableHandle } from 'react-sortable-hoc';
import { useArray } from '../hooks';
import { cssPrefix } from '../constant/css-prefix';
var BaseSortHandle = SortableHandle(function (props) {
    return (React.createElement(CnIcon, __assign({ size: "large" }, props, { type: "hamburger", className: classNames("".concat(cssPrefix, "-sort-handle"), props.className), style: props.style })));
});
export var SortHandle = function () {
    var _a;
    var array = useArray();
    if (!array)
        return null;
    if (((_a = array.field) === null || _a === void 0 ? void 0 : _a.pattern) !== 'editable')
        return null;
    return React.createElement(BaseSortHandle, null);
};
