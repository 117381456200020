import { __assign, __rest } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { Rating } from '@fusion/lib';
import { useNextLocale } from '../cn-utils';
export var CnRating = function (props) {
    var className = props.className, restProps = __rest(props, ["className"]);
    var ratingLocale = useNextLocale('Rating');
    return (React.createElement(Rating, __assign({ "data-name": "CnRating", className: classNames(className, 'cn-ui-rating') }, restProps, { locale: ratingLocale, size: "large" })));
};
CnRating.displayName = 'CnRating';
CnRating.defaultProps = {
    count: 5,
    allowHalf: false,
    defaultValue: 0,
    allowClear: true,
};
