import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import classNames from 'classnames';
import { formilyReact } from '@/form/formily';
import { CnFormArrayBase } from '@/form/cn-form-array-base';
export var CnArrayTableRemove = React.forwardRef(function (props, ref) {
    var self = formilyReact.useField();
    return (React.createElement(CnFormArrayBase.Remove, __assign({ ref: ref }, props, { className: classNames(props.className, 'cn-ui-array-table-remove') }), props.children ||
        props.title ||
        self.title ||
        $i18n.get({
            id: '31255239516622848.CNTM',
            dm: '删除',
            ns: 'CnForm',
        })));
});
