/**
 * 用于限制传入的数字在 max 和 min 之间
 * @param position
 * @param min
 * @param max
 */
export function bound(position, min, max) {
    var ret = position;
    if (min !== undefined) {
        ret = Math.max(position, min);
    }
    if (max !== undefined) {
        ret = Math.min(ret, max);
    }
    return ret;
}
