import { __assign, __rest } from "tslib";
import * as React from 'react';
import $i18n from 'panda-i18n';
import { formilyCore, formilyReact } from '@/form/formily';
import { useFormLayout } from '@/form/cn-form-layout';
import { CnFormItem as UiCnFormItem } from '@/components/cn-form-item';
// 适配
export var CnFormItem = formilyReact.connect(function (props) {
    var _a, _b, _c;
    var children = props.children, fullWidth = props.fullWidth, restProps = __rest(props, ["children", "fullWidth"]);
    var layout = useFormLayout();
    function getChildren(child) {
        if (fullWidth) {
            return React.cloneElement(child, { style: { width: '100%' } });
        }
        return child;
    }
    return (React.createElement(UiCnFormItem, __assign({}, restProps, { labelCol: (_a = props.labelCol) !== null && _a !== void 0 ? _a : layout.labelCol, wrapperCol: (_b = props.wrapperCol) !== null && _b !== void 0 ? _b : layout.wrapperCol, labelTextAlign: (_c = props.labelTextAlign) !== null && _c !== void 0 ? _c : layout.labelTextAlign }), getChildren(children)));
}, formilyReact.mapProps(function (props, field) {
    var baseProps = {
        title: props.title || props.label || field.title,
        readOnly: field.readOnly,
        extra: props.extra || field.description,
    };
    if (formilyCore.isVoidField(field)) {
        return __assign({ asterisk: props.asterisk }, baseProps);
    }
    if (!field)
        return props;
    var takeFeedbackStatus = function () {
        if (field.validating)
            return 'pending';
        return field.decoratorProps.feedbackStatus || field.validateStatus;
    };
    // 获取错误信息
    var takeMessage = function () {
        var split = function (messages) {
            return messages.reduce(function (buf, text) {
                if (!text)
                    return buf;
                return buf.concat(text);
            }, []);
        };
        if (field.validating)
            return;
        if (props.feedbackText)
            return props.feedbackText;
        if (field.selfErrors.length) {
            if (props.requiredMessage) {
                var requiredErrorsIndex = field.selfErrors.indexOf($i18n.get({
                    id: 'ThisFieldIsRequired',
                    dm: '该字段是必填字段',
                    ns: 'CnForm',
                }));
                if (requiredErrorsIndex > -1) {
                    field.selfErrors.splice(requiredErrorsIndex, 1, props.requiredMessage);
                }
            }
            return split(field.selfErrors);
        }
        if (field.selfWarnings.length)
            return split(field.selfWarnings);
        if (field.selfSuccesses.length)
            return split(field.selfSuccesses);
    };
    // 获取是否有 required mark
    var takeAsterisk = function () {
        if (field.required && field.pattern !== 'readPretty') {
            return true;
        }
        if ('asterisk' in props) {
            return props.asterisk;
        }
        return false;
    };
    return __assign({ title: props.title || props.label || field.title, validateState: takeFeedbackStatus(), errors: takeMessage(), asterisk: takeAsterisk() }, baseProps);
}));
