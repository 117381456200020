import './form-tab-title.scss';
import React from 'react';
import { CnBadge } from '@/components/cn-badge';
/**
 * 当有错误时，显示错误数量
 */
export var FormTabTitle = function (props) {
    var field = props.field, title = props.title, key = props.key;
    var errors = field.form.queryFeedbacks({
        type: 'error',
        address: "".concat(field.address.concat(key), ".*"),
    });
    if (errors.length) {
        return (React.createElement(CnBadge, { count: errors.length, className: "cn-ui-form-tab-badge" }, title));
    }
    return React.createElement(React.Fragment, null, title);
};
