import { __assign } from "tslib";
import assignWith from 'lodash/assignWith';
export function mergeProps() {
    var items = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        items[_i] = arguments[_i];
    }
    function customizer(objValue, srcValue) {
        return srcValue === undefined ? objValue : srcValue;
    }
    var ret = __assign({}, items[0]);
    for (var i = 1; i < items.length; i++) {
        ret = assignWith(ret, items[i], customizer);
    }
    return ret;
}
