import { __assign } from "tslib";
import * as React from 'react';
import $i18n from 'panda-i18n';
import { CnMenuButton } from '@/components/cn-menu-button';
import { CnButton } from '@/components/cn-button';
import { globalSplit, globalOpreateMoreIconSize } from '../../global';
import { isDef, isFunction, isNumber, isPlainObject } from '../../utils';
export var operateCode = "operate".concat(Date.now());
// 随机code 请注意不要放在pipeline内部，否则可能出现
export function operateColumnPipeline(opts, calOperateButtonItemWithNoRule) {
    var width = opts.width, buttons = opts.buttons, _a = opts.showButtonNum, showButtonNum = _a === void 0 ? 3 : _a, moreBtnProps = opts.moreBtnProps, render = opts.render, columnsProps = opts.columnsProps, split = opts.split, _b = opts.splitWidth, splitWidth = _b === void 0 ? 16 : _b, contentWidthRule = opts.contentWidthRule, _c = opts.lock, lock = _c === void 0 ? 'right' : _c;
    // 获取国际化文本
    var finalName = $i18n.get({
        id: '31255239218827264.CNTM',
        dm: '操作',
        ns: 'CnBaseTable',
    });
    var finalOperateMoreText = $i18n.get({
        id: 'More',
        dm: '更多',
        ns: 'CnBaseTable',
    });
    var finalSplit = split || globalSplit;
    var finalSplitWidth = parseInt(splitWidth) || 10;
    var showButtonNumReality = showButtonNum > 0 ? showButtonNum : (buttons || []).length;
    // 定义操作列对象
    var operateColumn = __assign(__assign({ name: finalName, lock: lock, sizeFixed: true, maxWidth: false, code: operateCode, contentWidthRule: getFinalRule({
            contentWidthRule: contentWidthRule,
            render: render,
            finalName: finalName,
            finalOperateMoreText: finalOperateMoreText,
            buttons: buttons,
            splitWidth: finalSplitWidth,
            showButtonNum: showButtonNumReality,
            calOperateButtonItemWithNoRule: calOperateButtonItemWithNoRule,
        }) }, ((columnsProps instanceof Function && columnsProps()) || [])), { width: width, getCellProps: function () {
            return {
                className: 'cn-table-cell-operate',
            };
        }, render: function (val, record, index) {
            var showButtons = []; // 展示的buttons
            var hideButtons = []; // 隐藏起来的buttons
            // 自定义render优先级最高
            if (render instanceof Function) {
                showButtons = render(val, record, index);
                if (showButtons.$$typeof)
                    return showButtons;
            }
            // 数据优先级最低
            if (Array.isArray(buttons)) {
                if (buttons.length - showButtonNumReality === 1) {
                    showButtonNumReality += 1;
                }
                buttons.forEach(function (buttonItem, i) {
                    var _a;
                    if (buttonItem &&
                        buttonItem.visible instanceof Function &&
                        !buttonItem.visible(record, index)) {
                        return;
                    }
                    var shouldShow = showButtons.length / 2 < showButtonNumReality;
                    var item;
                    if ([buttonItem].some(isFunction)) {
                        // 这里去掉了 buttonItem?.children
                        var renderFunc = buttonItem instanceof Function
                            ? buttonItem
                            : buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children;
                        var itemTmp = renderFunc(record, index);
                        if (isPlainObject(itemTmp) && itemTmp.$$typeof) {
                            item = itemTmp;
                        }
                    }
                    else if (isPlainObject(buttonItem)) {
                        var children = buttonItem.children, onClick_1 = buttonItem.onClick, _b = buttonItem.type, type = _b === void 0 ? 'primary' : _b, btnProps = buttonItem.btnProps, isDisabled = buttonItem.isDisabled;
                        var disabled_1 = isFunction(isDisabled)
                            ? !!isDisabled(record, index)
                            : false;
                        item = shouldShow ? (React.createElement(CnButton, __assign({ text: true, key: i, type: type, disabled: disabled_1, className: "cn-table-operate-btn", style: { height: 'auto' }, size: "small", onClick: function (e) {
                                return isFunction(onClick_1) && onClick_1(e, record, index);
                            } }, (btnProps || {})), children instanceof Function
                            ? children(record, index)
                            : children)) : (
                        // hideButton 要保证其触发范围
                        React.createElement(CnMenuButton.Item, { onClick: function (e) {
                                return !disabled_1 && isFunction(onClick_1) && onClick_1(e, record, index);
                            } },
                            React.createElement(CnButton, __assign({ text: true, key: i, disabled: disabled_1, 
                                // onClick={(e) => {
                                //   if (!disabled && isFunction(onClick)) {
                                //     onClick(e, record, index);
                                //   }
                                // }}
                                type: type, className: "cn-table-operate-btn", style: { height: 'auto' }, size: "small" }, (btnProps || {})), children instanceof Function
                                ? children(record, index)
                                : children)));
                    }
                    if (isDef(item)) {
                        if (shouldShow) {
                            showButtons.push(item);
                            showButtons.push(React.createElement("div", { style: { width: finalSplitWidth }, className: "hp-table-split" }, finalSplit));
                        }
                        else if (['MenuButtonItem', 'CnMenu.Item'].indexOf((_a = item === null || item === void 0 ? void 0 : item.type) === null || _a === void 0 ? void 0 : _a.displayName) === -1) {
                            hideButtons.push(React.createElement(CnMenuButton.Item, null, item));
                        }
                        else {
                            hideButtons.push(item);
                        }
                    }
                });
            }
            showButtons.pop();
            return (React.createElement("div", { className: "cn-table-operate" },
                showButtons,
                hideButtons.length > 0 ? (React.createElement(React.Fragment, null,
                    React.createElement("div", { style: { width: finalSplitWidth }, className: "hp-table-split" }, finalSplit),
                    React.createElement(CnMenuButton, __assign({ text: true, popupProps: {
                            v2: true,
                        }, style: { height: 'auto' }, size: "small", className: "cn-table-operate-btn", type: "primary", autoWidth: false, label: finalOperateMoreText, popupClassName: "cn-table-operate-pop" }, (moreBtnProps || {})), hideButtons.map(function (button) {
                        // <MenuButton.Item>{button}</MenuButton.Item>
                        // console.log(button);
                        return button;
                    })))) : null));
        } });
    // 在所有列的最后增加操作列
    return function (pipeline) {
        return pipeline.columns(pipeline.getColumns().concat([operateColumn]));
    };
}
function calVisibleButtonTotal(props, eachCal, noWidthRuleCal) {
    var buttons = props.buttons, record = props.record, index = props.index, maxButtonNum = props.maxButtonNum, finalOperateMoreText = props.finalOperateMoreText;
    var total = null;
    var calNum = 0;
    buttons.forEach(function (buttonItem, buttonIndex) {
        var isVisible = (buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.visible) instanceof Function
            ? buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.visible(record, index)
            : true;
        // 单独计算这种无规则render，需要使用探针，所以无论visible都要进行计算
        if ([buttonItem, buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children].some(isFunction) &&
            noWidthRuleCal instanceof Function &&
            !buttonItem.widthRule) {
            // 在什么都没有的情况下，这里提供一个hack方式，拿第一个按钮来计算
            noWidthRuleCal(buttonItem, buttonIndex, isVisible);
        }
        // 展示才会进行计算
        if (isVisible) {
            // 多了就会出现更多，使用更多的计算方式
            if (calNum >= maxButtonNum) {
                if (calNum === maxButtonNum) {
                    total = eachCal(total, finalOperateMoreText, globalOpreateMoreIconSize);
                }
                return;
            }
            // children funciton 计算法
            if ([buttonItem, buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children].some(isFunction)) {
                if (isPlainObject(buttonItem.widthRule)) {
                    var _a = buttonItem.widthRule, text = _a.text, additionWidth = _a.additionWidth;
                    var addText = text instanceof Function ? text(record, index) : text;
                    var addWidth = additionWidth instanceof Function
                        ? additionWidth(record, index)
                        : additionWidth;
                    total = eachCal(total, addText, addWidth);
                }
                return;
            }
            // chidren string 直接计算
            if (typeof (buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children) === 'string') {
                total = eachCal(total, buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children, 0);
            }
            calNum++;
        }
    });
    return total;
}
function getFinalRule(props) {
    var contentWidthRule = props.contentWidthRule, render = props.render, finalName = props.finalName, finalOperateMoreText = props.finalOperateMoreText, buttons = props.buttons, splitWidth = props.splitWidth, showButtonNum = props.showButtonNum, // 没有则为空
    calOperateButtonItemWithNoRule = props.calOperateButtonItemWithNoRule;
    return (contentWidthRule ||
        (render instanceof Function || !showButtonNum
            ? null
            : {
                header: {
                    text: function () {
                        return finalName;
                    },
                },
                cell: {
                    additionWidth: function (val, index, record) {
                        var buttonAdditionWidth = 0;
                        var totalSplitNum = calVisibleButtonTotal({
                            buttons: buttons,
                            record: record,
                            index: index,
                            maxButtonNum: showButtonNum,
                            finalOperateMoreText: finalOperateMoreText,
                            splitWidth: splitWidth,
                        }, function (prev, renderText, additionWidth) {
                            buttonAdditionWidth += parseInt(additionWidth) || 0;
                            return (parseInt(prev) || 0) + (renderText ? 1 : 0);
                        }, 
                        // 无计算规则处理方式
                        function (buttonItem, buttonIndex, isVisible) {
                            var width = calOperateButtonItemWithNoRule({
                                render: isFunction(buttonItem)
                                    ? buttonItem
                                    : buttonItem === null || buttonItem === void 0 ? void 0 : buttonItem.children,
                                record: record,
                                index: index,
                                buttonIndex: buttonIndex,
                                splitWidth: splitWidth,
                            });
                            if (isNumber(width) && isVisible) {
                                buttonAdditionWidth += width;
                            }
                        }) - 1;
                        return (totalSplitNum *
                            (parseInt(splitWidth) || 0) +
                            buttonAdditionWidth);
                    },
                    text: function (val, index, record) {
                        var finalText = calVisibleButtonTotal({
                            buttons: buttons,
                            record: record,
                            index: index,
                            maxButtonNum: showButtonNum,
                            finalOperateMoreText: finalOperateMoreText,
                            splitWidth: splitWidth,
                        }, function (prev, renderText) {
                            return "".concat(typeof prev === 'string' ? prev : '').concat(typeof renderText === 'string' ? renderText : '');
                        });
                        return finalText;
                    },
                },
            }));
}
