import { __assign } from "tslib";
import { formilyReact } from '@/form/formily';
var useField = formilyReact.useField, useFieldSchema = formilyReact.useFieldSchema;
export var useTabs = function () {
    var tabs = [];
    var fieldSchema = useFieldSchema();
    var tabsField = useField();
    fieldSchema.mapProperties(function (schema, name) {
        var _a, _b;
        var field = tabsField.query(tabsField.address.concat(name)).take();
        if ((field === null || field === void 0 ? void 0 : field.display) === 'none' || (field === null || field === void 0 ? void 0 : field.display) === 'hidden') {
            return;
        }
        if ((_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.includes('CnFormTabItem')) {
            tabs.push({
                name: name,
                schema: schema,
                props: __assign({ key: ((_b = schema === null || schema === void 0 ? void 0 : schema['x-component-props']) === null || _b === void 0 ? void 0 : _b.key) || name }, schema === null || schema === void 0 ? void 0 : schema['x-component-props']),
            });
        }
    });
    return tabs;
};
