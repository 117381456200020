import classnames from 'classnames';
// 定义 stylegen 函数，接收 TableProps 类型的参数
export var stylegen = function (props) {
    // 从 props 中解构 hasBorder 和 style
    var _a = props.hasBorder, hasBorder = _a === void 0 ? true : _a, style = props.style;
    // 返回一个新的样式对象，包含原样式和根据 hasBorder 设置的边框样式
    return Object.assign({}, style, {
        '--cell-border-vertical': hasBorder ? '1px solid var(--border-color)' : 0,
        '--header-cell-border-vertical': hasBorder
            ? 'var(--header-cell-border)'
            : 0,
    });
};
// 定义 classNamegen 函数，接收 TableProps 类型的参数
export var classNamegen = function (props) {
    // 从 props 中解构 className 和 size，默认 size 为 'small'
    var className = props.className, size = props.size;
    // 使用 classnames 函数生成类名字符串，根据 size 设置相应的类名
    return classnames(CN_UI_HASH_CLASS_NAME, className, {
        'cn-table-small': size === 'small',
        'cn-table-medium': size === 'medium',
        'cn-table-large': size === 'large',
    });
};
