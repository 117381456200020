import { __assign, __rest } from "tslib";
import { useRef, useState, useEffect } from 'react';
import { formilyShared } from '@/form/formily';
var isArr = formilyShared.isArr, isValid = formilyShared.isValid;
var calcBreakpointIndex = function (breakpoints, width) {
    for (var i = 0; i < breakpoints.length; i++) {
        if (width <= breakpoints[i]) {
            return i;
        }
    }
    return 0;
};
var calcFactor = function (value, breakpointIndex) {
    var _a;
    if (Array.isArray(value)) {
        if (breakpointIndex === -1)
            return value[0];
        return (_a = value[breakpointIndex]) !== null && _a !== void 0 ? _a : value[value.length - 1];
    }
    else {
        return value;
    }
};
var factor = function (value, breakpointIndex) {
    return (isValid(value) ? calcFactor(value, breakpointIndex) : value);
};
var calculateProps = function (target, props) {
    var clientWidth = target.clientWidth;
    var _a = props.breakpoints, breakpoints = _a === void 0 ? [0] : _a, layout = props.layout, labelAlign = props.labelAlign, wrapperAlign = props.wrapperAlign, labelCol = props.labelCol, wrapperCol = props.wrapperCol, otherProps = __rest(props, ["breakpoints", "layout", "labelAlign", "wrapperAlign", "labelCol", "wrapperCol"]);
    var breakpointIndex = calcBreakpointIndex(breakpoints, clientWidth);
    return __assign({ layout: factor(layout, breakpointIndex), labelAlign: factor(labelAlign, breakpointIndex), wrapperAlign: factor(wrapperAlign, breakpointIndex), labelCol: factor(labelCol, breakpointIndex), wrapperCol: factor(wrapperCol, breakpointIndex) }, otherProps);
};
export var useResponsiveFormLayout = function (props) {
    var ref = useRef(null);
    var breakpoints = props.breakpoints;
    if (!isArr(breakpoints)) {
        return { ref: ref, props: props };
    }
    var _a = useState(props), layoutProps = _a[0], setLayout = _a[1];
    var updateUI = function () {
        if (ref.current) {
            setLayout(calculateProps(ref.current, props));
        }
    };
    useEffect(function () {
        var observer = function () {
            updateUI();
        };
        var resizeObserver = new ResizeObserver(observer);
        if (ref.current) {
            resizeObserver.observe(ref.current);
        }
        updateUI();
        return function () {
            resizeObserver.disconnect();
        };
    }, []);
    return {
        ref: ref,
        props: layoutProps,
    };
};
