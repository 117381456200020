import { __assign } from "tslib";
import { mapStatus } from '@/form/__builtins__';
import { Checkbox as UiCheckbox, Input as UiInput, NumberPicker as UiNumberPicker, Select as UiSelect, TimePicker2 as UiTimePicker2, DatePicker2 as UiDatePicker2, Radio as UiRadio, Transfer as UiTransfer, Range as UiRange, } from '@fusion/lib';
import { formilyCore, formilyReact } from '@/form/formily';
var connect = formilyReact.connect, mapProps = formilyReact.mapProps;
var UiCheckboxGroup = UiCheckbox.Group;
export var Checkbox = connect(UiCheckbox, mapProps({
    value: 'checked',
    onInput: 'onChange',
}));
export var CheckboxGroup = connect(UiCheckboxGroup, mapProps({
    dataSource: true,
}));
var patchDataSource = function (dataSource) {
    if (dataSource === void 0) { dataSource = []; }
    var removeEmptyChildren = function (data) {
        var result = __assign({}, data);
        if (!result.children || result.children.length === 0) {
            delete result.children;
        }
        else {
            result.children = result.children.map(removeEmptyChildren);
        }
        return result;
    };
    return dataSource.map(removeEmptyChildren);
};
export var Select = connect(UiSelect, mapProps(function (props, field) {
    var _a;
    if (formilyCore.isVoidField(field)) {
        return props;
    }
    return __assign(__assign({}, props), { dataSource: patchDataSource((_a = props.dataSource) !== null && _a !== void 0 ? _a : field === null || field === void 0 ? void 0 : field.dataSource) });
}, mapStatus));
var UiTextArea = UiInput.TextArea;
export var Input = connect(UiInput, mapProps(mapStatus));
export var TextArea = connect(UiTextArea, mapProps(mapStatus));
export var NumberPicker = connect(UiNumberPicker, mapProps(mapStatus));
var UiRadioGroup = UiRadio.Group;
export var Radio = connect(UiRadio, mapProps({
    value: 'checked',
    onInput: 'onChange',
}));
export var RadioGroup = connect(UiRadioGroup, mapProps({
    dataSource: true,
}));
export var TimePicker2 = connect(UiTimePicker2, mapProps(mapStatus));
export var RangeTimePicker = connect(UiTimePicker2.RangePicker, mapProps(mapStatus));
export var DatePicker2 = connect(UiDatePicker2, mapProps(mapStatus));
export var RangeDatePicker = connect(UiDatePicker2.RangePicker, mapProps(mapStatus));
export var Transfer = connect(UiTransfer, mapProps(mapStatus, {
    dataSource: true,
}));
export var Range = connect(UiRange, mapProps(mapStatus));
export { Switch } from '@/form/switch';
export { FormItem } from '@/form/form-item';
export { Submit } from '@/form/submit';
export { Reset } from '@/form/reset';
