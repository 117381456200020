import './index.scss';
import * as React from 'react';
import classNames from 'classnames';
import { CnFormat } from '@cainiaofe/cn-ui-common';
CnFormat.registerFormat('CnInputTextArea', function (value) {
    if (!value) {
        return null;
    }
    return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-format-cn-input-text-area') }, value.split('\n').map(function (data) {
        return React.createElement("p", null, data);
    })));
});
