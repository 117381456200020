import { formilyReact } from '@/form/formily';
import { CnComplexCheckbox as UiCnComplexCheckbox, CnComplexCheckboxGroup as UiCnComplexCheckboxGroup, } from '@/components/cn-complex-checkbox';
import { mapStatus } from '@/form/__builtins__';
export var CnComplexCheckbox = formilyReact.connect(UiCnComplexCheckbox, formilyReact.mapProps({
    value: 'checked',
    onInput: 'onChange',
}));
export var CnComplexCheckboxGroup = formilyReact.connect(UiCnComplexCheckboxGroup, formilyReact.mapProps(mapStatus, {
    dataSource: true,
}));
