import { __assign } from "tslib";
import * as React from 'react';
import { Text, createText } from './view/text';
import { processTitleProps } from './utils/process-title-props';
export var CnTypography = {
    Caption: createText('Caption', 'cn-ui-typography-caption'),
    BodyText1: createText('BodyText1', 'cn-ui-typography-body-text-1'),
    BodyText2: createText('BodyText2', 'cn-ui-typography-body-text-2'),
    H1: function (props) {
        var _a = processTitleProps('h1', props), children = _a.children, restProps = _a.restProps;
        return React.createElement(Text, __assign({}, restProps), children);
    },
    H2: function (props) {
        var _a = processTitleProps('h2', props), children = _a.children, restProps = _a.restProps;
        return React.createElement(Text, __assign({}, restProps), children);
    },
    H3: function (props) {
        var _a = processTitleProps('h3', props), children = _a.children, restProps = _a.restProps;
        return React.createElement(Text, __assign({}, restProps), children);
    },
    H4: function (props) {
        var _a = processTitleProps('h4', props), children = _a.children, restProps = _a.restProps;
        return React.createElement(Text, __assign({}, restProps), children);
    },
    H5: function (props) {
        var _a = processTitleProps('h5', props), children = _a.children, restProps = _a.restProps;
        return React.createElement(Text, __assign({}, restProps), children);
    },
    H6: function (props) {
        var _a = processTitleProps('h6', props), children = _a.children, restProps = _a.restProps;
        return React.createElement(Text, __assign({}, restProps), children);
    },
};
