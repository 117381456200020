import { __assign, __rest } from "tslib";
import * as React from 'react';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { formilyReact } from '@/form/formily';
export function CnReadOnlyContainer(props) {
    var _a;
    var value = props.value, size = props.size, className = props.className, children = props.children, line = props.line, showFolder = props.showFolder, cnTextEllipsisProps = props.cnTextEllipsisProps, 
    // 自定义渲染
    readOnlyRender = props.readOnlyRender, 
    // 其他展示属性
    addonBefore = props.addonBefore, innerBefore = props.innerBefore, addonTextBefore = props.addonTextBefore, addonTextAfter = props.addonTextAfter, innerAfter = props.innerAfter, addonAfter = props.addonAfter, otherDivProps = __rest(props, ["value", "size", "className", "children", "line", "showFolder", "cnTextEllipsisProps", "readOnlyRender", "addonBefore", "innerBefore", "addonTextBefore", "addonTextAfter", "innerAfter", "addonAfter"]);
    var classes = classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-read-only', className, (_a = {},
        _a["cn-ui-read-only-size-".concat(size)] = size,
        _a));
    var field = formilyReact.useField();
    var handleClick = function (e) {
        if (typeof props.onClick === 'function') {
            props.onClick(e, field);
        }
    };
    if (readOnlyRender && isFunction(readOnlyRender)) {
        return (React.createElement("div", __assign({ className: classes }, otherDivProps), readOnlyRender(value)));
    }
    return (React.createElement("div", __assign({ className: classes }, otherDivProps, { onClick: handleClick }),
        addonBefore || '',
        innerBefore || '',
        addonTextBefore || '',
        React.createElement(CnTextEllipsis, __assign({ line: line, hasTooltip: true, showFolder: showFolder }, cnTextEllipsisProps), children),
        addonTextAfter || '',
        innerAfter || '',
        addonAfter || ''));
}
