import { __assign, __rest } from "tslib";
import * as React from 'react';
import cx from 'classnames';
import { formilyReact } from '@/form/formily';
import { CnAnchor } from '@/components/cn-anchor';
import { CnGrid } from '@/components/cn-grid';
import { CnForm } from '@/form/cn-form';
import './index.scss';
var CnRow = CnGrid.CnRow, CnCol = CnGrid.CnCol;
export var CnAnchorForm = formilyReact.connect(function (props) {
    var className = props.className, rightFixedSpan = props.rightFixedSpan, anchorProps = props.anchorProps, otherProps = __rest(props, ["className", "rightFixedSpan", "anchorProps"]);
    var anchorFormRef = React.useRef();
    var _a = React.useState({}), anchorFormMountedStyle = _a[0], setAnchorFormMountedStyle = _a[1];
    React.useEffect(function () {
        if (!anchorFormRef.current)
            return;
        var _a = anchorFormRef.current.getBoundingClientRect(), _b = _a.left, left = _b === void 0 ? 0 : _b, _c = _a.width, width = _c === void 0 ? 0 : _c, _d = _a.top, top = _d === void 0 ? 0 : _d;
        setAnchorFormMountedStyle({
            top: top,
            left: left + width,
            right: 'initial',
        });
    }, []);
    return (React.createElement("div", { className: cx('cn-ui-anchor-form', className), ref: anchorFormRef },
        React.createElement(CnRow, null,
            React.createElement(CnCol, null,
                React.createElement(CnForm, __assign({}, otherProps))),
            React.createElement(CnCol, { fixedSpan: rightFixedSpan })),
        React.createElement(CnAnchor, __assign({ style: anchorFormMountedStyle }, anchorProps))));
});
CnAnchorForm.defaultProps = {
    rightFixedSpan: 10,
};
