import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnBox } from '@/components/cn-box';
import { useUploadState, CnUploader } from '@cainiaofe/cn-ui-common';
import { MiniUploadList } from './upload-list/mini-file-list';
import { UploadButton } from './upload-button';
export var OSSUploadMini = React.forwardRef(function (props, ref) {
    var _a = useUploadState(), rootProps = _a.props, limited = _a.limited, readOnly = _a.readOnly;
    var description = props.description, buttonProps = props.buttonProps, buttonText = props.buttonText, restProps = __rest(props, ["description", "buttonProps", "buttonText"]);
    var style = rootProps.style, className = rootProps.className, disabled = rootProps.disabled;
    return (React.createElement("div", { "data-name": "CnOSSUpload", style: style, className: classNames(CN_UI_HASH_CLASS_NAME, className, 'cn-ui-oss-upload', 'cn-ui-oss-upload-mini', {
            'cn-ui-oss-upload-readonly': readOnly,
            'cn-ui-oss-upload-disabled': disabled || limited,
        }) },
        readOnly ? null : (React.createElement(CnBox, { direction: "row", align: "center", className: "cn-ui-oss-upload-core" },
            React.createElement(CnUploader, { ref: ref, className: "cn-ui-oss-upload-action" }, description ? (React.createElement(CnTooltip, { trigger: React.createElement(UploadButton, { buttonProps: buttonProps, buttonText: buttonText }) }, description)) : (React.createElement(UploadButton, { buttonProps: buttonProps, buttonText: buttonText }))))),
        React.createElement(MiniUploadList, __assign({}, restProps))));
});
