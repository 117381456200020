import { formilyReact } from '@/form/formily';
import { CnDatePicker2 as UiCnDatePicker2, CnMonthPicker2 as UiCnMonthPicker2, CnYearPicker2 as UiCnYearPicker2, CnWeekPicker2 as UiCnWeekPicker2, CnQuarterPicker2 as UiCnQuarterPicker2, CnRangeDatePicker2 as UiCnRangeDatePicker2, CnDatePickerPro as UiCnDatePickerPro, CnMonthPickerPro as UiCnMonthPickerPro, CnYearPickerPro as UiCnYearPickerPro, CnWeekPickerPro as UiCnWeekPickerPro, CnQuarterPickerPro as UiCnQuarterPickerPro, CnRangeDatePickerPro as UiCnRangeDatePickerPro, } from '@/components/cn-date-picker-pro';
import { mapStatus } from '@/form/__builtins__';
/**
 * @deprecated 请使用 CnDatePickerPro
 */
export var CnDatePicker2 = formilyReact.connect(UiCnDatePicker2, formilyReact.mapProps(mapStatus));
/**
 * @deprecated 请使用 CnMonthPickerPro
 */
export var CnMonthPicker2 = formilyReact.connect(UiCnMonthPicker2, formilyReact.mapProps(mapStatus));
/**
 * @deprecated 请使用 CnYearPickerPro
 */
export var CnYearPicker2 = formilyReact.connect(UiCnYearPicker2, formilyReact.mapProps(mapStatus));
/**
 * @deprecated 请使用 CnWeekPickerPro
 */
export var CnWeekPicker2 = formilyReact.connect(UiCnWeekPicker2, formilyReact.mapProps(mapStatus));
/**
 * @deprecated 请使用 CnQuarterPickerPro
 */
export var CnQuarterPicker2 = formilyReact.connect(UiCnQuarterPicker2, formilyReact.mapProps(mapStatus));
/**
 * @deprecated 请使用 CnRangeDatePickerPro
 */
export var CnRangeDatePicker2 = formilyReact.connect(UiCnRangeDatePicker2, formilyReact.mapProps(mapStatus));
export var CnDatePickerPro = formilyReact.connect(UiCnDatePickerPro, formilyReact.mapProps(mapStatus));
export var CnMonthPickerPro = formilyReact.connect(UiCnMonthPickerPro, formilyReact.mapProps(mapStatus));
export var CnYearPickerPro = formilyReact.connect(UiCnYearPickerPro, formilyReact.mapProps(mapStatus));
export var CnWeekPickerPro = formilyReact.connect(UiCnWeekPickerPro, formilyReact.mapProps(mapStatus));
export var CnQuarterPickerPro = formilyReact.connect(UiCnQuarterPickerPro, formilyReact.mapProps(mapStatus));
export var CnRangeDatePickerPro = formilyReact.connect(UiCnRangeDatePickerPro, formilyReact.mapProps(mapStatus));
