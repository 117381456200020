import '../../index.scss';
import * as React from 'react';
import { CnButton, CnSplitButtonGroup } from '@/components/cn-button';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnIcon } from '@/components/cn-icon';
import cx from 'classnames';
import { isInvisible } from '../../utils';
import { OptBtns } from '../opt-btns';
import { ConfigButton } from '../save-config';
import { useFilterRefsContext, useFilterPropsContext } from '../../context';
import { formilyReactiveReact } from '@/form/formily';
export var FilterItemBtns = formilyReactiveReact.observer(function (props) {
    var _a = useFilterPropsContext(), $i18n = _a.$i18n, resetButtonUseIcon = _a.resetButtonUseIcon, size = _a.size, buttonType = _a.buttonType, expandButtonUseIcon = _a.expandButtonUseIcon, enableConfig = _a.enableConfig, saveConfigState = _a.saveConfigState, saveConfigDispatch = _a.saveConfigDispatch, extendButtonsPosition = _a.extendButtonsPosition, extendButtons = _a.extendButtons, enableExpand = _a.enableExpand, showAll = _a.showAll, expand = _a.expand, hideChildren = _a.hideChildren, handleToggleMore = _a.handleToggleMore, filterDisplay = _a.filterDisplay;
    var filterContext = useFilterRefsContext();
    var hideButton = props.hideButton;
    var btns = [];
    btns.push({
        icon: 'refresh',
        onClick: function () {
            filterContext.formInstance.filterReset &&
                filterContext.formInstance.filterReset();
        },
        title: $i18n.get({ id: 'reset', dm: '重置' }),
        useIcon: resetButtonUseIcon,
    });
    // hideChildren 中存在需要展示的话，显示展开按钮
    var showButton = enableExpand &&
        (showAll ||
            (hideChildren &&
                hideChildren.filter(function (child) {
                    var _a, _b;
                    var item = (_b = (_a = filterContext.formInstance) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b.call(_a, child.key).take();
                    return !(isInvisible(child) ||
                        (item === null || item === void 0 ? void 0 : item.display) === 'none' ||
                        (item === null || item === void 0 ? void 0 : item.display) === 'hidden');
                }).length > 0));
    var renderButtons = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement(CnButton, { size: size, type: "primary", htmlType: "submit", onClick: function (e) {
                    filterContext.formInstance.filterSubmit &&
                        filterContext.formInstance.filterSubmit(e);
                }, className: "cn-ui-filter-search-btn cn-ui-filter-btn-".concat(buttonType || size) }, $i18n.get({
                id: 'search',
                dm: '查询',
                ns: 'CnFilter',
            })),
            React.createElement(OptBtns, { maxShowNum: 2, dataSource: btns, size: size })));
    };
    var renderExpandButton = function () {
        var expandButton = (React.createElement(CnTooltip, { align: "t", delay: 300, trigger: React.createElement(CnButton, { size: size, onClick: handleToggleMore, className: cx('cn-ui-filter-icon-btn', "cn-ui-filter-icon-btn-".concat(size)) },
                React.createElement("span", null,
                    React.createElement(CnIcon, { size: size, type: expand ? 'arrow-up' : 'arrow-down' }))) }, expand
            ? $i18n.get({ id: 'hide', dm: '更多' })
            : $i18n.get({ id: 'more', dm: '更多' })));
        if (!expandButtonUseIcon) {
            expandButton = (React.createElement(CnButton, { size: size, onClick: handleToggleMore, className: cx('cn-ui-filter-expand-btn-with-text') },
                React.createElement("span", { className: "cn-ui-filter-expand-btn-with-text-content" },
                    React.createElement(CnIcon, { size: "medium", className: "cn-ui-filter-expand-btn-with-text-icon", type: expand ? 'arrow-up' : 'arrow-down' }),
                    expand
                        ? $i18n.get({ id: 'hide', dm: '更多' })
                        : $i18n.get({ id: 'more', dm: '更多' }))));
        }
        // 流式布局不支持设置（交互确认）
        var configButton = filterDisplay === 'grid' && !hideButton ? (React.createElement(ConfigButton, { enableConfig: enableConfig, store: saveConfigState, dispatch: saveConfigDispatch, size: size })) : null;
        if (showButton && enableConfig) {
            return (React.createElement(CnSplitButtonGroup, { className: "cn-ui-filter-btn-group", size: size },
                expandButton,
                configButton));
        }
        if (showButton) {
            return expandButton;
        }
        if (enableConfig) {
            return configButton;
        }
        return null;
    };
    var renderFilterItemBtns = function () {
        if (!hideButton) {
            return (React.createElement("div", { className: "filter-item-btns" },
                React.createElement("div", { className: cx('cn-ui-filter-btns', {
                        'cn-ui-filter-btns-flex': filterDisplay === 'flex',
                    }) },
                    extendButtonsPosition === 'left' ? extendButtons : undefined,
                    renderButtons(),
                    extendButtonsPosition === 'right' ? extendButtons : undefined,
                    renderExpandButton(),
                    extendButtonsPosition === 'end' ? extendButtons : undefined)));
        }
        if (showButton) {
            return (React.createElement("div", { className: "filter-item-btns" },
                React.createElement("div", { className: cx('cn-ui-filter-btns', {
                        'cn-ui-filter-btns-flex': filterDisplay === 'flex',
                    }) }, renderExpandButton())));
        }
        return null;
    };
    return renderFilterItemBtns();
});
