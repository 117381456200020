import { __assign } from "tslib";
import $i18n, { withI18n } from 'panda-i18n';
import * as React from 'react';
import locale from '@/locales';
import { Input } from '@/components/fusion';
import { HocBaseComponents, Plugin, pluginManager, useNextLocale, } from '@/components/cn-utils';
import { nextCompMap } from './next-comp-map';
export var CnInputBase = nextCompMap(React.forwardRef(function (props, ref) {
    var placeholder = props.placeholder ||
        $i18n.get({ id: 'TERM.Enter', dm: '请输入', ns: 'CnInput' });
    var inputLocale = useNextLocale('Input');
    return props.htmlType === 'password' ? (React.createElement(Input.Password, __assign({}, props, { placeholder: placeholder, ref: ref, locale: inputLocale }))) : (React.createElement(Input, __assign({}, props, { placeholder: placeholder, ref: ref, locale: inputLocale })));
}), 'CnInput');
var CnInputView = React.forwardRef(function (props, ref) {
    var plugin = React.useMemo(function () {
        var _plugin = new Plugin('CnInput', pluginManager);
        _plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        _plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnInput')
            .map(function (item) { return item.method; }));
        return _plugin;
    }, []);
    var plugins = plugin.getPlugin();
    if (plugins.length === 0) {
        return React.createElement(CnInputBase, __assign({}, props, { ref: ref }));
    }
    return HocBaseComponents(CnInputBase, { props: props, plugins: plugins, ref: ref });
});
export var CnInput = withI18n(CnInputView, {
    locale: locale,
    componentName: 'CnInput',
});
