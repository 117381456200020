// 根据displayName获取mode
export function getModeByDisplayName(displayName) {
    var res = 'date';
    switch (displayName) {
        case 'CnWeekPicker2':
            res = 'week';
            break;
        case 'CnMonthPicker2':
            res = 'month';
            break;
        case 'CnQuarterPicker2':
            res = 'quarter';
            break;
        case 'CnYearPicker2':
            res = 'year';
            break;
        default:
            break;
    }
    return res;
}
