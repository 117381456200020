/** i18n-disable */
import classNames from 'classnames';
import { stateKey as multiSelectStateKey } from '../multi-select-pipeline';
export function rowPropsPipeline(rowProps) {
    // 返回一个处理函数
    return function (pipeline) {
        var _a;
        if (rowProps) {
            pipeline.appendRowPropsGetter(rowProps);
        }
        var selected = (_a = pipeline.getStateAtKey(multiSelectStateKey)) === null || _a === void 0 ? void 0 : _a.lastKey;
        if (Array.isArray(selected)) {
            var primaryKey_1 = pipeline.ensurePrimaryKey('行选择的样式展示');
            pipeline.appendRowPropsGetter(function (record) {
                return {
                    className: classNames({
                        'cn-table-row-selected': selected.includes(record[primaryKey_1]),
                    }),
                };
            });
        }
        return pipeline;
    };
}
