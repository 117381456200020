import { __assign } from "tslib";
/**
 * 只处理 post 请求参数
 */
export default function processPostData(options) {
    var contentType = options.contentType, method = options.method, runParams = options.runParams, data = options.data, formatParam = options.formatParam;
    if (method !== 'POST')
        return data;
    if (contentType === 'application/x-www-form-urlencoded') {
        var _runParams = runParams;
        if (typeof runParams === 'object' && runParams !== null) {
            var searchParams = new URLSearchParams();
            for (var _i = 0, _a = Object.keys(runParams); _i < _a.length; _i++) {
                var key = _a[_i];
                if (runParams[key] !== null) {
                    searchParams.append(key, runParams[key]);
                }
            }
            _runParams = searchParams.toString();
        }
        var _data = data;
        if (typeof data === 'object' && data !== null) {
            var searchParams = new URLSearchParams();
            for (var _b = 0, _c = Object.keys(data); _b < _c.length; _b++) {
                var key = _c[_b];
                searchParams.append(key, data[key]);
            }
            _data = searchParams.toString();
        }
        var result = [_runParams, _data].filter(Boolean).join('&');
        if (typeof formatParam === 'function') {
            return formatParam(result);
        }
        return result;
    }
    if (typeof formatParam === 'function') {
        return formatParam(__assign(__assign({}, runParams), data));
    }
    return __assign(__assign({}, runParams), data);
}
