import { __assign } from "tslib";
/**
 * 只处理 get 请求的参数
 */
export default function processGetParams(options) {
    var method = options.method, runParams = options.runParams, params = options.params, formatParam = options.formatParam;
    if (method !== 'GET')
        return params; // 如果请求方法不是 GET，则直接返回请求参数
    if (typeof formatParam === 'function') {
        return formatParam(__assign(__assign({}, runParams), params)); // 如果存在格式化参数的函数，则调用该函数并传入运行参数和请求参数
    }
    return __assign(__assign({}, runParams), params); // 否则，将运行参数和请求参数合并后返回
}
