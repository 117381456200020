import { __assign } from "tslib";
import './thumb-bar.scss';
import React, { memo } from 'react';
import { computeRatio, updateScrollPosition } from './utils';
import classNames from 'classnames';
function ThumbBarView(_a) {
    var _b, _c, _d;
    var visible = _a.visible, isGlobal = _a.isGlobal, trackStyle = _a.trackStyle, thumbStyle = _a.thumbStyle, minThumbSize = _a.minThumbSize, start = _a.start, gap = _a.gap, horizontal = _a.horizontal, pin = _a.pin, trackRef = _a.trackRef, boxSize = _a.boxSize, update = _a.update;
    var CW = boxSize.CW, CH = boxSize.CH, PT = boxSize.PT, PR = boxSize.PR, PB = boxSize.PB, PL = boxSize.PL, SW = boxSize.SW, SH = boxSize.SH;
    var _e = horizontal
        ? ['width', CW, SW]
        : ['height', CH, SH], sizeKey = _e[0], offsetSize = _e[1], scrollSize = _e[2];
    function getContainerBox() {
        var _a, _b;
        var targetNode = (_b = (_a = trackRef.current) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.parentNode;
        return targetNode === document.body ? document.documentElement : targetNode;
    }
    function handleThumbBarClick(e) {
        var containerBox = getContainerBox();
        var scrollLeft = containerBox.scrollLeft, scrollTop = containerBox.scrollTop;
        var scrollPosition = horizontal ? scrollLeft : scrollTop;
        var rect = e.target.getBoundingClientRect();
        var clickPosition = horizontal
            ? (e.clientX - rect.left) / rect.width
            : (e.clientY - rect.top) / rect.height;
        var scrollRatio = scrollPosition / scrollSize;
        var position = clickPosition > scrollRatio
            ? Math.min(scrollSize, scrollPosition + offsetSize)
            : Math.max(0, scrollPosition - offsetSize);
        updateScrollPosition(containerBox, position, horizontal);
    }
    function handleStart(e) {
        e.stopPropagation();
        var _a = getContainerBox(), scrollLeft = _a.scrollLeft, scrollTop = _a.scrollTop;
        update({
            pinX: horizontal,
            pinY: !horizontal,
            lastST: scrollTop,
            lastSL: scrollLeft,
            startX: e.clientX,
            startY: e.clientY,
        });
    }
    var style = __assign(__assign({}, (isGlobal
        ? (_b = {}, _b[sizeKey] = gap > 0 ? "calc(100% - ".concat(gap, "px)") : undefined, _b) : __assign((_c = {}, _c[sizeKey] = offsetSize - gap, _c), (horizontal
        ? {
            bottom: -PB,
            left: -PL + start,
        }
        : {
            top: PT - gap + start,
            right: -PR,
            transform: 'translateY(-100%)',
        })))), (trackStyle && trackStyle(horizontal)));
    return (React.createElement("div", { className: classNames('ms-track', "".concat(horizontal ? 'ms-x' : 'ms-y'), {
            'ms-active': pin,
            'ms-track-show': pin && visible,
        }), onClick: handleThumbBarClick, ref: trackRef, style: style },
        React.createElement("div", { className: "ms-thumb", onMouseDown: handleStart, onClick: function (e) { return e.stopPropagation(); }, style: __assign((_d = {}, _d[sizeKey] = computeRatio(scrollSize, offsetSize, gap, minThumbSize)
                .thumbSize, _d), (thumbStyle && thumbStyle(horizontal))) })));
}
export var ThumbBar = memo(ThumbBarView);
