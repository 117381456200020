import { __assign } from "tslib";
import './index.scss';
import * as React from 'react';
import { CnIcon } from '@/components/cn-icon';
import { CnStatusTag } from '@/components/cn-status-tag';
import { CnTextEllipsis } from '@/components/cn-ellipsis';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import classNames from 'classnames';
var renderDescription = function (description) {
    if (React.isValidElement(description)) {
        return description;
    }
    var result = [];
    if (Array.isArray(description)) {
        description.forEach(function (descriptionItem, index) {
            if (index === description.length - 1) {
                result.push(React.createElement("span", { key: index }, descriptionItem));
            }
            else {
                result.push(React.createElement("span", { key: index }, descriptionItem), React.createElement("span", null, "\u00A0|\u00A0"));
            }
        });
    }
    else {
        result.push(React.createElement("span", null, description));
    }
    return result;
};
export var CnDetailInfo = function (props) {
    var propsAvatar = props.avatar, icon = props.icon, title = props.title, description = props.description, status = props.status, tagText = props.tagText, cnTagProps = props.cnTagProps, rightSlot = props.rightSlot;
    var avatar = typeof propsAvatar === 'string' ? { src: propsAvatar } : propsAvatar;
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-detail-info') },
        React.createElement("div", { className: "cn-ui-detail-info-left" },
            (avatar || icon) && (React.createElement("div", { className: "cn-ui-detail-info-left-header" }, avatar ? (React.createElement("div", { className: "cn-ui-detail-info-left-header-avatar" },
                React.createElement("img", { className: "cn-ui-detail-info-left-header-avatar-img", src: avatar === null || avatar === void 0 ? void 0 : avatar.src }))) : (React.createElement(CnIcon, { className: "cn-ui-detail-info-left-header-icon", type: icon, size: 32 })))),
            React.createElement("div", { className: "cn-ui-detail-info-left-footer" },
                React.createElement("div", { className: "cn-ui-detail-info-content" },
                    React.createElement("div", { className: "cn-ui-detail-info-content-header" },
                        React.createElement(CnTextEllipsis, { className: "cn-ui-detail-info-title" }, title),
                        React.createElement("div", { className: "cn-ui-detail-info-tag" }, Array.isArray(tagText) ? (tagText.map(function (text, idx) { return (React.createElement(CnStatusTag, __assign({ key: idx, status: status }, cnTagProps), text)); })) : (React.createElement(CnStatusTag, __assign({ status: status }, cnTagProps), tagText)))),
                    React.createElement(CnTextEllipsis, { className: "cn-ui-detail-info-content-footer" }, renderDescription(description))))),
        rightSlot && React.createElement("div", { className: "cn-ui-detail-info-right" }, rightSlot)));
};
CnDetailInfo.displayName = 'CnDetailInfo';
CnDetailInfo.defaultProps = {
    status: 'info',
};
