import { __assign, __rest } from "tslib";
import './oss-image-upload.scss';
import React from 'react';
import { CnUploadContext, defaultCnUploadRequestConfig, getRequestService, } from '@cainiaofe/cn-ui-common';
import { CnMessage } from '@/components/cn-message';
import $i18n from 'panda-i18n';
import { getErrorMsg } from '@cainiaofe/cn-ui-utils';
import { acceptList } from '../constant/accept-list';
import { UploadView } from './upload-view';
export var OSSImageUpload = React.forwardRef(function (props, ref) {
    var _a = props.requestConfig, requestConfig = _a === void 0 ? defaultCnUploadRequestConfig : _a, onErrorProps = props.onError, restProps = __rest(props, ["requestConfig", "onError"]);
    /**
     * 组件内部主动通过 CnMessage.error 暴露错误内容
     */
    var onError = function (file) {
        var error = file.error;
        if (!error)
            return;
        var result = onErrorProps === null || onErrorProps === void 0 ? void 0 : onErrorProps(error, file);
        if (result === false)
            return;
        var msg = getErrorMsg(error, file.errorMsg ||
            $i18n.get({
                id: 'OSSCredentialAcquisitionFailed',
                dm: 'OSS凭证获取失败',
                ns: 'CnOssUpload',
            }));
        CnMessage.error({ content: msg });
    };
    return (React.createElement(CnUploadContext, __assign({}, restProps, { accept: acceptList.join(', '), onError: onError, fetchOSSToken: function (params) {
            var _a;
            return (_a = getRequestService(requestConfig)) === null || _a === void 0 ? void 0 : _a(params);
        } }),
        React.createElement(UploadView, __assign({ ref: ref }, restProps))));
});
OSSImageUpload.defaultProps = {
    headers: {},
    multiple: false,
    readOnly: false,
    showPreview: true,
    showRemove: true,
    openFileDialogOnClick: true,
};
OSSImageUpload.displayName = 'CnOSSImageUpload';
