import { __rest } from "tslib";
import React from 'react';
import { formilyReact } from '@/form/formily';
import { CnFormArrayBaseItemContext } from '../context';
var RecordScope = formilyReact.RecordScope;
var takeRecord = function (val) { return (typeof val === 'function' ? val() : val); };
export var CnFormArrayBaseItem = function (props) {
    var children = props.children, restProps = __rest(props, ["children"]);
    return (React.createElement(CnFormArrayBaseItemContext.Provider, { value: restProps },
        React.createElement(RecordScope, { getIndex: function () { return restProps.index; }, getRecord: function () { return takeRecord(restProps.record); } }, children)));
};
