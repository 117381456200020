import { __assign } from "tslib";
import React from 'react';
import { useIndex } from '../hooks';
import { cssPrefix } from '../constant/css-prefix';
export var ArrayBaseIndex = function (props) {
    var index = useIndex();
    return (React.createElement("span", __assign({}, props, { className: "".concat(cssPrefix, "-index") }),
        "#",
        index + 1,
        "."));
};
