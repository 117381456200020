import { __awaiter, __generator } from "tslib";
// i18n-disable
import React from 'react';
import { downloadFile } from '@cainiaofe/cn-ui-utils';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { calcSize, canUseImageViewer, getFileName } from './utils';
import { StaticFileIcon } from './icons';
import { CnIcon } from '@/components/cn-icon';
import { CnLoading } from '@/components/cn-loading';
import { CnProgress } from '@/components/cn-progress';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnImageViewer } from '@/components/cn-image-viewer';
import { useShowPreview } from './hooks/useShowPreview';
import { useShowDownload } from './hooks/useShowDownload';
import { useShowRemove } from './hooks/useShowRemove';
import { preview } from './preview';
import { CnBox } from '@/components/cn-box';
export var UploadListItem = function (props) {
    var style = props.style, readOnly = props.readOnly, file = props.file, onRemove = props.onRemove, webOfficeEnable = props.webOfficeEnable, webOfficeEditRequestConfig = props.webOfficeEditRequestConfig, webOfficePreviewRequestConfig = props.webOfficePreviewRequestConfig;
    var status = file.status, size = file.size, key = file.key;
    var renderFileFooter = function () {
        if (status === 'uploading')
            return React.createElement(CnProgress, { percent: file.percent });
        if (size)
            return calcSize(size);
        return null;
    };
    var isUploading = ['idle', 'uploading'].includes(status);
    var showRemove = useShowRemove({ showRemove: props.showRemove, file: file });
    var showDownload = useShowDownload({
        showDownload: props.showDownload,
        onDownload: props.onDownload,
        file: file,
    });
    var onDownload = function () {
        if (props.onDownload)
            props.onDownload(file);
        else
            downloadFile(file.url, file.name);
    };
    var showPreview = useShowPreview({
        showPreview: props.showPreview,
        onPreview: props.onPreview,
        file: file,
    });
    var defaultOnPreview = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (props.onPreview)
                props.onPreview(file);
            else if (canUseImageViewer(file))
                CnImageViewer.open({ src: file.url });
            else
                window.open(file.url);
            return [2 /*return*/];
        });
    }); };
    var onPreview = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!showPreview)
                        return [2 /*return*/];
                    if (!webOfficeEnable) return [3 /*break*/, 5];
                    /** 内置支持图片预览，无需调用 web office 预览能力 */
                    if (canUseImageViewer(file))
                        CnImageViewer.open({ src: file.url });
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, preview({
                            requestConfig: readOnly
                                ? webOfficePreviewRequestConfig
                                : webOfficeEditRequestConfig,
                            key: key,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    defaultOnPreview();
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
                case 5:
                    defaultOnPreview();
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { style: style, className: "cn-ui-upload-list-item", onClick: onPreview },
        React.createElement("div", { className: "cn-ui-upload-list-item-header" }, isUploading ? (React.createElement(CnLoading, { className: "cn-ui-upload-list-loading", size: "small" })) : (React.createElement(StaticFileIcon, { file: file }))),
        React.createElement("div", { className: "cn-ui-upload-list-item-info" },
            React.createElement(CnEllipsis, { className: "cn-ui-upload-list-item-info-file-name", ellipsisPosition: "middle", endCharCount: 4 }, getFileName(file)),
            React.createElement("div", { className: "cn-ui-upload-list-item-info-footer" }, renderFileFooter())),
        React.createElement(CnBox, { direction: "row", spacing: 4, align: "center", className: "cn-ui-upload-list-item-actions" },
            showPreview ? (React.createElement("div", { onClick: function (event) {
                    event.stopPropagation();
                    onPreview();
                }, className: "cn-ui-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "eye", size: 14 }) }, webOfficeEnable
                    ? '点击最长可预览/编辑30分钟！过期后如需继续操作，请返回页面重新点击该按钮！'
                    : '预览'))) : null,
            showDownload ? (React.createElement("div", { onClick: function (event) {
                    event.stopPropagation();
                    onDownload();
                }, className: "cn-ui-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "download", size: 14 }) }, "\u4E0B\u8F7D"))) : null,
            showRemove ? (React.createElement("div", { onClick: function (event) {
                    event.stopPropagation();
                    onRemove === null || onRemove === void 0 ? void 0 : onRemove(file);
                }, className: "cn-ui-upload-list-item-actions-item" },
                React.createElement(CnTooltip, { align: "t", trigger: React.createElement(CnIcon, { type: "close", size: 14 }) }, "\u5220\u9664"))) : null)));
};
UploadListItem.defaultProps = {
    showPreview: true,
    showRemove: true,
    showDownload: true,
};
