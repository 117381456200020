import { __assign, __awaiter, __generator } from "tslib";
import $i18n from 'panda-i18n';
import React, { useCallback, useMemo, useState } from 'react';
import useLatest from 'ahooks/lib/useLatest';
import classNames from 'classnames';
import { events, friendlyTips } from '@/components/cn-utils';
import { useCnRequest } from '@cainiaofe/cn-ui-common';
import { CnMessage } from '@/components/cn-message';
import { cssPrefix } from './constant';
import ExportButton from './export-button';
import LoopDialog from './loop-dialog';
import { renameQuickDialog } from './rename-quick-dialog';
import { LOOPING_EXPORT_JOB } from '../cn-async-jobs-pro/constant';
import { CnDialog } from '../cn-dialog';
export function CnExport(props) {
    var _this = this;
    var style = props.style, className = props.className, buttonProps = props.buttonProps, buttonText = props.buttonText, _a = props.children, children = _a === void 0 ? null : _a, _b = props.needRename, needRename = _b === void 0 ? false : _b, renameDialogProps = props.renameDialogProps, createService = props.createService, _createRequestConfig = props.createRequestConfig, hideLoopDialog = props.hideLoop, pollingService = props.pollingService, _pollingRequestConfig = props.pollingRequestConfig, pollingInterval = props.pollingInterval, showBgProcessBtn = props.showBgProcessBtn, autoDownload = props.autoDownload, onCreateSuccess = props.onCreateSuccess, onSuccess = props.onSuccess, onError = props.onError;
    var _c = useState(), jobId = _c[0], setJobId = _c[1];
    var _d = useState(false), createJobLoading = _d[0], setCreateJobLoading = _d[1];
    var _e = useState(false), loopDialogVisible = _e[0], setLoopDialogVisible = _e[1];
    var renameDialogPropsRef = useLatest(renameDialogProps);
    var onCreateSuccessRef = useLatest(onCreateSuccess);
    var onErrorRef = useLatest(onError);
    var createRequestConfig = useMemo(function () {
        var main = function () {
            if (_createRequestConfig) {
                return _createRequestConfig;
            }
            if (typeof createService === 'function') {
                return {
                    service: createService,
                };
            }
            return createService;
        };
        return __assign(__assign({}, main()), { manual: true });
    }, [createService, _createRequestConfig]);
    var pollingRequestConfig = useMemo(function () {
        var main = function () {
            if (_pollingRequestConfig) {
                return _pollingRequestConfig;
            }
            if (typeof pollingService === 'function') {
                return {
                    service: pollingService,
                };
            }
            return pollingService;
        };
        return __assign(__assign({}, main()), { manual: true });
    }, [pollingService, _pollingRequestConfig]);
    var getJobId = function (data) {
        if (['number', 'string'].includes(typeof data)) {
            return data;
        }
        try {
            return data.jobId;
        }
        catch (_a) { }
        return undefined;
    };
    var _onCreateSuccess = useCallback(function (data) { return __awaiter(_this, void 0, void 0, function () {
        var _jobId;
        return __generator(this, function (_a) {
            if (['number', 'string'].includes(typeof data)) {
                friendlyTips({
                    component: 'CnExport',
                    message: $i18n.get({
                        id: 'WhenCreatingTheDataStructureReturned_1770598113',
                        dm: '创建导出的任务返回的数据结构必须时对象，为了避免后期出现问题，请尽快调整',
                        ns: 'CnExport',
                    }),
                });
            }
            _jobId = getJobId(data);
            if (!_jobId) {
                throw new Error($i18n.get({
                    id: 'TheReturnedStructureOfTheCreated_1950281892',
                    dm: '创建任务服务返回结构不正确，必须包含jobId',
                    ns: 'CnExport',
                }));
            }
            events.emit(LOOPING_EXPORT_JOB);
            if (!hideLoopDialog) {
                setJobId(_jobId);
                setLoopDialogVisible(true);
            }
            if (onCreateSuccessRef.current) {
                onCreateSuccessRef.current(data);
            }
            else if (hideLoopDialog) {
                CnMessage.notice($i18n.get({
                    id: 'ExportInProgress',
                    dm: '导出进行中',
                    ns: 'CnExport',
                }));
            }
            return [2 /*return*/];
        });
    }); }, [hideLoopDialog, onCreateSuccessRef]);
    var runAsync = useCnRequest(createRequestConfig).runAsync;
    var onClickExportBtn = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var jobName, _a, data, auditUrl, _b, err_1, message;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 11, , 12]);
                    if (!needRename) return [3 /*break*/, 2];
                    return [4 /*yield*/, renameQuickDialog(renameDialogPropsRef.current)];
                case 1:
                    _a = _c.sent();
                    return [3 /*break*/, 3];
                case 2:
                    _a = undefined;
                    _c.label = 3;
                case 3:
                    jobName = _a;
                    setCreateJobLoading(true);
                    return [4 /*yield*/, runAsync({ jobName: jobName })];
                case 4:
                    data = _c.sent();
                    if (!data.needAudit) return [3 /*break*/, 9];
                    _c.label = 5;
                case 5:
                    _c.trys.push([5, 8, , 9]);
                    return [4 /*yield*/, new Promise(function (resolve, reject2) {
                            CnDialog.confirm({
                                title: $i18n.get({
                                    id: 'ExportDataApproval',
                                    dm: '导出数据审批',
                                    ns: 'CnExport',
                                }),
                                content: $i18n.get({
                                    id: 'ExportOfThisDataRequiresApproval_1240034591',
                                    dm: '导出该数据需经过审批，是否提交导出数据审批流程？',
                                    ns: 'CnExport',
                                }),
                                onOk: resolve,
                                okProps: {
                                    children: $i18n.get({
                                        id: '31255239613091840.CNTM',
                                        dm: '提交',
                                        ns: 'CnExport',
                                    }),
                                },
                                onCancel: reject2,
                            });
                        })];
                case 6:
                    _c.sent();
                    return [4 /*yield*/, runAsync({ jobName: jobName, __needAudit: true })];
                case 7:
                    data = _c.sent();
                    auditUrl = data.auditUrl;
                    if (auditUrl)
                        window.open(auditUrl);
                    return [3 /*break*/, 9];
                case 8:
                    _b = _c.sent();
                    return [3 /*break*/, 9];
                case 9: return [4 /*yield*/, _onCreateSuccess(data)];
                case 10:
                    _c.sent();
                    return [3 /*break*/, 12];
                case 11:
                    err_1 = _c.sent();
                    if (onErrorRef.current) {
                        onErrorRef.current(err_1);
                    }
                    else {
                        message = err_1.message ||
                            $i18n.get({
                                id: 'FailedToCreateExportTaskTask_123840374',
                                dm: '创建导出任务任务失败',
                                ns: 'CnExport',
                            });
                        friendlyTips({ component: 'CnExport', message: message });
                    }
                    return [3 /*break*/, 12];
                case 12:
                    setCreateJobLoading(false);
                    return [2 /*return*/];
            }
        });
    }); }, [
        needRename,
        renameDialogPropsRef,
        runAsync,
        _onCreateSuccess,
        onErrorRef,
    ]);
    return (React.createElement("div", { "data-name": "CnExport", className: classNames(CN_UI_HASH_CLASS_NAME, className, "".concat(cssPrefix, "wrapper")), style: style },
        React.createElement(ExportButton, { loading: createJobLoading, onClick: onClickExportBtn, buttonProps: buttonProps, buttonText: buttonText }, children),
        hideLoopDialog ? null : (React.createElement(LoopDialog, { jobId: jobId, setJobId: setJobId, autoDownload: autoDownload, visible: loopDialogVisible, setVisible: setLoopDialogVisible, pollingRequestConfig: pollingRequestConfig, pollingInterval: pollingInterval, showBgProcessBtn: showBgProcessBtn, onSuccess: onSuccess, onError: onError }))));
}
CnExport.displayName = 'CnExport';
