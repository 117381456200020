// 第三方依赖
import { useState } from 'react';
import pick from 'lodash/pick';
import { useControllableValue } from 'ahooks';
// 当前目录的相对依赖
import { useControlColumnsValue } from './use-control-columns-value';
import { useControlValue } from './use-control-value';
import { useUnitValue } from './use-unit-value';
export function useDelegateHub(props) {
    var _a, _b;
    // const [localColumns, setLocalColumns] = React.useState(props.columns);
    // const preColumns = React.useRef();
    var sortCustom = props.sortCustom;
    var _c = useUnitValue(props, {
        defaultValue: [],
        valuePropName: 'dataSource',
    }), dataSource = _c[0], setDataSource = _c[1];
    var _d = useUnitValue(props, {
        defaultValue: 'id',
        valuePropName: 'primaryKey',
    }), primaryKey = _d[0], setPrimaryKey = _d[1];
    var _e = useControlValue((sortCustom || {}), {
        defaultValuePropName: (props === null || props === void 0 ? void 0 : props.defaultSorts) || [],
        valuePropName: 'sorts',
        changePropName: 'onChangeSorts',
    }), sorts = _e[0], setSorts = _e[1];
    var _f = useControlValue(props, {
        defaultValuePropName: false,
        valuePropName: 'loading',
        changePropName: 'loadingChange',
    }), loading = _f[0], setLoading = _f[1];
    // const [size, setSize] = useControlValue<SizeType>(props as unknown as Obj, {
    //   defaultValuePropName: props?.defaultSize ?? 'medium',
    //   valuePropName: 'size',
    //   changePropName: 'toolbar.sizeChange',
    // });
    var _g = useState(), size = _g[0], setSize = _g[1];
    var _h = useControlValue(props, {
        defaultValuePropName: (_a = props === null || props === void 0 ? void 0 : props.defaultIsFullScreen) !== null && _a !== void 0 ? _a : false,
        valuePropName: 'isFullScreen',
        changePropName: 'toolbar.screenChange',
    }), fullScreen = _h[0], setFullScreen = _h[1];
    var _j = useControlValue(props, {
        defaultValuePropName: (_b = props === null || props === void 0 ? void 0 : props.defaultIsZebra) !== null && _b !== void 0 ? _b : false,
        valuePropName: 'isZebra',
        changePropName: 'toolbar.zebraChange',
    }), zebra = _j[0], setZebra = _j[1];
    var _k = useControllableValue(pick(props.rowDetail, ['defaultOpenKeys', 'openKeys', 'onChangeOpenKeys']), {
        defaultValue: [],
        valuePropName: 'openKeys',
        trigger: 'onChangeOpenKeys',
    }), openKeys = _k[0], setOpenKeys = _k[1];
    var _l = useControlColumnsValue(props), columns = _l[0], setColumns = _l[1];
    return {
        dataSource: dataSource,
        setDataSource: setDataSource,
        primaryKey: primaryKey,
        setPrimaryKey: setPrimaryKey,
        sorts: sorts,
        setSorts: setSorts,
        loading: loading,
        setLoading: setLoading,
        size: size,
        setSize: setSize,
        fullScreen: fullScreen,
        setFullScreen: setFullScreen,
        zebra: zebra,
        setZebra: setZebra,
        columns: columns,
        setColumns: setColumns,
        openKeys: openKeys,
        setOpenKeys: setOpenKeys,
    };
}
