import { __assign, __rest } from "tslib";
import $i18n from 'panda-i18n';
import React from 'react';
import classNames from 'classnames';
import { CnBox } from '@/components/cn-box';
import { useUploadState, CnUploader } from '@cainiaofe/cn-ui-common';
import { UploadList } from './upload-list';
import { UploadButton } from './upload-button';
export var OSSUploadNormal = React.forwardRef(function (props, ref) {
    var _a = useUploadState(), rootProps = _a.props, limited = _a.limited, readOnly = _a.readOnly;
    var description = props.description, buttonProps = props.buttonProps, buttonText = props.buttonText, restProps = __rest(props, ["description", "buttonProps", "buttonText"]);
    var style = rootProps.style, className = rootProps.className, disabled = rootProps.disabled, capture = rootProps.capture;
    return (React.createElement("div", { "data-name": "CnOSSUpload", style: style, className: classNames(CN_UI_HASH_CLASS_NAME, className, 'cn-ui-oss-upload', {
            'cn-ui-oss-upload-readonly': readOnly,
            'cn-ui-oss-upload-disabled': disabled || limited,
        }) },
        readOnly ? null : (React.createElement(CnBox, { direction: "row", align: "center", className: "cn-ui-oss-upload-core" },
            React.createElement(CnUploader, { ref: ref, className: "cn-ui-oss-upload-action" },
                React.createElement(UploadButton, { buttonProps: buttonProps, buttonText: buttonText })),
            React.createElement("div", { className: "cn-ui-oss-upload-description" }, description ||
                (capture
                    ? $i18n.get({
                        id: 'TheOnlyAllowPhotoUploadFunctionI_1016983121',
                        dm: '启用了「仅允许拍照上传」功能，请前往钉钉移动客户端拍照上传图片。',
                        ns: 'CnOssUpload',
                    })
                    : null)))),
        React.createElement(UploadList, __assign({}, restProps))));
});
