import { __assign } from "tslib";
import './cn-text-ellipsis.scss';
import * as React from 'react';
import classNames from 'classnames';
import { CnTooltip } from '@/components/cn-tooltip';
import { CnIcon } from '@/components/cn-icon';
export var CnTextEllipsis = function (_a) {
    var children = _a.children, style = _a.style, line = _a.line, outerClassName = _a.outerClassName, className = _a.className, hasTooltip = _a.hasTooltip, showFolder = _a.showFolder, toolTipProps = _a.toolTipProps;
    var textRef = React.useRef(null);
    var _b = React.useState(false), isOverflow = _b[0], setIsOverflow = _b[1];
    var _c = React.useState(false), showAll = _c[0], setShowAll = _c[1];
    React.useEffect(function () {
        var element = textRef.current;
        if (!element)
            return;
        if (element.scrollHeight > element.offsetHeight) {
            setIsOverflow(true);
        }
    }, []);
    var handleToggleFold = function () {
        setShowAll(!showAll);
    };
    var content = (React.createElement("div", { "data-name": "CnEllipsis", className: classNames(CN_UI_HASH_CLASS_NAME, outerClassName, 'cn-ui-text-clip', {
            fold: showFolder && isOverflow,
        }) },
        React.createElement("div", { ref: textRef, className: classNames([
                className,
                {
                    'cn-ui-text-clip-inner': !showAll,
                },
            ]), style: __assign(__assign({}, style), { WebkitLineClamp: line }) }, children),
        showFolder && isOverflow ? (React.createElement("span", { className: "cn-ui-text-clip-fold", onClick: handleToggleFold },
            React.createElement(CnIcon, { type: showAll ? 'triangle-up-fill' : 'triangle-down-fill' }))) : null));
    if (hasTooltip && isOverflow) {
        return (React.createElement(CnTooltip, __assign({ trigger: content }, toolTipProps, { className: classNames('cn-ui-text-ellipsis-tooltip', toolTipProps === null || toolTipProps === void 0 ? void 0 : toolTipProps.className) }), children));
    }
    return content;
};
CnTextEllipsis.defaultProps = {
    line: 2,
    hasTooltip: true,
    showFolder: false,
};
CnTextEllipsis.displayName = 'CnTextEllipsis';
