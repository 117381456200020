import { __assign } from "tslib";
import './main.scss';
import React from 'react';
import { formilyReact, } from '@/form/formily';
import cls from 'classnames';
import { SortableContainer, SortableElement, } from 'react-sortable-hoc';
import { CnFormArrayBase, mixinCnFormArrayBase, } from '@/form/cn-form-array-base';
var useField = formilyReact.useField, observer = formilyReact.observer, useFieldSchema = formilyReact.useFieldSchema, RecursionField = formilyReact.RecursionField;
var prefixCls = 'cn-ui-formily-array-items';
var SortableItem = SortableElement(function (props) {
    return (React.createElement("div", __assign({}, props, { className: cls("".concat(prefixCls, "-item"), props.className) }), props.children));
});
var SortableList = SortableContainer(function (props) {
    return (React.createElement("div", __assign({}, props, { className: cls("".concat(prefixCls, "-list"), props.className) }), props.children));
});
var isAdditionComponent = function (schema) {
    var _a;
    return ((_a = schema['x-component']) === null || _a === void 0 ? void 0 : _a.indexOf('Addition')) > -1;
};
var useAddition = function () {
    var schema = useFieldSchema();
    return schema.reduceProperties(function (addition, schema, key) {
        if (isAdditionComponent(schema)) {
            return React.createElement(RecursionField, { schema: schema, name: key });
        }
        return addition;
    }, null);
};
var CnArrayItemsView = observer(function (props) {
    var field = useField();
    var schema = useFieldSchema();
    var addition = useAddition();
    var dataSource = Array.isArray(field.value) ? field.value : [];
    return (React.createElement(CnFormArrayBase, null,
        React.createElement("div", __assign({ "data-name": "CnArrayItems" }, props, { onChange: function () { }, className: cls(prefixCls, props.className) }),
            React.createElement(SortableList, { useDragHandle: true, lockAxis: "y", helperClass: "".concat(prefixCls, "-sort-helper"), onSortEnd: function (_a) {
                    var oldIndex = _a.oldIndex, newIndex = _a.newIndex;
                    field.move(oldIndex, newIndex);
                } }, dataSource === null || dataSource === void 0 ? void 0 : dataSource.map(function (item, index) {
                var items = Array.isArray(schema.items)
                    ? schema.items[index] || schema.items[0]
                    : schema.items;
                return (React.createElement(CnFormArrayBase.Item, { key: index, index: index, record: function () { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a[index]; } },
                    React.createElement(SortableItem, { key: "item-".concat(index), index: index },
                        React.createElement("div", { className: "".concat(prefixCls, "-item-inner") },
                            React.createElement(RecursionField, { schema: items, name: index })))));
            })),
            addition)));
});
export var CnArrayItems = mixinCnFormArrayBase(CnArrayItemsView);
CnArrayItems.Item = function (props) {
    return (React.createElement("div", __assign({}, props, { onChange: function () { }, className: cls("".concat(prefixCls, "-").concat(props.type || 'card'), props.className) }), props.children));
};
CnArrayItems.displayName = 'CnArrayItems';
