import dayjs from 'dayjs';
import { CnFormat } from '@cainiaofe/cn-ui-common';
// 策略是逐步改为使用领域组件的 format="dayjs"
// 这个 format="date" 由于写法落后且难以兼容，暂时保存以作兼容
export function formatDate(val, interv, dateFormat) {
    if (val === void 0) { val = '0'; }
    if (interv === void 0) { interv = '-'; }
    if (dateFormat === void 0) { dateFormat = 'YYYY-MM-DD'; }
    if (interv !== '-') {
        var date = new Date(val !== null && val !== void 0 ? val : 0);
        return "".concat(date.getFullYear()).concat(interv).concat(date.getMonth() + 1).concat(interv).concat(date.getDate());
    }
    return dayjs(val).format(dateFormat);
}
export var dateRender = function (value, otherProps) {
    var _a;
    var others = otherProps.others;
    if (value) {
        return formatDate(value, (_a = others === null || others === void 0 ? void 0 : others.dateInterv) !== null && _a !== void 0 ? _a : '-', others === null || others === void 0 ? void 0 : others.dateFormat);
    }
    else {
        return '- -';
    }
};
CnFormat.registerFormat('date', dateRender);
