import { __assign, __rest } from "tslib";
import './scss/index.scss';
import * as React from 'react';
import { withI18n } from 'panda-i18n';
import { Switch } from '@fusion/lib';
import classNames from 'classnames';
import locale from '@/locales';
export var CnSwitch = withI18n(React.forwardRef(function (props, ref) {
    var className = props.className, readOnly = props.readOnly, disabled = props.disabled, $i18n = props.$i18n, others = __rest(props, ["className", "readOnly", "disabled", "$i18n"]);
    return (React.createElement(Switch, __assign({ "data-name": "CnSwitch", ref: ref, className: classNames('cn-ui-switch', className, {
            'cn-ui-switch-readonly': readOnly,
        }), disabled: readOnly || disabled }, others)));
}), {
    locale: locale,
    componentName: 'CnSwitch',
    forwardRef: true,
});
CnSwitch.displayName = 'CnSwitch';
