import { __assign, __spreadArray } from "tslib";
import React from 'react';
import dayjs from 'dayjs';
import moment from 'moment';
import { CnPageScrollDomSelector } from '@cainiaofe/cn-ui-common';
import $i18n from 'panda-i18n';
import isObject from 'lodash/isObject';
export var isInvisible = function (el) {
    var _a;
    return (['none', 'hidden'].indexOf(el === null || el === void 0 ? void 0 : el['x-display']) > -1 ||
        ((_a = el === null || el === void 0 ? void 0 : el['x-decorator-props']) === null || _a === void 0 ? void 0 : _a.hidden) ||
        (el === null || el === void 0 ? void 0 : el['x-hidden']) ||
        (el === null || el === void 0 ? void 0 : el['x-display']) === 'none' ||
        (el === null || el === void 0 ? void 0 : el['x-display']) === 'hidden' ||
        (el === null || el === void 0 ? void 0 : el['x-visible']) === false);
};
/**
 * 将Filter的children节点，最多保留maxRow行
 */
export var calcLayout = function (maxRow, columns, properties, enableSaveSelected, saveSelectSpan, filterRefContext) {
    var _a, _b, _c, _d;
    var idx = 0;
    var currentGroupSpan = 0;
    var groupCnt = 0;
    var arrangedProperties = __spreadArray([], properties, true);
    var showChildren = [];
    if (enableSaveSelected) {
        arrangedProperties.unshift({
            key: 'saveSelected',
            value: {
                colSpan: saveSelectSpan,
            },
        });
    }
    while (idx < arrangedProperties.length) {
        var child = arrangedProperties[idx].value;
        var formInstance = (_a = filterRefContext.current) === null || _a === void 0 ? void 0 : _a.formInstance;
        var item = (_b = formInstance === null || formInstance === void 0 ? void 0 : formInstance.query) === null || _b === void 0 ? void 0 : _b.call(formInstance, arrangedProperties[idx].key).take();
        if (isInvisible(child) ||
            (item === null || item === void 0 ? void 0 : item.display) === 'none' ||
            (item === null || item === void 0 ? void 0 : item.display) === 'hidden') {
            // 排版中跳过 但仍然参与渲染
            showChildren.push(arrangedProperties[idx]);
            idx += 1;
            continue;
        }
        var span = Number(child === null || child === void 0 ? void 0 : child.colSpan) ||
            Number((_c = child === null || child === void 0 ? void 0 : child['x-decorator-props']) === null || _c === void 0 ? void 0 : _c.colSpan) ||
            1;
        // 可换行 && 当前行已满 => 直接换行
        if (groupCnt + 1 < maxRow && currentGroupSpan + span > columns) {
            currentGroupSpan = 0;
            ++groupCnt;
        }
        // 不可换行 && 当前行无法放下btn + 当前元素 => 不添加当前元素
        if (groupCnt + 1 >= maxRow && currentGroupSpan + span > columns) {
            break;
        }
        // 将当前元素添加到当前行
        currentGroupSpan += span;
        showChildren.push(arrangedProperties[idx]);
        idx += 1;
    }
    var hideChildren = arrangedProperties.slice(idx);
    if (enableSaveSelected && ((_d = showChildren === null || showChildren === void 0 ? void 0 : showChildren[0]) === null || _d === void 0 ? void 0 : _d.key) === 'saveSelected') {
        showChildren.shift();
    }
    return {
        showChildren: showChildren,
        hideChildren: hideChildren,
    };
};
export var calcLayoutWithButton = function (maxRow, columns, buttonSpan, properties, enableSaveSelected, saveSelectSpan, filterRefContext) {
    var _a, _b, _c, _d;
    var idx = 0;
    var currentGroupSpan = 0;
    var groupCnt = 0;
    var btnSpan = Math.min(buttonSpan, columns);
    var arrangedProperties = __spreadArray([], properties, true);
    var showChildren = [];
    if (enableSaveSelected) {
        arrangedProperties.unshift({
            key: 'saveSelected',
            value: {
                colSpan: saveSelectSpan,
            },
        });
    }
    while (idx < arrangedProperties.length) {
        var child = arrangedProperties[idx].value;
        var formInstance = (_a = filterRefContext.current) === null || _a === void 0 ? void 0 : _a.formInstance;
        var item = (_b = formInstance === null || formInstance === void 0 ? void 0 : formInstance.query) === null || _b === void 0 ? void 0 : _b.call(formInstance, arrangedProperties[idx].key).take();
        if (isInvisible(child) ||
            (item === null || item === void 0 ? void 0 : item.display) === 'none' ||
            (item === null || item === void 0 ? void 0 : item.display) === 'hidden') {
            // 排版中跳过 但仍然参与渲染
            showChildren.push(arrangedProperties[idx]);
            idx += 1;
            continue;
        }
        var span = Number(child === null || child === void 0 ? void 0 : child.colSpan) ||
            Number((_c = child === null || child === void 0 ? void 0 : child['x-decorator-props']) === null || _c === void 0 ? void 0 : _c.colSpan) ||
            1;
        // 可换行 && 当前行已满 => 直接换行
        if (groupCnt + 1 < maxRow && currentGroupSpan + span > columns) {
            currentGroupSpan = 0;
            ++groupCnt;
        }
        // 不可换行 && 当前行无法放下btn + 当前元素 => 不添加当前元素
        if (groupCnt + 1 >= maxRow && currentGroupSpan + span + btnSpan > columns) {
            break;
        }
        // 将当前元素添加到当前行
        currentGroupSpan += span;
        showChildren.push(arrangedProperties[idx]);
        idx += 1;
    }
    // btn前置空白
    var spaceSpan = columns - currentGroupSpan - btnSpan;
    if (spaceSpan < 0) {
        // 此行已放不下btn，需要换行
        ++groupCnt;
        showChildren.push({
            key: 'cn-filter-right-button-span',
            value: {
                colSpan: columns - btnSpan,
            },
        });
    }
    else {
        if (spaceSpan > 0) {
            showChildren.push({
                key: 'cn-filter-right-button-space-span',
                value: {
                    colSpan: spaceSpan,
                },
            });
        }
        ++groupCnt;
    }
    var hideChildren = arrangedProperties.slice(idx);
    if (enableSaveSelected && ((_d = showChildren === null || showChildren === void 0 ? void 0 : showChildren[0]) === null || _d === void 0 ? void 0 : _d.key) === 'saveSelected') {
        showChildren.shift();
    }
    return {
        showChildren: showChildren,
        hideChildren: hideChildren,
    };
};
/**
 * 计算流式布局的children
 * @param maxRow 最大显示行数
 * @param width 容器宽度
 * @param properties form的schema
 * @param enableSaveSelected 是否显示我的查询条件
 * @param filterRefContext filter上下文
 * @param columnGap 间距
 * @param size 尺寸
 * @returns 显示和隐藏的shema
 */
export var calcFlexLayout = function (maxRow, width, properties, enableSaveSelected, filterRefContext, columnGap, size, hideButton) {
    var _a, _b, _c, _d, _e, _f;
    var idx = 0;
    var currentGroupWidth = 0;
    var groupCnt = 0;
    var btnWidth = hideButton ? 180 : 220;
    var arrangedProperties = __spreadArray([], properties, true);
    var showChildren = [];
    if (enableSaveSelected) {
        arrangedProperties.unshift({
            key: 'saveSelected',
            value: {
                title: $i18n.get({ id: 'myCriteria', dm: '我的查询条件' }),
            },
        });
    }
    while (idx < arrangedProperties.length) {
        var child = arrangedProperties[idx].value;
        var formInstance = (_a = filterRefContext.current) === null || _a === void 0 ? void 0 : _a.formInstance;
        var item = (_b = formInstance === null || formInstance === void 0 ? void 0 : formInstance.query) === null || _b === void 0 ? void 0 : _b.call(formInstance, arrangedProperties[idx].key).take();
        if (isInvisible(child) ||
            (item === null || item === void 0 ? void 0 : item.display) === 'none' ||
            (item === null || item === void 0 ? void 0 : item.display) === 'hidden') {
            // 排版中跳过 但仍然参与渲染
            showChildren.push(arrangedProperties[idx]);
            idx += 1;
            continue;
        }
        var tipWidth = ((_c = child === null || child === void 0 ? void 0 : child['x-decorator-props']) === null || _c === void 0 ? void 0 : _c.tip) ? 18 : 0;
        var requiredWidth = (child === null || child === void 0 ? void 0 : child.required) ? 10 : 0;
        var labelRightGap = 8;
        var wrapperWidth = Number((_d = child === null || child === void 0 ? void 0 : child['x-decorator-props']) === null || _d === void 0 ? void 0 : _d.wrapperWidth) ||
            getFlowFormItemWidthByComponentName(child === null || child === void 0 ? void 0 : child['x-component']);
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        ctx.font = "".concat(size === 'large' ? 16 : 12, "px Arial");
        var text = (child === null || child === void 0 ? void 0 : child.title) || '';
        var wordWidth = ctx.measureText(text).width;
        var labelWidth = ((_e = child === null || child === void 0 ? void 0 : child.title) === null || _e === void 0 ? void 0 : _e.length) ? Math.min(wordWidth, 116) : 0;
        var currentItemWidth = labelWidth + wrapperWidth + requiredWidth + tipWidth + labelRightGap;
        // 可换行 && 当前行已满 => 直接换行
        if (groupCnt + 1 < maxRow &&
            currentGroupWidth + currentItemWidth + Number(columnGap) > width) {
            currentGroupWidth = 0;
            ++groupCnt;
        }
        // 不可换行 && 当前行无法放下btn + 当前元素 => 不添加当前元素
        if (groupCnt + 1 >= maxRow &&
            currentGroupWidth + currentItemWidth + btnWidth > width) {
            break;
        }
        // 将当前元素添加到当前行（第一项不加间距，其他需要加间距）
        currentGroupWidth =
            currentGroupWidth === 0
                ? currentItemWidth
                : currentGroupWidth + currentItemWidth + Number(columnGap);
        showChildren.push(arrangedProperties[idx]);
        idx += 1;
    }
    var hideChildren = arrangedProperties.slice(idx);
    if (enableSaveSelected && ((_f = showChildren === null || showChildren === void 0 ? void 0 : showChildren[0]) === null || _f === void 0 ? void 0 : _f.key) === 'saveSelected') {
        showChildren.shift();
    }
    return {
        showChildren: showChildren,
        hideChildren: hideChildren,
    };
};
export var defaultDateFormat = {
    time: 'HH:mm:ss',
    date: 'YYYY-MM-DD',
    month: 'YYYY-MM',
    week: 'GGGG-wo',
    year: 'YYYY',
    quarter: 'YYYY-[Q]Q',
};
export var isEmpty = function (value) {
    if (['boolean', 'number'].includes(typeof value))
        return false;
    return !value;
};
export var normalizeChildren = function (value) {
    return React.Children.toArray(value);
};
export function normalizeArray(value) {
    if (isEmpty(value))
        return [];
    var _value = Array.isArray(value) ? value : [value];
    return _value.filter(function (val) { return !isEmpty(val); });
}
var _childrenToDataSource = function (dataSource, children, targetDisplayName, normalizeFn, ignoreChildren) {
    React.Children.forEach(children, function (child) {
        var _a;
        if (!targetDisplayName ||
            normalizeDisplayName(((_a = child === null || child === void 0 ? void 0 : child.type) === null || _a === void 0 ? void 0 : _a.displayName) || '') === targetDisplayName) {
            dataSource.push(normalizeFn(child));
            if (ignoreChildren)
                return;
        }
        if (child === null || child === void 0 ? void 0 : child.props.children) {
            _childrenToDataSource(dataSource, child === null || child === void 0 ? void 0 : child.props.children, targetDisplayName, normalizeFn);
        }
    });
};
export var childrenToDataSource = function (children, targetDisplayName, normalizeFn, ignoreChildren) {
    var dataSource = [];
    _childrenToDataSource(dataSource, children, normalizeDisplayName(targetDisplayName), normalizeFn, ignoreChildren);
    return dataSource;
};
var _valueToDataSourceItem = function (payload, value, dataSourceNode) {
    if (payload.count === value.length)
        return;
    if (['boolean', 'number', 'string'].includes(typeof dataSourceNode)) {
        var idx = value.indexOf(dataSourceNode);
        if (idx >= 0) {
            payload.items[idx] = {
                value: dataSourceNode,
                label: dataSourceNode,
            };
            payload.count += 1;
            return;
        }
    }
    if (!isEmpty(dataSourceNode.value)) {
        var idx = value.indexOf(dataSourceNode.value);
        if (idx >= 0) {
            payload.items[idx] = dataSourceNode;
            payload.count += 1;
        }
    }
    if (Array.isArray(dataSourceNode.children)) {
        dataSourceNode.children.forEach(function (childNode) {
            _valueToDataSourceItem(payload, value, childNode);
        });
    }
};
export var valueToDataSourceItem = function (value, dataSource) {
    var normalizedValue = normalizeArray(value);
    var payload = {
        count: 0,
        items: normalizedValue.slice(),
    };
    _valueToDataSourceItem(payload, normalizedValue, {
        children: dataSource,
    });
    return payload.items;
};
export var genId = function (ids) {
    return Math.max.apply(Math, __spreadArray(__spreadArray([], (ids || []), false), [0], false)) + 1;
};
export function formatValue(value, formatter) {
    if (Array.isArray(value)) {
        return value.map(formatter);
    }
    return formatter(value);
}
export var normalizeDisplayName = function (displayName) {
    return (displayName || '').replace(/Config\(/g, '').replace(/\)/g, '');
};
export var getNormalizedDisplayName = function (type) {
    return normalizeDisplayName((type === null || type === void 0 ? void 0 : type.displayName) || '');
};
var componentsWithoutOverlay = [
    'Checkbox',
    'Input',
    'NumberPicker',
    'Radio',
    'Range',
    'Rating',
    'Switch',
    'CnCheckbox',
    'CnInput',
    'CnNumberPicker',
    'CnRangeNumberPicker',
    'CnNumberPickerState',
    'CnRadio',
    'CnRange',
    'CnRating',
    'CnSwitch',
];
export var getItemStyleInjectProps = function (componentName, props) {
    var _a;
    if (componentsWithoutOverlay.indexOf(componentName) >= 0) {
        return null;
    }
    // 使用 v2 的组件自己覆盖 container 设置
    var popupContainer = function (e) {
        var _a, _b;
        return (_b = (_a = e === null || e === void 0 ? void 0 : e.closest('.cn-ui-filter-pro')) !== null && _a !== void 0 ? _a : e === null || e === void 0 ? void 0 : e.closest(CnPageScrollDomSelector)) !== null && _b !== void 0 ? _b : document.body;
    };
    return {
        popupContainer: (_a = props === null || props === void 0 ? void 0 : props.popupContainer) !== null && _a !== void 0 ? _a : popupContainer,
        popupProps: __assign({ container: popupContainer }, ((props === null || props === void 0 ? void 0 : props.popupProps) || {})),
    };
};
var formatConfig = {
    moment: {
        componentNames: [
            'DatePicker',
            'RangePicker',
            'WeekPicker',
            'MonthPicker',
            'YearPicker',
            'TimePicker',
        ],
        getValueFormatter: function (val) {
            return formatValue(val, function (v) {
                return (moment.isMoment(v) ? v.valueOf() : v);
            });
        },
        setValueFormatter: function (val) {
            return formatValue(val, function (v) {
                return (typeof v === 'number' ? moment(v) : v);
            });
        },
    },
    dayjs: {
        componentNames: [
            'DatePicker2',
            'RangePicker2',
            'WeekPicker2',
            'MonthPicker2',
            'QuarterPicker2',
            'YearPicker2',
            'TimePicker2',
        ],
        getValueFormatter: function (val) {
            return formatValue(val, function (v) {
                return (dayjs.isDayjs(v) ? v.valueOf() : v);
            });
        },
        setValueFormatter: function (val) {
            return formatValue(val, function (v) {
                return (typeof v === 'number' ? dayjs(v) : v);
            });
        },
    },
};
export var getValueFormatterInjectProps = function (componentName) {
    var typeName = Object.keys(formatConfig).find(function (key) {
        var _a;
        return (_a = formatConfig[key]) === null || _a === void 0 ? void 0 : _a.componentNames.includes(componentName);
    });
    return typeName
        ? {
            _formattertypename: typeName,
        }
        : null;
};
// 流式布局组件默认宽度映射
export var FLOW_FORM_ITEM_WIDTH_MAP = {
    // 116
    CnTimePickerPro: 116,
    CnTimePicker2: 116,
    CnDatePickerPro: 116,
    CnDatePicker2: 116,
    CnMonthPickerPro: 116,
    CnMonthPicker2: 116,
    CnYearPickerPro: 116,
    CnYearPicker2: 116,
    CnWeekPickerPro: 116,
    CnWeekPicker2: 116,
    CnQuarterPickerPro: 116,
    CnQuarterPicker2: 116,
    TimePicker: 116,
    // 168
    CnRangeTimePickerPro: 168,
    CnRangeTimePicker2: 168,
    RangeTimePicker: 168,
    // 200
    CnCurrencyAmountNumberPicker: 200,
    CnRadio: 200,
    CnRadioGroup: 200,
    CnCheckbox: 200,
    CnCheckboxGroup: 200,
    CnRangeNumberPicker: 200,
    CnAsyncSelect: 200,
    CnSelect: 200,
    RadioGroup: 200,
    Select: 200,
    // 312
    CnRangeDatePickerPro: 312,
    CnRangeDatePicker2: 312,
    CnDateRangePickerPro: 312,
    RangePicker: 312,
};
// 流式布局组件默认宽度
export var DEFAULT_FORM_ITEM_WIDTH = 152;
/**
 * 根据组件名称获取组件宽度
 * @param componentName 组件名称
 * @returns 组件在流式布局下的宽度
 */
export var getFlowFormItemWidthByComponentName = function (componentName) {
    return FLOW_FORM_ITEM_WIDTH_MAP[componentName] || DEFAULT_FORM_ITEM_WIDTH;
};
/**
 * 获取最大行数
 * @param schema 传入的schema
 * @param enableSaveSelected 是否显示我的查询条件
 * @returns 最大行数
 */
export var getMaxRowBySchema = function (schema, enableSaveSelected) {
    if (!(schema === null || schema === void 0 ? void 0 : schema.properties) || !isObject(schema.properties)) {
        return 2;
    }
    var maxRowBySchema = enableSaveSelected
        ? Object.keys(schema.properties).length + 1
        : Object.keys(schema.properties).length;
    return maxRowBySchema;
};
