import './index.scss';
import $i18n from 'panda-i18n';
import React from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { CnIcon } from '@/components/cn-icon';
import { downloadFile } from '@cainiaofe/cn-ui-utils';
import { CnBox } from '@/components/cn-box';
import { CnEllipsis } from '@/components/cn-ellipsis';
import { CnTooltip } from '@/components/cn-tooltip';
import { classMap, iconMap, cssPrefix } from './constant';
import { useStatusMap } from '../../hooks/use-status-map';
import JobItemRight from '../job-item-right';
export default function JobItem(props) {
    var type = props.type, data = props.data, className = props.className;
    var jobName = data.jobName, createTime = data.createTime, status = data.status, errorDownloadUrl = data.errorDownloadUrl, reason = data.reason;
    var statusMap = useStatusMap();
    return (React.createElement("div", { className: classNames(cssPrefix, classMap[status], className) },
        React.createElement("div", { className: "".concat(cssPrefix, "-content") },
            React.createElement(CnEllipsis, { className: "".concat(cssPrefix, "-content-title"), ellipsisPosition: "middle" }, jobName),
            React.createElement(CnBox, { className: "".concat(cssPrefix, "-content-bottom"), direction: "row", align: "center", spacing: 4 },
                React.createElement(CnIcon, { size: 16, type: iconMap[status] }),
                React.createElement("div", { className: "".concat(cssPrefix, "-content-status") }, statusMap[status]),
                React.createElement("div", { className: "".concat(cssPrefix, "-content-create-time") }, dayjs(createTime).format('YYYY-MM-DD HH:mm')),
                reason ? (React.createElement(CnTooltip, { align: "t", trigger: React.createElement("div", { className: "".concat(cssPrefix, "-content-failed-record") }, $i18n.get({
                        id: 'FailureReason',
                        dm: '失败原因',
                        ns: 'CnAsyncJobsPro',
                    })) }, reason)) : null,
                errorDownloadUrl ? (React.createElement("div", { className: "".concat(cssPrefix, "-content-failed-record"), onClick: function () { return downloadFile(errorDownloadUrl); } }, $i18n.get({
                    id: 'FailureRecord',
                    dm: '失败记录',
                    ns: 'CnAsyncJobsPro',
                }))) : null)),
        React.createElement("div", { className: "".concat(cssPrefix, "-right") },
            React.createElement(JobItemRight, { type: type, data: data }))));
}
