import { __assign, __rest } from "tslib";
import * as React from 'react';
import { CnFormGrid as UICnFormGrid, } from '@/components/cn-form-grid';
import { formilyReact } from '@/form/formily';
// 判断字段是否隐藏，如果隐藏则不渲染
var isHidden = function (fieldQuery) {
    // 查询表单字段信息; https://core.formilyjs.org/zh-CN/api/models/query#take
    var display = (fieldQuery.take() || {}).display;
    return display === 'none' || display === 'hidden';
};
export var CnFormGrid = formilyReact.observer(function (props) {
    var children = props.children, otherProps = __rest(props, ["children"]);
    var fieldSchema = formilyReact.useFieldSchema();
    var field = formilyReact.useField();
    var list = fieldSchema.mapProperties(function (schema, name) {
        if (isHidden(field.query(field.address.concat(name)))) {
            return null;
        }
        return schema;
    });
    if (list.length === 0)
        return null;
    return (React.createElement(UICnFormGrid, __assign({ "data-name": "CnFormGrid" }, otherProps), fieldSchema.mapProperties(function (schema, name) {
        var _a, _b, _c, _d;
        // 获取字段 formPath; https://core.formilyjs.org/zh-CN/api/entry/form-path
        if (isHidden(field.query(field.address.concat(name)))) {
            return null;
        }
        return (React.createElement(formilyReact.RecursionField, { key: name, schema: schema, name: name, 
            // @ts-ignore 给父组件使用,忽略 RecursionField colSpan 未定义提示，同时忽略 schema.colSpan 未定义提示
            colSpan: (_b = (_a = schema === null || schema === void 0 ? void 0 : schema['x-decorator-props']) === null || _a === void 0 ? void 0 : _a.colSpan) !== null && _b !== void 0 ? _b : schema.colSpan, 
            // @ts-ignore 给父组件使用,忽略 RecursionField rowSpan 未定义提示，同时忽略 schema.rowSpan 未定义提示
            rowSpan: (_d = (_c = schema === null || schema === void 0 ? void 0 : schema['x-decorator-props']) === null || _c === void 0 ? void 0 : _c.rowSpan) !== null && _d !== void 0 ? _d : schema.rowSpan }));
    })));
});
