import { events } from '../events';
import { COMPACT_STORAGE_KEY } from './constant';
import { SIZE_CHANGE } from "../../constant";
var sizeList = ['small', 'medium', 'large'];
/**
 * 将当前页面的紧凑模式设置为指定的大小并存储到本地
 * @param size
 */
export var setGlobalSize = function (size) {
    // 校验数据是否合法
    if (!sizeList.includes(size)) {
        return;
    }
    var result = {};
    try {
        var data = localStorage.getItem(COMPACT_STORAGE_KEY);
        if (data) {
            result = JSON.parse(data);
        }
        if (typeof result !== 'object') {
            result = {};
        }
    }
    catch (_a) {
        result = {};
    }
    result[location.origin] = size;
    localStorage.setItem(COMPACT_STORAGE_KEY, JSON.stringify(result));
    events.emit(SIZE_CHANGE, size);
};
/**
 * 从本地数据获取当前页面的紧凑模式大小
 */
export var getGlobalSize = function () {
    try {
        var data = localStorage.getItem(COMPACT_STORAGE_KEY);
        var result = JSON.parse(data);
        var size = result[location.origin];
        // 校验数据是否合法
        if (sizeList.includes(size)) {
            return size;
        }
    }
    catch (_a) { }
    return undefined;
};
