import $i18n from "../../locales/i18n";
/**
 * 用于校验文件是否上传完成
 * 当未完成时拦截提交并抛出异常
 */
export var checkUploadIsComplete = function (value, rule) {
    if (!rule.checkUploadIsComplete)
        return true;
    if (!(value === null || value === void 0 ? void 0 : value.length))
        return true;
    /**
     * 1. 先过滤状态为空的逻辑
     * 2. 再判断状态是否存在非 done 的情况，非 done 代表未上传完成
     */
    var notCompleteList = value.filter(function (item) { return item.status && item.status !== 'done'; });
    if (notCompleteList.length) {
        var fileNameList = notCompleteList
            .map(function (item) { return item.name; })
            .filter(Boolean)
            .join(',');
        return $i18n.get({
            id: 'TheFileFileNameListHasNotBeenUpl_403816816',
            dm: '文件{fileNameList}还未上传完成，请等待文件上传完成后再提交',
        }, { fileNameList: fileNameList });
    }
    return true;
};
