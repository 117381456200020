import { SIZE_CHANGE } from "../constant";
import { events, getGlobalSize } from "../utils";
import { useEffect, useState } from 'react';
export function useSizeChange(props) {
    var _a;
    var _b = useState(getGlobalSize), size = _b[0], setSize = _b[1];
    useEffect(function () {
        events.on(SIZE_CHANGE, function (temp) {
            setSize(temp);
        });
        return function () {
            events.off(SIZE_CHANGE, setSize);
        };
    }, []);
    return {
        size: (_a = props === null || props === void 0 ? void 0 : props.size) !== null && _a !== void 0 ? _a : size,
        setSize: setSize,
    };
}
