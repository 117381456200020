import { __assign } from "tslib";
import './view/cn-badge.scss';
import * as React from 'react';
import NextBadge from '@fusion/lib/badge';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
export var CnBadge = function (props) {
    return withNativeProps(props, React.createElement(NextBadge, __assign({ "data-testid": "CnBadge", "data-name": "CnBadge" }, props)));
};
CnBadge.displayName = 'CnBadge';
/**
 * @deprecated  请使用 CnBadge 替换
 */
export var Badge = CnBadge;
