import { __assign, __awaiter, __generator } from "tslib";
import isEmpty from 'lodash/isEmpty';
import { packRequest } from './pack-request';
import { getStandardData } from './get-standard-data';
import processPostData from './process-post-data';
import processGetParams from './process-get-params';
export function getRequestService(requestConfig) {
    var _this = this;
    if (!requestConfig)
        return undefined;
    var serviceProps = requestConfig.service, methodProps = requestConfig.method, dataProps = requestConfig.data, formatParam = requestConfig.formatParam, formatResultProps = requestConfig.formatResult, headers = requestConfig.headers;
    var contentType = headers === null || headers === void 0 ? void 0 : headers['content-type'];
    var method = (methodProps === null || methodProps === void 0 ? void 0 : methodProps.toLocaleUpperCase()) || 'GET';
    var formatResult = formatResultProps || requestConfig.resultFormatter;
    /**
     * 兼容历史逻辑，该逻辑未来会删除
     */
    var compatibleOldData = function (res) {
        if (isEmpty(res)) {
            return res;
        }
        if (typeof formatResult === 'function') {
            // @ts-ignore 历史兼容代码类型不匹配，忽略校验
            return formatResult(res);
        }
        if (!isEmpty(res === null || res === void 0 ? void 0 : res.data)) {
            return res === null || res === void 0 ? void 0 : res.data;
        }
    };
    var service = function (params) { return __awaiter(_this, void 0, void 0, function () {
        var response, responseKeys, processedParams, processedData, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!serviceProps) return [3 /*break*/, 2];
                    return [4 /*yield*/, serviceProps(params)];
                case 1:
                    response = _a.sent();
                    responseKeys = Object.keys(response);
                    if (responseKeys.includes('request') &&
                        responseKeys.includes('status') &&
                        responseKeys.includes('headers') &&
                        responseKeys.includes('config')) {
                        return [2 /*return*/, compatibleOldData(response)];
                    }
                    /** end compatible */
                    return [2 /*return*/, response];
                case 2:
                    processedParams = processGetParams({
                        method: method,
                        runParams: params,
                        params: requestConfig.params,
                        formatParam: formatParam,
                    });
                    processedData = processPostData({
                        contentType: contentType,
                        method: method,
                        runParams: params,
                        data: dataProps,
                        formatParam: formatParam,
                    });
                    return [4 /*yield*/, packRequest(__assign(__assign({}, requestConfig), { params: processedParams, data: processedData }))];
                case 3:
                    res = _a.sent();
                    return [2 /*return*/, getStandardData(res, formatResult)];
            }
        });
    }); };
    return service;
}
