import { __assign, __extends, __rest } from "tslib";
import * as React from 'react';
import { Balloon } from '@/components/fusion';
import './employee-info.scss';
import $i18n from 'panda-i18n';
import { CnButton } from '@/components/cn-button';
import classNames from 'classnames';
var EmployeeInfo = /** @class */ (function (_super) {
    __extends(EmployeeInfo, _super);
    function EmployeeInfo() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.renderCard = function () {
            var dataSource = _this.props.dataSource;
            var name = dataSource.name, nickName = dataSource.nickName, workNo = dataSource.workNo, department = dataSource.department;
            return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-employee-info'), key: workNo },
                React.createElement("div", { className: "cn-ui-employee-info-header" },
                    React.createElement("div", { className: "cn-ui-employee-detail" },
                        React.createElement("div", { className: "cn-ui-employee-detail-header" },
                            React.createElement("img", { className: "employee-img", src: "https://work.alibaba-inc.com/photo/".concat(workNo, ".200x200.jpg?") })),
                        React.createElement("div", { className: "cn-ui-employee-detail-footer" },
                            React.createElement("div", { className: "employee-name" },
                                name,
                                nickName && "(".concat(nickName, ")")),
                            React.createElement("div", { className: "employee-workid" }, workNo)))),
                React.createElement("div", { className: "cn-ui-employee-info-body" }, department),
                React.createElement("div", { className: "cn-ui-employee-info-footer" },
                    React.createElement(CnButton, { component: "a", target: "_blank", type: "secondary", href: "https://work.alibaba-inc.com/nwpipe/u/".concat(workNo) }, $i18n.get({
                        id: '37329441080348672.CNTM',
                        dm: '菜鸟内外',
                        ns: 'CnEmployeeSelect',
                    })))));
        };
        _this.renderTrigger = function () {
            var dataSource = _this.props.dataSource;
            var name = dataSource.name, nickName = dataSource.nickName, workNo = dataSource.workNo;
            return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-employee-info-trigger') },
                React.createElement("img", { className: "cn-ui-employee-info-trigger-img", src: "https://work.alibaba-inc.com/photo/".concat(workNo, ".50x50.jpg") }),
                React.createElement("span", { className: "cn-ui-employee-info-trigger-text" },
                    name,
                    " ",
                    nickName && "(".concat(nickName, ")"))));
        };
        return _this;
    }
    EmployeeInfo.prototype.render = function () {
        var _a = this.props, dataSource = _a.dataSource, otherProps = __rest(_a, ["dataSource"]);
        return (React.createElement(Balloon, __assign({ closable: false, popupClassName: "cn-ui-employee-info-popup", align: "bl", alignEdge: true, needAdjust: true, triggerType: ['hover', 'click'], autoFocus: false, trigger: this.renderTrigger() }, otherProps), this.renderCard()));
    };
    EmployeeInfo.displayName = 'EmployeeInfo';
    return EmployeeInfo;
}(React.Component));
export { EmployeeInfo };
