import { __assign } from "tslib";
import React from 'react';
import { formilyShared } from '@/form/formily';
import { CnFormArrayBase, } from '@/form/cn-form-array-base';
import { usePaginationContext } from '../pagination-context';
export var CnArrayTableAddition = function (props) {
    var array = CnFormArrayBase.useArray();
    var _a = usePaginationContext(), _b = _a.totalPage, totalPage = _b === void 0 ? 0 : _b, _c = _a.pageSize, pageSize = _c === void 0 ? 10 : _c, changePage = _a.changePage;
    return (React.createElement(CnFormArrayBase.Addition, __assign({}, props, { onClick: function (e) {
            var _a, _b;
            // 如果添加数据后将超过当前页，则自动切换到下一页
            var total = ((_a = array === null || array === void 0 ? void 0 : array.field) === null || _a === void 0 ? void 0 : _a.value.length) || 0;
            if (total === totalPage * pageSize + 1 &&
                formilyShared.isFn(changePage)) {
                changePage(totalPage + 1);
            }
            (_b = props.onClick) === null || _b === void 0 ? void 0 : _b.call(props, e);
        } })));
};
