import { __assign, __rest } from "tslib";
import React from 'react';
import classNames from 'classnames';
import { PandaConfigProvider } from 'panda-i18n';
import { withNativeProps } from '@cainiaofe/cn-ui-common';
import { CnPageContent } from './page-content';
export function CnPageView(props) {
    var style = props.style, className = props.className, restProps = __rest(props, ["style", "className"]);
    return (React.createElement(PandaConfigProvider, null, withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-page', 'cn-page', {
            'cn-page--no-padding': restProps.noPadding,
        }), "data-name": "CnPage" },
        React.createElement(CnPageContent, __assign({}, restProps)),
        React.createElement("div", { className: "cn-ui-page-footer-placeholder" })))));
}
