import { events } from '@/components/cn-utils';
import { HIDE_EXPORT_LOADING, HIDE_IMPORT_LOADING, SHOW_EXPORT_LOADING, SHOW_IMPORT_LOADING, } from '@/components/cn-async-jobs-pro/constant';
import { useEffect } from 'react';
export default function useLauncherLoading(props) {
    var type = props.type, loading = props.loading;
    useEffect(function () {
        if (type === 'export') {
            events.emit(loading ? SHOW_EXPORT_LOADING : HIDE_EXPORT_LOADING);
        }
        if (type === 'import') {
            events.emit(loading ? SHOW_IMPORT_LOADING : HIDE_IMPORT_LOADING);
        }
    }, [loading, type]);
}
