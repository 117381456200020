import './array-base.scss';
import React from 'react';
import { formilyReact } from '@/form/formily';
import { CnFormArrayBaseContext } from '../context';
import { mixinCnFormArrayBase } from '../utils';
var useField = formilyReact.useField, useFieldSchema = formilyReact.useFieldSchema, RecordsScope = formilyReact.RecordsScope;
var EditArrayView = function (props) {
    var field = useField();
    var schema = useFieldSchema();
    return (React.createElement(RecordsScope, { getRecords: function () { return field.value; } },
        React.createElement(CnFormArrayBaseContext.Provider, { value: { field: field, schema: schema, props: props } }, props.children)));
};
export var CnFormArrayBase = mixinCnFormArrayBase(EditArrayView);
