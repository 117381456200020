import { isProduction } from './env';
var isDev = !isProduction();
export function devWarning(component, message) {
    if (isDev) {
        console.warn("[cn-ui: ".concat(component, "] ").concat(message));
    }
}
export function devError(component, message) {
    if (isDev) {
        console.error("[cn-ui: ".concat(component, "] ").concat(message));
    }
}
var infoBox;
export function devPrint() {
    var message = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        message[_i] = arguments[_i];
    }
    if (isDev) {
        if (!infoBox) {
            infoBox = document.createElement('textarea');
            document.body.append(infoBox);
            infoBox.style.position = 'fixed';
            infoBox.style.top = '0';
            infoBox.style.left = '0';
            infoBox.style.width = '100vw';
            infoBox.style.height = '100vh';
            infoBox.style.zIndex = '999999';
            infoBox.style.fontSize = '12px';
            infoBox.style.pointerEvents = 'none';
            infoBox.style.background = 'transparent';
            infoBox.style.textShadow =
                '-1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px -1px 0 #FFF, 1px 1px 0 #FFF';
        }
        infoBox.value = "".concat(infoBox.value, "\n").concat(message.join(' ')).trim();
        infoBox.scrollTop = infoBox.scrollHeight;
    }
}
