import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import { CnOSSUpload as OSSUpload, } from '@/components/cn-oss-upload';
import { formilyReact } from '@/form/formily';
import { getFormUploadProps } from '@cainiaofe/cn-ui-common';
export var CnOSSUpload = formilyReact.observer(function (props) {
    var fileListRef = React.useRef([]);
    var field = formilyReact.useField();
    return (React.createElement(OSSUpload, __assign({}, props, getFormUploadProps({
        fileListRef: fileListRef,
        field: field,
        props: props,
        uploadErrMsg: $i18n.get({
            id: 'PleaseWaitForTheUploadToEnd',
            dm: '请等待上传结束',
            ns: 'CnForm',
        }),
    }))));
});
