import { __assign, __rest } from "tslib";
import './cn-icon-list.scss';
import './cn-icon.scss';
import * as React from 'react';
import isString from 'lodash/isString';
import { createFromIconfontCN } from '../icon-font';
import classNames from 'classnames';
var scriptUrl = 'https://at.alicdn.com/t/c/font_3267196_9f40m3jbp5i.js';
// const script = document.createElement('script');
// script.setAttribute('src', scriptUrl);
// script.setAttribute('data-namespace', scriptUrl);
// script.defer = true;
// document.body.appendChild(script);
var CustomIcon = createFromIconfontCN({
    scriptUrl: scriptUrl,
});
export var CnIcon = function (props) {
    var type = props.type, outerClassName = props.outerClassName, otherProps = __rest(props, ["type", "outerClassName"]);
    var realType = type || '';
    if (isString(type) && !type.startsWith('icon-')) {
        realType = "icon-".concat(type);
    }
    return (React.createElement(CustomIcon, __assign({}, otherProps, { outerClassName: classNames(outerClassName, 'cn-ui-icon'), type: realType })));
};
CnIcon.createFromIconfontCN = createFromIconfontCN;
