import dayjs from 'dayjs';
import { getModeByDisplayName } from './get-mode-by-display-name';
/**
 * 根据mode、showTime将日期格式转string格式
 * @param date 日期格式
 * @param mode 日期模式
 * @param showTime 是否显示时间
 * @param format 日期格式
 * @returns string格式
 */
export function date2StringByModeShowTime(date, mode, showTime, format, displayName) {
    try {
        var res = '';
        if (!date || (date === null || date === void 0 ? void 0 : date.isValid()) === false) {
            throw new Error('Invalid date');
        }
        var currentMode = mode || getModeByDisplayName(displayName);
        if (format) {
            return dayjs(date).format(format);
        }
        else {
            if (showTime) {
                return date.format('YYYY-MM-DD HH:mm:ss');
            }
            switch (currentMode) {
                case 'year':
                    res = date.format('YYYY');
                    break;
                case 'month':
                    res = date.format('YYYY-MM');
                    break;
                case 'week':
                    res = date.format('YYYY-wo');
                    break;
                case 'quarter':
                    res = "".concat(date.format('YYYY'), "-Q").concat(date.format('Q'));
                    break;
                default:
                    res = date.format('YYYY-MM-DD');
                    break;
            }
        }
        return res;
    }
    catch (error) {
        throw new Error(error);
    }
}
