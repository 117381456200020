import $i18n from 'panda-i18n';
import React from 'react';
import classNames from 'classnames';
import { CnEllipsis } from '../../../../components/cn-ellipsis';
import { CnIcon } from '../../../cn-icon';
import { EntryPointsContent } from '../entry-points-content';
import './index.scss';
export var EntryPoints = function (props) {
    var icon = props.icon, iconBgColor = props.iconBgColor, title = props.title, desc = props.desc, viewMore = props.viewMore, dataSource = props.dataSource, onItemClick = props.onItemClick, style = props.style, className = props.className;
    return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-entry-points', className), style: style },
        React.createElement("div", { className: "cn-entry-points-hd" },
            icon && (React.createElement("div", { className: "cn-entry-points-hd-icon", style: { background: iconBgColor } },
                React.createElement(CnIcon, { size: "xl", type: icon }))),
            React.createElement("div", { className: "cn-entry-points-hd-content" },
                React.createElement("div", { className: "cn-entry-points-hd-content-line1" },
                    React.createElement(CnEllipsis, { className: "cn-entry-points-hd-content-title" }, title),
                    (viewMore === null || viewMore === void 0 ? void 0 : viewMore.visible) ? (React.createElement("div", { className: "cn-entry-points-hd-content-view-more", onClick: viewMore.onClick }, viewMore.children || (React.createElement(React.Fragment, null,
                        $i18n.get({ id: 'More', dm: '更多', ns: 'CnEntryPoints' }),
                        React.createElement(CnIcon, { size: "small", type: "arrow-right" }))))) : null),
                desc ? (React.createElement("div", { className: "cn-entry-points-hd-content-desc" }, desc)) : null)),
        React.createElement(EntryPointsContent, { onItemClick: onItemClick, dataSource: dataSource })));
};
EntryPoints.defaultProps = {
    viewMore: {
        visible: true,
    },
};
