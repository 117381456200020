// 初始化项目的时候填入的变量, 请谨慎修改, 会影响各个地方的使用
export var componentName = 'CnResult';
/*
 * 结果组件状态类型
 */
export var RESULT_TYPE_MAP = function ($i18n) { return ({
    info: {
        icon: 'exclamation-r-fill',
        title: $i18n.get({ id: 'Information', dm: '信息' }),
    },
    success: {
        icon: 'complete-r-fill',
        title: $i18n.get({ id: 'Success', dm: '成功' }),
    },
    warning: {
        icon: 'exclamation-t-fill',
        title: $i18n.get({
            id: '31255240133185536.CNTM',
            dm: '警告',
        }),
    },
    error: {
        icon: 'error-r-fill',
        title: $i18n.get({ id: 'Error', dm: '错误' }),
    },
    empty: {
        icon: 'https://img.alicdn.com/imgextra/i2/O1CN01p68sFM1yLu9FwGE5w_!!6000000006563-55-tps-160-128.svg',
        title: $i18n.get({ id: 'Empty', dm: '空空如也' }),
        subTitle: $i18n.get({
            id: 'NoInformationHasBeenFoundYet_702186463',
            dm: '暂无发现任何信息',
        }),
    },
    403: {
        icon: 'https://img.alicdn.com/imgextra/i1/O1CN015ILtaC23umNz0Nfy7_!!6000000007316-55-tps-160-128.svg',
        title: $i18n.get({
            id: 'NoPermissionForTheTimeBeing_1608534244',
            dm: '暂无权限',
        }),
        subTitle: $i18n.get({
            id: 'YouDoNotHaveAccessRights_1861995846',
            dm: '您暂无访问权限，请联系应用管理员',
        }),
    },
    404: {
        icon: 'https://img.alicdn.com/imgextra/i3/O1CN01QF1B631UFYBGYt9lS_!!6000000002488-55-tps-160-128.svg',
        title: '404',
        subTitle: $i18n.get({
            id: 'ThePageYouVisitedDoesNot_126725188',
            dm: '您所访问的页面不存在',
        }),
    },
    500: {
        icon: 'https://img.alicdn.com/imgextra/i1/O1CN01pMOERK1Rm7Ux2ZVgY_!!6000000002153-55-tps-160-128.svg',
        title: $i18n.get({ id: 'UnknownError', dm: '未知错误' }),
        subTitle: $i18n.get({
            id: 'YourDataIsNotDetectedBy_1514908024',
            dm: '系统未检测到您的数据，请稍后尝试',
        }),
    },
    networkError: {
        icon: 'https://gw.alicdn.com/imgextra/i3/O1CN019Fy2Qk1bjxp1mqZJJ_!!6000000003502-55-tps-160-128.svg',
        title: $i18n.get({ id: 'NetworkProblems', dm: '网络问题', ns: 'CnResult' }),
        subTitle: $i18n.get({
            id: 'TERM.NetworkErrorPleaseCheckYourConne_580936153',
            dm: '网络错误，请检查网络连接并重试',
            ns: 'CnResult',
        }),
    },
    messageEmpty: {
        icon: 'https://gw.alicdn.com/imgextra/i4/O1CN01KUBnel21jfZfbVd4T_!!6000000007021-55-tps-160-128.svg',
        title: $i18n.get({ id: 'TERM.NoMessages', dm: '暂无消息', ns: 'CnResult' }),
        subTitle: $i18n.get({
            id: 'NoInformationHasBeenFoundYet_702186463',
            dm: '暂无发现任何信息',
            ns: 'CnResult',
        }),
    },
    searchEmpty: {
        icon: 'https://gw.alicdn.com/imgextra/i3/O1CN0100jSYV1uhC7kWZpo7_!!6000000006068-55-tps-160-128.svg',
        title: $i18n.get({
            id: 'TERM.NoResultsFound',
            dm: '暂无搜索结果',
            ns: 'CnResult',
        }),
        subTitle: $i18n.get({
            id: 'YouCanChangeTheSearchTermTempora_2028355524',
            dm: '你可暂时更换搜索词',
            ns: 'CnResult',
        }),
    },
}); };
