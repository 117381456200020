import * as React from 'react';
import cx from 'classnames';
import { formilyReact } from '@/form/formily';
import './index.scss';
export var CnFormSingleCol = formilyReact.observer(function (props) {
    var _a;
    var className = props.className, style = props.style, children = props.children;
    var containerRef = React.useRef();
    var resizeObserverRef = React.useRef();
    var _b = React.useState('initial'), maxWidth = _b[0], setMaxWidth = _b[1];
    React.useEffect(function () {
        resizeObserverRef.current = new ResizeObserver(function (enties) {
            for (var _i = 0, enties_1 = enties; _i < enties_1.length; _i++) {
                var oGrid = enties_1[_i];
                var width = oGrid.target.clientWidth;
                var targetMaxWidth = 'initial';
                if (width > 894 && width <= 1494) {
                    targetMaxWidth = 600;
                }
                else if (width > 1494) {
                    targetMaxWidth = 800;
                }
                setMaxWidth(targetMaxWidth);
            }
        });
        resizeObserverRef.current.observe(containerRef.current);
        return function () {
            resizeObserverRef.current.unobserve(containerRef.current);
        };
    }, []);
    var classes = cx((_a = {
            'cn-ui-form-single-col-wrapper': true
        },
        _a[className] = !!className,
        _a));
    return (React.createElement("div", { className: classes, style: style, ref: containerRef },
        React.createElement("div", { className: "cn-ui-form-single-col", style: {
                maxWidth: maxWidth,
            } }, children)));
});
