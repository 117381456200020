import * as React from 'react';
import $i18n from 'panda-i18n';
import classNames from 'classnames';
// 定义一个函数 handleErrorInfo，用于处理错误信息
export function handleErrorInfo(error) {
    // 如果没有错误，则返回一个空对象
    if (!error)
        return {};
    // 如果有错误，则返回一个对象，其中 autoWidth 属性为 false，dataSource 属性为空数组，notFoundContent 属性为一个 div，div 中显示请求数据异常的信息
    return {
        autoWidth: false,
        dataSource: [],
        notFoundContent: (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-select-error') }, $i18n.get({
            id: 'RequestDataException',
            dm: '请求数据异常',
            ns: 'CnCascaderSelect',
        }))),
    };
}
