import { __assign } from "tslib";
import './index.scss';
import * as React from 'react';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { getPopupContainer, withNativeProps } from '@cainiaofe/cn-ui-common';
import { useControllableValue } from 'ahooks';
import $i18n from 'panda-i18n';
import { Dropdown } from '@/components/fusion';
import { CnIcon } from '@/components/cn-icon';
import { CnInput, CnInputTextArea } from '@/components/cn-input';
import { i18nRegister } from '@/components/cn-utils';
import { processInputValue, processTextareaValue } from './utils';
export var CnBatchInput = function (props) {
    var _a;
    var className = props.className, state = props.state, size = props.size, inputProps = props.inputProps, textAreaProps = props.textAreaProps, readOnly = props.readOnly, disabled = props.disabled, autoFocus = props.autoFocus;
    var inputRef = React.useRef(null);
    var wrapperRef = React.useRef(null);
    var _b = React.useState(false), dropDownVisible = _b[0], setDropDownVisible = _b[1];
    var _c = useControllableValue(props), inputValue = _c[0], setInputValue = _c[1];
    var _d = React.useState(processInputValue(inputValue)), textareaValue = _d[0], setTextareaValue = _d[1];
    React.useEffect(function () {
        var newValue = processTextareaValue(textareaValue);
        if (!isEqual(newValue, inputValue)) {
            setInputValue(newValue);
        }
    }, [textareaValue]);
    React.useEffect(function () {
        var newValue = processInputValue(inputValue);
        if (!isEqual(newValue, textareaValue)) {
            setTimeout(function () {
                setTextareaValue(newValue);
            });
        }
    }, [inputValue]);
    return withNativeProps(props, React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, className, 'cn-ui-batch-input'), ref: wrapperRef },
        React.createElement(Dropdown, { visible: dropDownVisible, onVisibleChange: function (visible, type) {
                if (type === 'fromTrigger') {
                    setDropDownVisible(true);
                }
                else {
                    setDropDownVisible(visible);
                }
            }, container: getPopupContainer(), wrapperClassName: "cn-ui-batch-input-popup", triggerType: "click", target: inputRef.current, autoFocus: autoFocus, trigger: React.createElement(CnInput, __assign({ ref: inputRef, hint: React.createElement(CnIcon, { type: "icon-bill-more", className: "add-icon", outerClassName: "cn-next-input-hint" }), state: state, size: size, readOnly: readOnly, disabled: disabled }, inputProps, { value: inputValue, onChange: setInputValue })) },
            React.createElement("div", { className: "popup-wrapper" },
                React.createElement("div", { className: "popup-title" },
                    React.createElement("span", null, $i18n.get({
                        id: 'BatchAddition',
                        dm: '批量添加',
                        ns: 'CnBatchInput',
                    })),
                    React.createElement("span", { className: "tips" }, $i18n.get({
                        id: 'EachDataEnterKeyWrap',
                        dm: '（每条数据回车键换行）',
                        ns: 'CnBatchInput',
                    }))),
                React.createElement(CnInputTextArea, __assign({ hasBorder: false, rows: 8 }, textAreaProps, { className: classNames((_a = {
                            'popup-textarea': true
                        },
                        _a[textAreaProps === null || textAreaProps === void 0 ? void 0 : textAreaProps.className] = !!(textAreaProps === null || textAreaProps === void 0 ? void 0 : textAreaProps.className),
                        _a)), value: textareaValue, onChange: setTextareaValue }))))));
};
CnBatchInput.displayName = 'CnBatchInput';
CnBatchInput.defaultProps = {
    autoFocus: false,
};
i18nRegister(CnBatchInput);
