import { formilyReact } from '@/form/formily';
import { CnInput as UiInput, CnInputTextArea as UiTextArea, } from '@/components/cn-input';
import { mapStatus } from '@/form/__builtins__';
export var CnInput = formilyReact.connect(UiInput, formilyReact.mapProps(function (props) {
    if (props.readOnly)
        return {};
    return props;
}, function (props, field) {
    if (props.readOnly)
        return {};
    return mapStatus(props, field);
}));
export var CnInputTextArea = formilyReact.connect(UiTextArea, formilyReact.mapProps(mapStatus));
