import { __assign, __extends } from "tslib";
import * as React from 'react';
import { CnAsyncSelect, } from '@/components/cn-async-select';
import defaultDataSource from './default-data-source';
var CnFiscalYearPicker = /** @class */ (function (_super) {
    __extends(CnFiscalYearPicker, _super);
    function CnFiscalYearPicker() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CnFiscalYearPicker.prototype.render = function () {
        return (React.createElement(CnAsyncSelect, __assign({ "data-name": "CnFiscalYearPicker", showSearch: true, hasSelectAll: true, dataSource: defaultDataSource }, this.props)));
    };
    CnFiscalYearPicker.displayName = 'CnFiscalYearPicker';
    return CnFiscalYearPicker;
}(React.Component));
export { CnFiscalYearPicker };
