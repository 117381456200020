import { __assign } from "tslib";
import { formilyReact } from '@/form/formily';
import { componentMap } from '@/form/component-map';
import { CnFilterProItem } from '../cn-filter-pro-item';
export var createScope = function (scope) {
    if (scope) {
        return formilyReact.createSchemaField({
            components: __assign(__assign({}, componentMap), { CnFilterProItem: CnFilterProItem }),
            scope: scope,
        });
    }
    else {
        return formilyReact.createSchemaField({
            components: __assign(__assign({}, componentMap), { CnFilterProItem: CnFilterProItem }),
        });
    }
};
export var SchemaField = createScope();
