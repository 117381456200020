import { __assign, __rest } from "tslib";
import * as React from 'react';
import { formilyReact } from '@/form/formily';
import { CnFormGrid } from '@/components/cn-form-grid';
import { useFilterPropsContext } from '../../context';
import { isInvisible } from '../../utils';
var observer = formilyReact.observer, RecursionField = formilyReact.RecursionField, useFieldSchema = formilyReact.useFieldSchema, useField = formilyReact.useField;
export var CnFilterGrid = observer(function (_a) {
    var realSchema = _a.realSchema, props = __rest(_a, ["realSchema"]);
    var schema = useFieldSchema();
    var gridField = useField();
    var _b = useFilterPropsContext(), cols = _b.cols, columnGap = _b.columnGap, rowGap = _b.rowGap, onColumnChange = _b.onColumnChange, rtl = _b.rtl;
    return (React.createElement(CnFormGrid, __assign({}, props, { cols: cols, columnGap: columnGap, rowGap: rowGap, onColumnChange: onColumnChange }), schema.mapProperties(function (schemaItem, name) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        var childFormPath = gridField.address.concat(name);
        var field = gridField.query(childFormPath).take();
        if ((field === null || field === void 0 ? void 0 : field.display) === 'none' ||
            (field === null || field === void 0 ? void 0 : field.display) === 'hidden' ||
            isInvisible(schemaItem)) {
            return;
        }
        return (React.createElement("div", { style: {
                display: ((_b = (_a = realSchema.properties) === null || _a === void 0 ? void 0 : _a[name]) === null || _b === void 0 ? void 0 : _b.isFold)
                    ? 'none'
                    : 'initial',
            }, key: name, colSpan: (_d = (_c = schemaItem['x-decorator-props']) === null || _c === void 0 ? void 0 : _c.colSpan) !== null && _d !== void 0 ? _d : schemaItem.colSpan, rowSpan: (_f = (_e = schemaItem['x-decorator-props']) === null || _e === void 0 ? void 0 : _e.rowSpan) !== null && _f !== void 0 ? _f : schemaItem.rowSpan, hidden: (_h = (_g = schemaItem['x-decorator-props']) === null || _g === void 0 ? void 0 : _g.hidden) !== null && _h !== void 0 ? _h : schemaItem.hidden, dir: rtl ? 'rtl' : undefined },
            React.createElement(RecursionField, { name: name, schema: schemaItem })));
    })));
});
