import { __assign } from "tslib";
import $i18n from 'panda-i18n';
import * as React from 'react';
import { CnButton } from '@/components/cn-button';
import { CnIcon } from '@/components/cn-icon';
import { cssPrefix } from './constant';
/**
 * 渲染导出按钮
 */
export default function ExportButton(props) {
    var onClick = props.onClick, children = props.children, loading = props.loading, buttonProps = props.buttonProps, _a = props.buttonText, buttonText = _a === void 0 ? $i18n.get({
        id: '31255238623236096.CNTM',
        dm: '导出',
        ns: 'CnExport',
    }) : _a;
    if (children) {
        return (React.createElement("span", { className: "".concat(cssPrefix, "button-wrapper"), onClick: onClick }, children));
    }
    return (React.createElement(CnButton, __assign({}, buttonProps, { loading: loading, iconSize: "medium", icons: {
            loading: loading ? undefined : React.createElement(CnIcon, { size: "medium", type: "export" }),
        }, onClick: onClick }), buttonText));
}
