import { __assign } from "tslib";
import './cn-anchor.scss';
import * as React from 'react';
import classNames from 'classnames';
import isFunction from 'lodash/isFunction';
import { AnchorList } from '../anchor-list';
import { findNode, findAllNodes, getNestHeadings, startListen, removeListen, jumpToNode, } from '../utils';
import { CnPageScrollDomSelector } from '@cainiaofe/cn-ui-common';
export var CnAnchor = React.forwardRef(function (props, ref) {
    var offsetY = props.offsetY, onChange = props.onChange, className = props.className, style = props.style, contentSelector = props.contentSelector, headingSelector = props.headingSelector, ignoreSelector = props.ignoreSelector, levelSelector = props.levelSelector, showRoot = props.showRoot;
    var _a = React.useState([]), dataSource = _a[0], setDataSource = _a[1];
    var _b = React.useState(''), currentValue = _b[0], setCurrentValue = _b[1];
    var nodeRef = React.useRef({});
    var bodyRef = React.useRef();
    var getDefaultHeadingsContainer = function () {
        if (!bodyRef.current)
            return window;
        var container = bodyRef.current.closest('.cn-ui-drawer-content');
        if (container)
            return container;
        container = bodyRef.current.closest('.cn-next-dialog-body');
        if (container)
            return container;
        return bodyRef.current.closest(CnPageScrollDomSelector) || window;
    };
    React.useEffect(function () {
        // 获取所有 headings 项
        var headingsContainer = findNode(contentSelector);
        if (!headingsContainer) {
            headingsContainer = getDefaultHeadingsContainer();
        }
        var headings = findAllNodes(headingSelector, headingsContainer, ignoreSelector);
        var levelNodes = findAllNodes(levelSelector, headingsContainer);
        var nestedHeadings = getNestHeadings(headings, headingsContainer, levelNodes, levelSelector);
        nodeRef.current = {
            headingsContainer: headingsContainer,
            headings: headings,
            levelNodes: levelNodes,
            nestedHeadings: nestedHeadings,
        };
        setDataSource(nestedHeadings);
        startListen(nestedHeadings, headingsContainer, updateCurrentValue, {
            offsetY: offsetY,
        });
        return function () {
            removeListen(nestedHeadings, headingsContainer);
        };
    }, [contentSelector, headingSelector, ignoreSelector, levelSelector]);
    var updateCurrentValue = function (newValue) {
        setCurrentValue(newValue);
    };
    var handleChange = function (newValue) {
        var _a;
        if (!nodeRef.current)
            return;
        var htmlId = newValue.htmlId;
        setCurrentValue(htmlId);
        removeListen(nodeRef.current.nestedHeadings, nodeRef.current.headingsContainer);
        jumpToNode(htmlId, (_a = nodeRef.current) === null || _a === void 0 ? void 0 : _a.headingsContainer, offsetY);
        startListen(nodeRef.current.nestedHeadings, nodeRef.current.headingsContainer, updateCurrentValue, {
            offsetY: offsetY,
        });
        if (isFunction(onChange)) {
            onChange(newValue);
        }
    };
    return (React.createElement("div", { className: classNames(CN_UI_HASH_CLASS_NAME, className, 'cn-ui-anchor', 'cn-ui-anchor-expand'), style: __assign({ top: 120, right: 40 }, style), ref: ref },
        React.createElement("div", { className: "cn-ui-anchor-body", ref: bodyRef },
            React.createElement(AnchorList, { dataSource: dataSource, value: currentValue, onSelect: handleChange, showRoot: showRoot }))));
});
CnAnchor.displayName = 'CnAnchor';
CnAnchor.defaultProps = {
    headingSelector: '.cn-ui-form-card-title',
    levelSelector: '.cn-ui-card, .cn-ui-card-sub-card, .cn-ui-card-sub-area-card',
    offsetY: 0,
    showRoot: true,
};
