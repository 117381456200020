import * as React from 'react';
import pick from 'lodash/pick';
import isNil from 'lodash/isNil';
import classnames from 'classnames';
import isPlainObject from 'lodash/isPlainObject';
import { CnFormat } from '@cainiaofe/cn-ui-common';
// 其他组件
import { domStyleMap } from '@/components/cn-utils';
import { CnTooltip } from '@/components/cn-tooltip';
import './index.scss';
export var renderValueHandleEmpty = function (value) {
    if (isNil(value) || value === '') {
        return '- -';
    }
    return value;
};
export var textRender = function (value) {
    if (isPlainObject(value) && typeof value === 'object') {
        // 对象传入，目前支持ToolTips,link
        // const linkStyle = value?.link ? { color: '#284cc0' } : {}; // 是否带超链
        var styleAttribute = pick(value, Object.keys(domStyleMap));
        var textDom = void 0;
        if (value === null || value === void 0 ? void 0 : value.link) {
            textDom = (React.createElement("a", { rel: "noreferrer", target: "_blank", href: value.link, "data-testid": "cn-format-text", className: classnames(CN_UI_HASH_CLASS_NAME, {
                    'cn-format-text--link': !!(value === null || value === void 0 ? void 0 : value.link),
                }), style: styleAttribute }, renderValueHandleEmpty(value.text)));
        }
        else {
            textDom = (React.createElement("span", { "data-testid": "cn-format-text", style: styleAttribute }, renderValueHandleEmpty(value.text)));
        }
        if (value === null || value === void 0 ? void 0 : value.toolTips) {
            // toolsTips
            var renderContent = function (data) {
                return (React.createElement("div", null, data.map(function (item) {
                    return React.createElement("div", { key: item.desc }, item.desc);
                })));
            };
            if (Array.isArray(value === null || value === void 0 ? void 0 : value.toolTips)) {
                return (React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, renderContent(value.toolTips)));
            }
            else {
                return (React.createElement(CnTooltip, { trigger: textDom, triggerType: "hover" }, renderValueHandleEmpty(value.toolTips)));
            }
        }
        return textDom;
    }
    return renderValueHandleEmpty(value);
};
// 动态注册
CnFormat.registerFormat('text', textRender);
