import { useI18n } from 'panda-i18n';
/**
 * 返回表单 label 的布局模式，top 上下布局或者 left 左右布局
 *
 * 当 labelAlign 值为 auto 的时候有两种情况
 * 1. 如果当前语言环境是中文简繁则返回 left 左右布局
 * 2. 如果当前语言环境是非中文简繁则返回 top 上下布局
 */
export var useFormLabelAlign = function (labelAlign) {
    var i18n = useI18n();
    var lang = i18n.getLang();
    if (labelAlign === 'auto') {
        // 简繁 使用左右布局
        if (['zh-CN', 'zh-TW'].includes(lang))
            return 'left';
        // 非简繁 使用上下布局
        return 'top';
    }
    // 非 auto 模式使用用户设置的值
    return labelAlign;
};
