import { formilyReactive } from '@/form/formily';
var model = formilyReactive.model, markRaw = formilyReactive.markRaw;
export var createCnFormTab = function (defaultActiveKey) {
    var formTab = model({
        activeKey: defaultActiveKey,
        setActiveKey: function (key) {
            formTab.activeKey = key;
        },
    });
    return markRaw(formTab);
};
