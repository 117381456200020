import * as React from 'react';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { formilyReact } from '@/form/formily';
import { createScope } from '../schema-field';
var JSXSchemaField = createScope();
export var JSXField = function (_a) {
    var propsSchema = _a.propsSchema, schema = _a.schema, setSchema = _a.setSchema, children = _a.children, formInstance = _a.formInstance;
    var jsxFieldSchemaJsonRef = React.useRef();
    React.useEffect(function () {
        var _a, _b;
        (_b = (_a = formInstance === null || formInstance === void 0 ? void 0 : formInstance.query('jsxField')) === null || _a === void 0 ? void 0 : _a.take()) === null || _b === void 0 ? void 0 : _b.setComponentProps({ schema: schema });
    }, [formInstance, schema]);
    // 从 Markup SchemaField 中读取 schema
    var VoidJSXField = function (_a) {
        var s = _a.schema;
        var jsxFieldSchema = formilyReact.useFieldSchema();
        jsxFieldSchemaJsonRef.current = jsxFieldSchema.toJSON();
        React.useEffect(function () {
            if (!isNil(propsSchema))
                return;
            var jsxFieldSchemaJson = jsxFieldSchema.toJSON();
            if (!jsxFieldSchemaJson || !jsxFieldSchemaJson.properties)
                return;
            jsxFieldSchemaJson = {
                type: 'object',
                properties: jsxFieldSchemaJson.properties,
            };
            if (!isEqual(s, jsxFieldSchemaJson)) {
                setSchema(jsxFieldSchemaJson);
            }
        }, [jsxFieldSchema, s, propsSchema]);
        return null;
    };
    // 如果不是 shcemaField 则自行控制
    if (!jsxFieldSchemaJsonRef.current ||
        !jsxFieldSchemaJsonRef.current.properties) {
        return React.createElement(React.Fragment, null, children);
    }
    return (React.createElement(JSXSchemaField, null,
        React.createElement(JSXSchemaField.Void, { name: "jsxField", "x-component": VoidJSXField, "x-component-props": {
                schema: schema,
            } }, children)));
};
