import { __assign, __rest } from "tslib";
import React, { forwardRef } from 'react';
import { isEnableScrollbar, isEnableStyle } from './utils';
import { ScrollBarView } from './scroll-bar-view';
export var CnScrollBar = forwardRef(function (props, ref) {
    var suppressScrollX = props.suppressScrollX, suppressScrollY = props.suppressScrollY, _a = props.as, as = _a === void 0 ? 'div' : _a, style = props.style, className = props.className, children = props.children, otherProps = __rest(props, ["suppressScrollX", "suppressScrollY", "as", "style", "className", "children"]);
    var currentStyle = __assign({ overflowX: isEnableStyle(suppressScrollX), overflowY: isEnableStyle(suppressScrollY) }, style);
    var Wrapper = as;
    if (!isEnableScrollbar()) {
        return (React.createElement(Wrapper, __assign({ style: currentStyle, className: className, ref: ref }, props), children));
    }
    return (React.createElement(ScrollBarView, __assign({ style: currentStyle, innerRef: ref, suppressScrollX: suppressScrollX, suppressScrollY: suppressScrollY, Wrapper: Wrapper, className: className }, otherProps), children));
});
