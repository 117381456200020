import React from 'react';
import { CnButton } from '../cn-button';
import { CnIcon } from '../cn-icon';
function SliderArrow(props) {
    var className = props.className, loop = props.loop, _a = props.current, current = _a === void 0 ? -1 : _a, total = props.total, swipeNext = props.swipeNext, swipePrev = props.swipePrev;
    return (React.createElement("div", { className: "".concat(className || '', " cn-ui-slider-arrow") },
        React.createElement(CnButton, { className: "arrow-icon arrow-left", disabled: loop ? false : current === 0, onClick: function () { return swipePrev(); } },
            React.createElement(CnIcon, { type: "icon-arrow-left", size: "xl" })),
        React.createElement(CnButton, { className: "arrow-icon arrow-right", disabled: loop ? false : current + 1 === total, onClick: function () { return swipeNext(); } },
            React.createElement(CnIcon, { type: "icon-arrow-right", size: "xl" }))));
}
export default SliderArrow;
