import * as React from 'react';
import { getDeptCacheByKey } from './request-cache';
function DepartmentPreviewItem(props) {
    var item = props.item, mokelay = props.mokelay, host = props.host;
    var _a = React.useState(item), departmentCache = _a[0], setDepartmentCache = _a[1];
    React.useEffect(function () {
        if (!item.fullName) {
            getDeptCacheByKey({ deptNo: item.value, mokelay: mokelay, host: host }).then(function (res) {
                setDepartmentCache(res);
            });
        }
        else {
            setDepartmentCache(item);
        }
    }, [item === null || item === void 0 ? void 0 : item.value]);
    return React.createElement("span", null, departmentCache === null || departmentCache === void 0 ? void 0 : departmentCache.fullName);
}
export default function DepartmentPreview(props) {
    var _a = props || {}, value = _a.value, mokelay = _a.mokelay, host = _a.host;
    if (Array.isArray(value)) {
        return (React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap' } }, value.map(function (valueItem, index) {
            if (index === (value === null || value === void 0 ? void 0 : value.length) - 1) {
                return (React.createElement(DepartmentPreviewItem, { item: { value: valueItem }, host: host, mokelay: mokelay }));
            }
            else {
                return (React.createElement(React.Fragment, null,
                    React.createElement(DepartmentPreviewItem, { item: { value: valueItem }, host: host, mokelay: mokelay }),
                    "\u3001"));
            }
        })));
    }
    return (React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap' } },
        React.createElement(DepartmentPreviewItem, { item: { value: value }, host: host, mokelay: mokelay })));
}
