import { __assign, __rest } from "tslib";
import React from 'react';
export function SliderIndicator(props) {
    var _a = props.current, current = _a === void 0 ? -1 : _a, _b = props.total, total = _b === void 0 ? 0 : _b, className = props.className, type = props.type, onClick = props.onClick, restProps = __rest(props, ["current", "total", "className", "type", "onClick"]);
    if (type === 'number') {
        return (React.createElement("div", __assign({ className: "indicator-number".concat(className || '') }, restProps),
            current + 1,
            "/",
            total));
    }
    var dots = new Array(total).fill(1).map(function (_, index) {
        return (React.createElement("div", { className: "indicator-dot ".concat(index === current ? 'indicator-dot-active' : ''), onClick: function () { return onClick === null || onClick === void 0 ? void 0 : onClick(index); }, key: "".concat(index) }));
    });
    return (React.createElement("div", __assign({ className: "".concat(className || '', " cn-ui-slider-indicator ") }, restProps), dots));
}
